import { id } from "vuetify/src/locale";
import { brandConstants } from "@/brand/current/PartnerConstants";
import settingGroupManagement from "./setting-group-management";
import macros from "./macros";
import newChat from "./manualTicketCreation";
import ticketChatList from "./ticketChatList";
import shopify from "./shopify";
import autoAssign from "./autoAssign";
import liveChatPreChatFormConfig from "./liveChatPreChatFormConfig";
import email from "./email";

export default {
  ...id,
  $vuetify: {
    ...id,
    ...newChat.id,
    ...autoAssign.id,
    ...ticketChatList.id,
    ...shopify.id,
    ...settingGroupManagement.id,
    ...macros.id,
    ...liveChatPreChatFormConfig.id,
    ...email.id,
    loginPageSubmitAndRegisterSeparatorOR: "Atau",
    loginAgreement: "Dengan masuk, kamu menyetujui {1} {0}.",
    agreementName: "syarat & ketentuan ",
    help: "Bantuan",
    connectAgent: "Hubungi CS",
    manualGuide: "Lihat panduan penggunaan",

    tos: "Term of Service",
    term: "I accept xxx {0}.",
    TOCText: "Dengan masuk, kamu menyetujui",
    TOCLinkText: "syarat & ketentuan",

    labelSelect: "Pilih",
    labelSaldo: "Saldo",
    labelDownload: "Download",
    labelEmail: "Email",
    labelPassword: "Password",
    labelOptional: "Opsional",
    labelLater: "Nanti Saja",
    labelTopUpNow: "Top up sekarang",
    labelUnderstand: "Saya Mengerti",
    warnEmailRequired: "Email wajib diisi",
    warnEmailFormat: "Format email salah",
    warnPasswordRequired: "Password wajib diisi",
    noMutationDataText: "Tidak ada riwayat mutasi",
    labelTopUpAlertSucceed: "Hore! Kamu berhasil top up {amount} Chat Credits.",
    labelDeleteFilter: "Hapus filter",

    labelTransactionCloseLabel: "Ya, tutup",
    labelTransactionCancelCloseLabel: "Tidak, kembali",
    labelTransactionTitleLabel: "Tutup & batalkan transaksi",
    labelTransactionDescLabel:
      "Dengan menutup halaman ini, produk yang telah ditambahkan tidak akan tersimpan",

    tabTitleBroadcastSend: "Kirim Broadcast",
    tabTitleBroadcastHistory: "Riwayat",
    tabTitleChatCreditsMutation: "Mutasi",

    tabTitleChatSettingOperationalHour: "Jam Operasional CS",
    tabTitleChatSettingFollowUp: "Follow Up",
    tabTitleChatSettingAutoClose: "Auto Close",
    tabTitleChatSettingQuickReplies: "Quick Replies",
    tabTitleChatSettingQuickRemarks: "Keterangan",
    tabTitleChatSettingCategory: "Tag Chat",
    tabTitleChatSettingSurveyRating: "Survey Rating",

    quickRepliesListTitle: "Daftar Quick Replies",
    quickRemarksListTitle: "Daftar Keterangan",
    quickRepliesListSubTitle:
      "Quick replies berguna bagi CS untuk membalas chat secara instan tanpa ketik manual.",
    quickRepliesListAddButton: "Tambah Quick Reply",
    quickRemarksListAddButton: "Tambah",
    quickRepliesDeleteDialogTitle: "Hapus quick reply ini?",
    quickRemarksDeleteDialogTitle: "Hapus keterangan ini?",
    quickRepliesDeleteDialogDescription:
      "Kamu akan menghapus `{0}` dari daftar quick chat",
    quickRepliesDeleteSomeDialogTitle: "Hapus {0} quick reply?",
    quickRepliesDeleteSomeDialogDescription:
      "Kamu akan menghapus sebanyak {0} quick chat pada daftar.",
    quickRepliesDeleteAllDialogTitle: "Hapus semua quick reply?",
    quickRepliesDeleteAllDialogDescription:
      "Kamu akan menghapus semua yang ada di daftar quick chat",
    quickRepliesEmptyTitle: "Daftar masih kosong",
    quickRepliesEmptyContent:
      "Nantinya, semua daftar quick replies akan muncul di sini.",
    quickRemarksEmptyContent:
      "Yuk tambah keterangan sekarang, biar CS bisa memberikan judul topik untuk chat yang masuk",
    quickRepliesDupliationErrorMsg:
      "Judul quick reply sudah terdaftar sebelumnya",
    quickRemarksInfo:
      "Keterangan berfungsi agar CS dapat menandakan topik chat yang ingin ditutup/diselesaikan.",
    quickRemarksSuccessAddMsg: "Berhasil menambah keterangan.",
    quickRepliesSuccessAddMsg: "Berhasil menambah quick reply.",

    quickRemarksListRemarksName: "Nama keterangan",
    quickRemarksListRemarksDescription: "Isi keterangan",

    quickRepliesSelectedCount: "{0} quick reply dipilih",

    quickRemarksDeleteDialogDescription:
      "Kamu akan menghapus `{0}` dari daftar keterangan",
    quickRemarksDeleteSomeDialogTitle: "Hapus {0} keterangan?",
    quickRemarksDeleteSomeDialogDescription:
      "Kamu akan menghapus sebanyak {0} keterangan pada daftar.",
    quickRemarksDeleteAllDialogTitle: "Hapus semua keterangan?",
    quickRemarksDeleteAllDialogDescription:
      "Kamu akan menghapus semua yang ada di daftar keterangan",
    quickRemarksEmptyTitle: "Belum ada keterangan",
    quickRemarksDupliationErrorMsg:
      "Nama keterangan sudah terdaftar sebelumnya",

    quickRepliesAddTitle: "Tambah Quick Replies",
    quickRemarksAddTitle: "Tambah keterangan",
    quickRepliesEditTitle: "Edit Quick Replies",
    quickRemarksEditTitle: "Edit keterangan",
    quickRepliesNameTitle: "Judul Quick Replies",
    quickRepliesContentTitle: "Isi Quick Replies",
    quickRemarksNameTitle: "Nama Keterangan",
    quickRemarksContentTitle: "Isi Keterangan",
    quickRemarksNameSubTitle: "Contoh: Refund, Pengiriman, Rewards, dll",
    quickRemarksContentSubTitle: "Penjelasan singkat dari nama keterangan",
    quickRepliesNameHint: "Ketik judul di sini",
    quickRepliesContentHint: "Tulis isi di sini",
    quickRepliesAddFieldError: "Melebihi karakter yang tersedia",
    quickRepliesAddButton: "Tambah",
    quickRepliesEditButton: "Simpan",
    quickRepliesErrorMessageLength: "Jumlah karakter melebihi batas",

    quickRepliesSearchHint: "Cari judul quick replies",
    quickRemarksSearchHint: "Cari nama keterangan",

    quickRemarksPopupTitle: "Ubah status menjadi Closed",

    quickRemarksChooseRemarksTitle: "Pilih Keterangan",
    quickRemarksContentRemarksTitle: "Isi Keterangan",
    quickRemarksContentRemarksTitleDetail:
      "Penjelasan singkat dari nama keterangan",

    chatCategoryInfo:
      "Tag chat berfungsi agar CS dapat menandakan topik chat yang sedang berjalan.",
    chatCategoryListTitle: "Daftar List Tag",
    chatCategoryAddTitle: "Tambah tag",
    chatCategoryEditTitle: "Edit tag",
    chatCategoryNameTitle: "Nama tag chat",
    chatCategoryContentTitle: "Isi Keterangan",
    chatCategoryNameSubTitle:
      "Contoh: Pelanggan baru, komplain, follow up, sudah bayar, dll",
    chatCategorySearchHint: "Cari nama tag chat",
    chatCategoryDeleteDialogTitle: "Hapus tag chat ini?",
    chatCategoryDeleteDialogDescription:
      "Kamu akan menghapus `{0}` dari daftar tag chat",
    chatCategoryEmptyTitle: "Belum ada tag chat",
    chatCategoryEmptyContent:
      "Yuk tambah tag chat sekarang, biar CS bisa memberikan topik untuk chat yang masuk",
    chatCategoryDuplicateErrorMsg: "Nama tag chat sudah terdaftar sebelumnya",
    chatCategorySuccessAddMsg: "Berhasil menambah tag chat.",
    chatCategoryAddPlaceholder: "Tambah tag chat",
    chatCategoryPickPlaceholder: "Tags",
    chatCategoryListName: "Nama tag",

    settingPlanAndPricingTitleLabel: "Paket Langganan",
    settingPlanAndPricingDescriptionLabel:
      "Pilih paket KOALA+ sesuai kebutuhan tokomu",

    planAndPricingPlanTitle: "Harga terjangkau & buka akun tanpa biaya.",
    planAndPricingPlanDescription:
      "Ada bonus sampai total 350.000 Chat Credits setiap bulan untuk menikmati seluruh fitur premium KOALA+.",
    planAndPricingPlanTaxDescription: "Harga belum termasuk PPN 11%",
    planAndPricingPlanButtonLabel: "Berlangganan",
    planAndPricingPlanButtonLabelExtend: "Perpanjang",
    planAndPricingPlanButtonCurrentLabel: "Paket saat ini",
    planAndPricingPlanNote:
      "*Promo berlaku bagi yang berlangganan sebelum 31 Desember 2022",
    planAndPricingWABAAbout: "Apa itu WhatsApp Business API?",
    planAndPricingWABAAboutDesc:
      "WhatsApp Business API (WABA) memungkinkan bisnis untuk mengotomatisasi komunikasi dengan balasan otomatis, chatbot WhatsApp & pesan interaktif. Untuk menggunakan {0}, kamu harus menghubungkan akun WABAmu di {0}.",
    planAndPricingWABARegister: "Bagaimana caranya daftarnya?",
    planAndPricingWABARegisterStep1: "Buat akun Facebook Business Manager",
    planAndPricingWABARegisterStep2:
      "Verifikasi dan hubungkan akun dengan WhatsApp Business API",
    planAndPricingWABARegisterStep3: "Daftar dan berlangganan {0} premium",
    planAndPricingWABAImportantNote1:
      "Gunakan nomor khusus untuk buat akun Whatsapp Business API",
    planAndPricingWABAImportantNote1Desc:
      "Nomor telepon tidak dapat digunakan di aplikasi atau Web WhatsApp setelah kamu menautkannya dengan WhatsApp Business API",
    planAndPricingWABAImportantNote2: "Pastikan kamu punya informasi ini:",
    planAndPricingWABAImportantNote2List1:
      "Display Name toko kamu yang akan ditampilkan kepada pelanggan yang mengobrol dengan kamu.",
    planAndPricingWABAImportantNote2List2:
      "Detail bisnis toko kamu (deskripsi bisnis, alamat bisnis, alamat email).",
    planAndPricingWABAImportantNote2List3: "Website toko kamu.",
    planAndPricingWABAImportantNote2List4:
      "Dokumen legal toko Anda (NPWP, legalitas perusahaan, dll).",
    planAndPricingWABAAccountCriteria:
      "Pastikan akun yang kamu gunakan memenuhi syarat berikut:",
    planAndPricingWABAAccountCriteriaList1:
      "Akun minimal memiliki akses parsial pada Facebook Business Manager yang akan digunakan untuk menghubungkan WABA",
    planAndPricingWABAAccountCriteriaList2:
      "Akun tidak memiliki {0} pada Facebook Business Manager",
    planAndPricingWABAAccountLimitation: "limitasi fitur",

    planAndPricingWABAInfo: "Info penting:",
    planAndPricingWABANextButton: "Lanjut",
    planAndPricingWABATitle: "Hubungkan akun WABA",
    planAndPricingWABADescription:
      "Untuk mulai berlangganan KOALA+, kamu harus menghubungkan akun WhatsApp Business API (WABA) dengan KOALA+ dulu.",
    planAndPricingWABAAlert: "Hubungkan akun WhatsApp Business API",
    planAndPricingWABAAlertDescription:
      "Untuk mulai berlangganan, silakan hubungkan akun WhatsApp Business API dengan KOALA+ terlebih dahulu",
    planAndPricingWABAConnectNow: "Hubungkan sekarang",
    planAndPricingWABAButtonLabel: "Mulai sekarang",
    planAndPricingWABAPackageVariant: "Pilihan Paket",
    planAndPricingWABAPricingInfo: "Informasi Biaya",
    planAndPricingWABAFeeTitle: "Biaya WABA",
    planAndPricingWABAFeeComponentTitle:
      "Perubahan biaya pesan outbound & ketentuan bonus credit: Efektif 1 Juni 2023",
    planAndPricingWABAFeeDescription:
      "Biaya kirim pesan broadcast yang dikenakan akan berbeda-beda berdasarkan kategori pesan template serta perubahan ketentuan penggunaan bonus credit.",
    planAndPricingWABAFeeDescription2:
      "WhatsApp Business API memiliki biaya terpisah dari biaya langganan {0}.",
    planAndPricingWABAFeeDescription3:
      "Kamu bisa top up Chat Credits disini untuk menggunakan fitur WABA.",
    planAndPricingWABAFeeButtonLabel: "Top Up Chat Credits",
    planAndPricingAlert:
      "Segera lakukan pembayaran paket langganan sebesar Rp{0} sekarang atau silakan refresh halaman jika Anda sudah membayar",

    planAndPricingWABAFeeCardTitle: "Biaya pesan inbound",
    planAndPricingWABAOutboundFeeCardTitle: "Biaya pesan outbound",
    planAndPricingWABAOutboundFeeCardSubtitle:
      "Biaya kirim pesan broadcast ke pelanggan yang disesuaikan dengan kategori pesan.",
    planAndPricingWABABonusCreditChangesTitle: "Perubahan ketentuan bonus",
    planAndPricingWABABonusCreditChangesDetailName: "Detail Perubahan",
    planAndPricingWABABonusCreditCardTitle: "Bonus Chat Credits",
    planAndPricingWABABonusCreditCardSubtitle:
      "Bonus Chat Credits akan masuk ke saldo bonus inbound dan hanya bisa digunakan untuk pesan inbound.",
    planAndPricingWABAFeeCardSubtitle:
      "Biaya chat masuk dari pelanggan lewat chatbot atau CS setelah dibalas oleh toko dalam 24 jam.",
    planAndPricingWABAFeeCardPricing: "{0} Chat Credits",
    planAndPricingWABAFeeCardPerChat: "/percakapan",
    planAndPricingWABAFeeCardPpn: "*harga sudah termasuk PPN 11%",
    planAndPricingWABAOutboundChangesTitle: "Perubahan biaya pesan outbound",
    planAndPricingWABAOutboundChangesCategoryName: "Kategori",
    planAndPricingWABAOutboundChangesStart: "Sebelum 1 Juni 2023",
    planAndPricingWABAOutboundChangesEnd: "Mulai 1 Juni 2023",
    planAndPricingWABAOutboundChangesUnit: "Chat Credits/kontak",
    planAndPricingWABAFeeBonusCreditTableAmountBonus: "Jumlah bonus",
    planAndPricingWABAFeeBonusCreditTableBonusMax: "Hingga {0}",

    planAndPricingWABAFeeBonusCreditTableUsage: "Penggunaan",
    planAndPricingWABAFeeBonusCreditTableUsageInboundOutbound:
      "Untuk pesan inbound & outbound",
    planAndPricingWABAFeeBonusCreditTableUsageInboundOnly:
      "Untuk pesan inbound saja",

    planAndPricingWABAFeeBonusCreditTableDateObtained: "Tanggal diperoleh",
    planAndPricingWABAFeeBonusCreditTableDateObtainedBefore: "Tanggal daftar",
    planAndPricingWABAFeeBonusCreditTableDateObtainedAfter:
      "Tanggal 1 tiap bulannya",

    tabTitleUnassigned: "Belum Ditugaskan",
    tabTitleAssigned: "Ditugaskan",
    searchContactPlaceholder: "Search Contact",
    titleBlankChatroom: "Belum ada chat yang aktif",
    subtitleBlankChatroom: "Chat dari pelanggan akan muncul disini",
    noChat: "Tidak ada chat",
    noCategory: "Belum ada tag",
    noTags: "Belum ada tag",
    chatroomFilterShowAllTags: "Lihat semua",
    chatroomFilterHideTags: "Sembunyikan",
    latest: "Terbaru",
    oldest: "Terlama",
    apply: "Terapkan",
    selectedContact: "kontak dipilih",
    forward: "Teruskan",
    resolve: "Selesai",
    assign: "Tugaskan",
    priority: "Prioritas",
    priorityNormalLabel: "Normal",
    prioritySevereLabel: "Menengah",
    priorityCriticalLabel: "Tinggi",
    chatroomFilterSort: "Urutkan",
    chatroomFilterCategory: "Tag",
    chatroomFilterPriorityStatus: "Prioritas",
    chatroomFilterMultipleStore: "Semua Channel",
    enableNotificationForNewChatTitle: "Dapatkan notifikasi pesan baru",
    enableDesktopNotificationLink: "Nyalakan notifikasi dekstop",
    showContactDetailLabel: "Lihat detail kontak",
    searchTicketIDPlaceholder: "Cari {0}",

    chatroomFilterApply: "Terapkan",
    chatReassign: "Teruskan ke CS lain",
    chatCreatedAt: "Chat dibuat",
    chatLastReply: "Kiriman terakhir",
    chatExpired: "Kedaluwarsa",
    chatSession: "Sesi",
    chatTicketID: "Ticket ID",

    chatroomSearchCS: "Cari Nama CS",

    commonDateTimeYesterday: "Kemarin",
    commonDateTimeEveryDay: "Setiap Hari",
    commonDateTimeToday: "Hari ini",
    commonDateTimeMonday: "Senin",
    commonDateTimeTuesday: "Selasa",
    commonDateTimeWednesday: "Rabu",
    commonDateTimeThursday: "Kamis",
    commonDateTimeFriday: "Jumat",
    commonDateTimeSaturday: "Sabtu",
    commonDateTimeSunday: "Minggu",

    chatroomCreateTransaction: "Buat transaksi",
    chatroomPhotoAndVideo: "Foto dan video",
    chatroomPhoto: "Foto",
    chatroomDocument: "Dokumen",
    chatroomAudio: "Audio",
    chatroomChatInputPlaceholder: "Ketik isi pesan disini",
    chatroomExpiredAt: "Chat akan kadaluwarsa jam ",
    chatroomHasExpired: "Chat sudah kadaluwarsa",
    chatroomOpenUrlInGoogleMap: "Buka di Google Map",
    chatRoomEmailAttachmentsSizeLimit: "Lampiran file: {0}/25MB",
    chatRoomEmailAttachmentsSizeExceedsLimit:
      "Lampiran file melebihi limit: {0}/25MB",
    chatRoomSendAsInternalNote: "Kirim sebagai internal note",
    chatRoomSendAsMessage: "Kirim sebagai pesan",

    csCreateTransaction: "Buat Transaksi",
    csCreateTransactionOrderDetail: "Detail pesanan",
    csCreateTransactionOrderInput: "Masukkan data pesanan",
    csCreateTransactionOrderInputName: "Nama Pelanggan",
    csCreateTransactionOrderInputPhone: "Nomor handphone",
    csCreateTransactionOrderInputDeliveryMethod: "Metode pengiriman",
    csCreateTransactionOrderInputDeliveryCourierMethod: "Kirim dengan kurir",
    csCreateTransactionOrderInputBuyerPickupMethod: "Pelanggan ambil ke toko",
    csCreateTransactionSearchInCatalog: "Cari produk di katalog",
    csCreateTransactionOrderManualAction: "Tidak ada di katalog? Isi manual",
    csCreateTransactionOrderHere: "di sini",
    csCreateTransactionOrderNote: "Catatan (opsional)",
    csCreateTransactionOrderAddressEmpty:
      "Minta Admin untuk isi alamat toko terlebih dahulu",
    csCreateTransactionOrderDeliveryFeeDiscount: "Promo ongkir!",
    csCreateTransactionOrderDeliveryFeeDiscountAmount:
      "Diskon ongkir sampai dengan {0} untuk pelanggan, dengan pilih Kurir Partner KOALA.",
    csCreateTransactionProductListEmptyTitle: "Belum ada produk di katalog",
    csCreateTransactionProductListEmptyDesc:
      "Tambahkan produk ke katalog atau tambah secara manual",
    failedCreateTrxLabel:
      "Gagal buat transaksi. Silakan coba beberapa saat lagi.",
    sharePaymentLink: "Bagikan link pembayaran",
    titleForward: "Tugaskan ke CS",
    subtitleForward: "Pilih daftar CS",
    description: "Deskripsi",
    caption1Forward: "Kontak terpilih untuk ditutup chatnya.",
    caption2Forward: `Chat yang sudah dalam status “Closed” akan masuk ke menu Riwayat Chat`,
    chatroomUnassignedInstruction:
      "Balas chat dalam waktu maks. 24 jam. Kamu bisa tugaskan ke CS yang sedang online.",
    chatroomAssignedHasNotExpiryDateInstruction:
      "Chat akan tetap aktif hingga admin menyelesaikan percakapan.",
    chatroomReplyDeadline: "Waktu tersisa untuk membalas pesan:",
    timeHourLabel: " Jam",
    chatHistoryTitle: "Riwayat Chat",
    chatHistoryDisplay: "Lihat riwayat chat",
    chatHistorySubtitle:
      "Kamu bisa melihat riwayat chat yang sudah selesai atau expired",
    chatHistorySectionTitle: "Daftar Riwayat",
    chatHistoryPlaceholderFilterPhone: "Cari Kontak",
    chatHistoryPlaceholderFilterChannel: "Pilih Channel",
    chatHistoryPlaceholderFilterDate: "Tanggal Waktu Selesai",
    chatHistoryColumnNameAndContact: "Nama & No. Customer",
    chatHistoryColumnContact: "No. Customer",
    chatHistoryColumnChannel: "Channel",
    chatHistoryColumnCs: "CS Ditugaskan",
    chatHistoryColumnClosedBy: "Selesai Oleh",
    chatHistoryColumnClosedAt: "Waktu Selesai",
    chatHistoryColumnRemark: "Keterangan",
    chatHistoryColumnAction: "Riwayat Chat",
    chatHistoryDialogTitle: "Riwayat Chat",
    chatHistoryOverlayText:
      "Dengan berlangganan sekarang, kamu bisa menggunakan fitur ini untuk mendukung jualanmu",
    chatHistoryOverlayButton: "Langganan sekarang",
    chatHistoryEmptyTitle: "Belum ada Riwayat Chat",
    callHistoryEmptyTitle: "Belum ada Riwayat Call",
    chatHistoryEmptySubtitle1:
      "Semua riwayat chat yang kamu balas ke pelanggan akan muncul di sini.",
    callHistoryEmptySubtitle1:
      "Semua riwayat call kepada pelanggan akan muncul di sini.",
    chatHistoryColumnTicketID: "Ticket ID",
    chatHistoryColumnCategory: "Tag",
    chatHistorySearchName: "Nama",
    chatHistorySearchAllChannel: "Semua Channel",
    chatHistorySearchAssignedCS: "Semua CS (Ditugaskan)",
    chatHistorySearchClosedByCS: "Semua CS (Selesai oleh)",
    chatHistorySearchRemarks: "Cari {0}",
    chatHistoryColumnTag: "Tag",
    chatHistoryEmailFilter: "Email",
    chatHistoryColumnRemarksOrSubject: "Keterangan/Subjek",

    noMutationTitle: "Mutasi Kosong",
    noMutationDesc: "Tidak ada mutasi di periode terpilih",
    noMutationClose: "Mengerti",

    topUpValidateTitle: "Top up gagal",
    topUpValidateDesc:
      "Silakan melakukan pembayaran pada proses top up sebelumnya.",
    topUpValidateClose: "Mengerti",

    broadcastPriceChanges: "Perubahan biaya kirim broadcast per 1 Juni 2023",
    broadcastPriceChangesDescription:
      "Biaya yang dikenakan akan berbeda-beda berdasarkan kategori pesan template.",
    broadcastPriceChangesSeeMore: "Lihat ketentuan",

    broadcastPriceAlert:
      "Biaya untuk broadcast adalah {0} chat credits/kontak (harga sudah termasuk PPN 11%).",
    broadcastAddReceiverTitle: "Pilih penerima broadcast",
    broadcastAddReceiverSubtitle:
      "Masukkan no. HP penerima secara manual, isi angka saja.",
    broadcastAddReceiverPhoneHint: "No. handphone penerima",
    broadcastAddReceiverAddBtn: "Tambah",
    broadcastAddReceiverValidationInvalidNumber: "Nomor tidak valid",
    broadcastAddReceiverValidationInvalidLength: "Nomor minimal 9-13 angka",
    broadcastAddReceiverValidationInvalidAlreadyExit: "Nomor sudah terdaftar",
    broadcastCountryCodeTextFieldPlaceholder: "Country code",
    broadcastPhoneNumberTextFieldPlaceholder: "No. HP penerima",

    broadcastBulkReceiverTitle: "Isi banyak no. HP sekaligus",
    broadcastBulkReceiverSubtitle:
      "Pastikan format file <b>.xls / .xslx</b> dan nama file <b>tidak diubah</b>.",
    broadcastBulkReceiverDownload: "Download Excel",
    broadcastBulkReceiverUpload: "Upload Excel",
    broadcastBulkReceiverValidationInvalidFormat: "Format file tidak sesuai",
    broadcastBulkReceiverValidationErrorUpload:
      "Upload gagal, silahkan cek kembali jaringan internet kamu",
    broadcastBulkReceiverValidationInvalidErrorMessage:
      "Ada {0} data yang tidak valid.",
    broadcastBulkReceiverValidationInvalidDownloadFileContent: "Download file",
    broadcastBulkReceiverValidationInvalidContentErrorMessage:
      "untuk edit data tersebut.",
    broadcastAllContactTitle: "Semua Pelanggan ({0} kontak)",
    broadcastAllContactSubTitle:
      "Semua kontak pelanggan akan masuk pada list broadcast",
    broadcastAllContactNoData: "Kamu belum memiliki data pelanggan ",
    broadcastAllContactAddContact: "Atur Pelanggan",

    labelFilterSortBroadcastHistory: "Urutkan",
    labelFilterTemplateBroadcastHistory: "Nama Template",
    labelDateTemplateBroadcastHistory: "Tanggal",
    columnNameTemplateBroadcastHistory: "Nama Template",
    columnNameSentBroadcastHistory: "Jumlah Dikirim",
    columnNameCategoryBroadcastHistory: "Kategori & Subkategori",
    columnNameFailedBroadcastHistory: "Gagal",
    columnNameBroadcastTimeBroadcastHistory: "Waktu Broadcast",
    columnNameDescriptionBroadcastHistory: "Keterangan",
    columnNameDownloadCustomerDataBroadcastHistory: "Download Detail",
    columnNameStatusBroadcastHistory: "Status Broadcast",
    columnNameStatusDeliveredBroadcastHistory: "Terkirim",
    columnNameStatusReadBroadcastHistory: "Dibaca",
    columnNameStatusFailedBroadcastHistory: "Gagal",
    columnNameStatusSendingBroadcastHistory: "Sedang mengirim",
    columnNameStatusScheduledAtBroadcastHistory: "Dijadwalkan pada ",
    columnNameStatusFailedMaxQuotaBroadcastHistory:
      "Gagal, telah mencapai batas kuota harian",

    columnNameChatCreditsTime: "Waktu",
    columnNameChatCreditsMutationType: "Deskripsi Transaksi",
    columnNameChatCreditsTransactionStatus: "Status Transaksi",
    columnNameChatCreditsMutationAmount: "Mutasi",
    columnNameChatCreditsMutationBalance: "Saldo Chat Credit",

    columnNameChatSettingDayLabel: "Hari",
    columnNameChatSettingOperationalHourLabel: "Jam Operasional",
    columnNameChatSettingMessageOnOperationalHourLabel:
      "Chat ketika jam operasional",
    columnNameChatSettingMessageOutsideOperationalHourLabel:
      "Chat diluar jam operasional",
    columnNameChatSettingActionLabel: "Action",

    labelChatCreditsTopUpNominal: "Isi Jumlah Top Up",
    labelChatCreditsTopUpPaymentMethod: "Metode Pembayaran",
    labelChatCreditsTopUpSelectPaymentMethod: "Pilih metode pembayaran",
    labelChatCreditsTopUpContinuePayment: "Lanjut bayar",

    labelTitleChatSetting: "Chat berdasarkan jam operasional",
    labelSubtitleChatSetting: "Jam operasional dalam WIB",

    labelChatSettingAutoCloseTitle: "Chat auto close",
    labelChatSettingSurveyRatingTitle: "Variable Survey",
    labelChatSettingAutoCloseColumnTitle1: "Isi chat auto close",
    labelChatSettingAutoCloseColumnDescription1:
      "Chat autoclose adalah chat otomatis yang dikirim ke pelangganmu sudah tidak membalas chat dari CS",
    labelChatSettingAutoCloseChatMessagePlaceholder: "Isi chat otomatis",
    labelChatSettingAutoCloseChatMessageError:
      "Jumlah karakter sudah melewati batas maximum",
    labelChatSettingAutoCloseChatDescriptionLabel: "Keterangan",
    labelChatSettingAutoCloseChatDescriptionPlaceholder: "Keterangan..",
    labelChatSettingAutoCloseColumnTitle2: "Auto close setelah berapa menit?",
    labelChatSettingAutoCloseColumnTitle2Placeholder: "Isi durasi dalam menit",
    labelChatSettingAutoCloseSuccessMessage:
      "Berhasil melakukan update chat auto close",
    labelChatSettingSurveyRatingSuccessMessage:
      "Berhasil melakukan update chat survey rating",

    labelChatSettingFollowUpTitle: "Chat follow up",
    labelChatSettingFollowUpColumnTitle1: "Isi Chat follow up",
    labelChatSettingFollowUpColumnDescription1:
      "Chat follow up adalah chat otomatis yang dikirim ke pelangganmu yang belum membalas chat dari CS",
    labelChatSettingFollowUpColumnTitle2:
      "Kirim chat follow up setelah berapa menit?",
    labelChatSettingFollowUpColumnPlaceholder: "Isi durasi dalam menit",
    labelChatSettingFollowUpMinute: "menit",
    labelChatSettingFollowUpSuccessMessage:
      "Berhasil melakukan update chat follow up",
    labelChatSettingFollowUpFailedMessage:
      "Gagal melakukan update chat follow up",
    labelChatSettingFollowUpSave: "Simpan",

    labelChatSettingCancelLabel: "Batalkan",
    labelChatSettingResetLabel: "Reset Sekarang",
    labelChatSettingDialogTitleLabel: "Reset chat otomatis",
    labelChatSettingDialogDescriptionLabel:
      "Chat otomatis dan jam operasional pada hari Senin akan direset",

    labelChatSettingOperationalHourEditDialogLabel: "Tambahkan",

    labelChatSettingOperationalHourEditDialogTitleLabel: "Ubah Auto Reply",
    labelChatSettingOperationalHourAddDialogTitleLabel: "Tambah Auto Reply",
    labelChatSettingOperationalHourEditDialogOperationalHourLabel:
      "Jam operasional",
    labelChatSettingOperationalHourEditDialogSelectHourLabel:
      "Pilih Jam (dalam WIB)",
    labelChatSettingOperationalHourEditDialog24HourLabel: "24 Jam",
    labelChatSettingOperationalHourEditDialogOpeningHourLabel: "Jam Buka",
    labelChatSettingOperationalHourEditDialogCloseHourLabel: "Jam Tutup",
    labelChatSettingOperationalHourEditDialogChatInHourLabel:
      "Chat ketika jam operasional",
    labelChatSettingOperationalHourEditDialogShowChatLabel:
      "Tampilkan chat ini 1x saja dalam sehari",
    labelChatSettingOperationalHourEditDialogChatOutHourLabel:
      "Chat diluar jam operasional",
    labelChatSettingOperationalHourEditDialogApplyInAllDayLabel:
      "Terapkan disemua hari?",

    appBarChatCredits: "Kredit Chat",
    appBarCredits: "Kredit",
    appBarContactCSParameterized: "Hubungi {0}",
    appBarManualGuide: "Panduan penggunaan",
    menuHome: "Beranda",
    menuProduct: "Produk",
    whatsappCatalogue: "WhatsApp Catalog",
    listMenuCatalog: "Katalog " + brandConstants.PARTNER_NAME,
    listMenuCatalogWhatsapp: "Katalog Whatsapp",
    menuTransaction: "Transaksi",
    menuWhatapp: "Whatsapp",
    menuShopify: "Shopify",
    menuViber: "Viber",
    menuBroadcast: "Broadcast",
    menuTemplate: "Template",
    menuChatbot: "Chatbot",
    menuCustomerService: "Atur Layanan CS",
    menuChatHistory: "Riwayat Chat",
    menuChatSetting: "Pengaturan Chat",
    menuInbox: "Chat Masuk",
    menuChatCredits: "Chat Credits",
    menuAddProduct: "Tambah Produk",
    menuEditProduct: "Edit Produk",
    menuSaveProduct: "Simpan",
    menuProductList: "Daftar Produk",
    menuCategory: "Kategori",
    menuSetting: "Pengaturan",
    menuSettingStore: "Pengaturan Toko",
    menuSettingAdmin: "Pengaturan Admin",
    menuSettingLiveChatCreate: "Tambah Live Chat",
    menuSettingLiveChatEdit: "Edit Live Chat",
    menuPlanPricing: "Paket Langganan",
    menuCSPerformance: "Performance",
    menuSocial: "Social Channel",
    menuPublish: "Publish",
    menuComments: "Comments",
    menuCallCenter: "Call Center",
    menuCallCenterHistory: "Riwayat Call",
    menuEmail: "Email",

    menuChatSettingTitle: "Pengaturan Chat Otomatis CS",
    menuChatSettingDesc:
      "Kamu bisa mengatur balasan chat CS secara otomatis ke pelangganmu",
    menuChatbotDesc:
      "Biaya auto reply chatbot adalah {0} chat credits/conversation dalam 24 jam (harga sudah termasuk PPN 11%).",
    menuChatSettingPriceDesc:
      "Biaya setiap balasan chat adalah {0} chat credits/chat dan hanya berlaku untuk chat dari whatsapp saja.",

    menuChatbotActivation: "Aktifkan chatbot",
    menuChatSettingAutoActivation: "Aktifkan chat otomatis",
    menuChatSettingAutoChatActivationBanner:
      "Untuk dapat menggunakan chat otomatis, kamu harus menonaktifkan chatbot terlebih dahulu dimenu chatbot.",
    menuChatSettingAutoChatIsActive: "aktif",
    menuChatSettingAutoChatIsInactive: "tidak aktif",
    menuChatSettingAutoActivationOperationalHour: "Aktifkan Chat Otomatis",
    menuChatSettingAutoActivationFollowUp: "Aktifkan Chat Follow Up",
    menuChatSettingAutoActivationAutoClose: "Aktifkan Chat Auto Close",
    menuChatSettingSurveyRating: "Kirim Survey Kepuasan Pelanggan",
    menuWallet: "Wallet",
    menuChatSettingAutoClosePackageBanner:
      "Fitur secara otomatis akan dimatikan bila status akun adalah Free",
    menuChatSettingAutoCloseInsufficientCreditBanner:
      "Fitur secara otomatis akan dimatikan bila chat credit tidak cukup",
    menuBroadcastTitle: "Pesan Broadcast",
    menuChatbotTitle: "Chatbot",
    menuChatCreditsTitle: "Chat Credits",
    menuChatCreditsDesc:
      "Miliki Chat Credits untuk biaya kirim pesan ke pelanggan. Top up dan lihat total pemakaian di halaman mutasi.",

    menuChatCreditsBonusAlertTitle: "Bonus Chat Credits sampai total {amount}!",
    menuChatCreditsBonusAlertDesc:
      "Dengan langganan KOALA+ premium, chat creditsmu akan di top up sampai total {amount} setiap bulannya.",
    menuChatCreditsBonusUpgradePremium: "Upgrade Premium",

    menuChatCreditsBalanceLabel: "Saldo",

    menuChatCreditsTopUpAlertDesc:
      "Top up belum dibayar. Silakan lakukan pembayaran sebesar {amount} sekarang.",
    menuChatCreditsTopUpAlertButton: "Bayar",

    menuChatCreditsFilterDateLabel: "Tanggal",
    menuChatCreditsFilterMutationTypeLabel: "Jenis Mutasi",

    menuTransactionAgentLabel: "Agen",

    menuCSPerformanceGeneralTab: "General",
    menuCSPerformanceAgentPerformanceTab: "Laporan Performa Agen",
    menuCSPerformanceCustomerSatisfactionTab: "Laporan Kepuasan Pelanggan",
    menuCSPerformanceAgentStatusTitle: "Status Agen Saat Ini",
    menuCSPerformanceChatPerformanceTitle: "Performa Chat",
    menuCSPerformanceAnalysisTitle: "Analisis",
    menuCSPerformanceBroadcastPerformanceTab: "Performa Broadcast",
    menuCSPerformanceBroadcastHistoryTab: "Riwayat Broadcast",

    menuCSPerformanceChannelFilter: "Channel",
    menuCSPerformanceDateFilter: "Periode",
    menuCSPerformanceTemplateFilter: "Template",

    menuContact: "Kontak Pelanggan",

    broadcastReceiverTitle: "Penerima Broadcast",
    broadcastReceiverListTitle: "Daftar Penerima Broadcast",
    broadcastReceiverSubTitle: "Kuota maks. kirim ke {0} kontak/hari.",
    broadcastReceiverSubTitleTooltips: "Kuota maks. kirim ke {0} kontak/hari.",
    broadcastReceiverCounter: " penerima",
    broadcastReceiverCounterSelection: " telah dipilih",
    broadcastReceiverListReveal: "Selengkapnya",
    broadcastReceiverListConceal: "Tutup",
    broadcastReceiverSelectedAll: "pilih semua",
    broadcastReceiverSelectedCancel: "batalkan",
    broadcastReceiverDeleteAllConfirmTitle: "Hapus Daftar Penerima?",
    broadcastReceiverDeleteAllConfirmContent:
      "Kamu akan menghapus semua nomor penerima pada daftar",
    broadcastReceiverDeleteAllConfirmYes: "Ya, hapus",
    broadcastReceiverDeleteAllConfirmNo: "Tidak",
    broadcastReceiverDeletePartialConfirmTitle: "Hapus Daftar Penerima?",
    broadcastReceiverDeletePartialConfirmContent:
      "Kamu akan menghapus nomor penerima yang telah dipilih pada daftar",
    broadcastReceiverDeletePartialConfirmYes: "Ya, hapus",
    broadcastReceiverDeletePartialConfirmNo: "Tidak",
    broadcastReceiverEmptyReceiver:
      "Daftar penerima broadcast masih kosong. Silakan tambah kontak satu per satu atau sekaligus",

    broadcastDetailTitle: "Detail Broadcast",
    broadcastDetailTemplate: "Template yang digunakan",
    broadcastDetailTemplateSelection: "Pilih Template",
    broadcastDetailSend: "Kirim Broadcast",
    broadcastDetailStoreName: "Nama Toko",
    broadcastDetailStoreNameEmpty: "Isi detail tokomu",
    broadcastDetailStoreLink: "Link Toko",
    broadcastDetailDescription: "Keterangan",
    broadcastDetailDescriptionPlaceholder:
      "Isi keterangan. Contoh: pelanggan baru, pelanggan tetap, promo Ramadhan, dll",
    broadcastDetailDescriptionSubtitle:
      "Digunakan untuk memberikan tagging pada riwayat broadcast dan keterangan tidak akan masuk ke pesan broadcast.",
    broadcastDetailUploadPicture: "Upload gambar",
    broadcastDetailUploadPictureSubtitle1: "Gambar maks 2MB dan 500x500 pixel.",
    broadcastDetailUploadPictureSubtitle2:
      "Kamu bisa mengganti gambar dengan klik ‘Replace’",
    broadcastDetailUploadPictureInvalid: "Gambar tidak sesuai dengan ketentuan",
    broadcastDetailPreview: "Preview broadcast",
    broadcastDetailPreviewSubtitle:
      "Menampilkan preview chat yang dikirim ke penerima",
    broadcastDetailProduct: "Pilih Produk",
    broadcastDetailProductPlaceholder: "Pilih produk dari katalog",
    broadcastDetailProductEmpty: "Tambahkan produk di katalog",
    broadcastDetailBroadcastTime: "Pilih Waktu Broadcast",
    broadcastDetailBroadcastTimeNow: "Kirim broadcast sekarang",
    broadcastDetailBroadcastTimeScheduled: "Kirim broadcast di waktu lain",
    broadcastDetailScheduleBroadcastDate: "Pilih Tanggal Broadcast",
    broadcastDetailScheduleBroadcastTime: "Pilih Jam Broadcast",
    broadcastDetailTemplatePlaceHolderEmptyReceiver:
      "Tambah kontak penerima terlebih dahulu",
    broadcastDetailSentConfirmTitle: "Lanjut Kirim Broadcast",
    broadcastDetailSentConfirmSubtitlePostpaid:
      "Kamu akan mengirim broadcast ke <b>{0} kontak</b> via {1}.",
    broadcastScheduledDetailSentConfirmSubtitlePostpaid:
      "Kamu akan mengirim broadcast ke <b>{0} kontak</b> via {1} pada tanggal {2} jam {3}.",
    broadcastDetailSentConfirmSubtitlePrepaid:
      "Kamu akan mengirim broadcast ke <b>{0} kontak</b> via {1}.",
    broadcastScheduledDetailSentConfirmSubtitlePrepaid:
      "Kamu akan mengirim broadcast ke <b>{0} kontak</b> via {1} pada tanggal {2} jam {3}.",
    broadcastDetailMultipleCountrySentConfirmTitle:
      "Kirim broadcast ke negara berbeda",
    broadcastDetailMultipleCountrySentConfirmSubtitle:
      "Nomor penerima broadcast terdeteksi <b>lebih dari satu negara</b>. Biaya broadcast bisa berubah tergantung ketentuan tiap negara.",
    broadcastScheduledMultipleCountryDetailSentConfirmSubtitle:
      "Nomor penerima broadcast terdeteksi <b>lebih dari satu negara</b>. Broadcast akan dikirim pada tanggal {1} jam {2}. Biaya broadcast bisa berubah tergantung ketentuan tiap negara.",
    broadcastDetailSentConfirmYes: "Ya, kirim sekarang",
    broadcastDetailSentConfirmNo: "Tidak",
    broadcastSentMessageSuccess:
      "Berhasil mengirim pesan broadcast ke {0} kontak. Silakan cek riwayat untuk melihat detail pesan terkirim.",
    broadcastSentMessageFailed: "Gagal mengirim pesan broadcast ke {0} kontak.",
    broadcastSentInsufficientTitle: "Chat Credits Tidak Cukup",
    broadcastSentInsufficientSubtitle:
      "Silakan melakukan top up untuk melanjutkan kirim pesan broadcast",
    broadcastSentExceedTitle: "Kuota Broadcast Sudah Habis",
    broadcastSentExceedSubTitle:
      "Kuota harian sudah habis. Silakan coba lagi besok.",
    broadcastTimeInvalid: "Jam yang dipilih sudah melewati waktu saat ini.",
    broadcastScheduledMessageSuccess:
      "Broadcast akan dikirim ke {0} kontak sesuai dengan waktu yang ditentukan. Silakan cek riwayat untuk melihat detail pesan terkirim.",
    broadcastDialogTitleText: "Yakin menghapus daftar penerima broadcast?",
    broadcastDialogTitleDescription:
      "Info penerima broadcast yg dipilih akan dihapus.",
    broadcastDialogCancelButtonText: "Batal",
    broadcastDialogRemoveButtonText: "Ya, hapus",

    templatePageTitle: "Template Broadcast",
    templateListTitle: "Daftar Template Broadcast",
    templateListSearch: "Cari nama template",
    templateListRowCount: "Tampilkan",
    templateListRowCountOptionAll: "Semua",
    templateHeaderName: "Nama Template",
    templateHeaderCampaignCode: "Kode Campaign",
    templateHeaderType: "Tipe Template",
    templateHeaderContent: "Isi Template",
    templateHeaderStatus: "Status",
    templateHeaderNewMessage: "Pesan Baru",
    templateHeaderAction: "Aksi",
    templateTableStatusApproved: "Disetujui",
    templateTableStatusDeclined: "Template ditolak",
    templateStatusDeclinedExpl: "Agar disetujui, edit sesuai dengan ",
    templateTableStatusPending: "Sedang diverifikasi Meta",
    templateTableEdit: "Ubah",
    templateTableDelete: "Hapus",
    templateGuide: "pedoman",
    templatePreview: "Preview",
    templatePreviewbutton: "Lihat Preview",
    templateSubmitNew: "Tambah Template",

    templateDeleteDialogTitle: "Hapus template?",
    templateDeleteDialogContent:
      "Jika dihapus, template akan hilang dan tidak akan tersimpan lagi.",
    templateDeleteDialogConfirm: "Ya, hapus",
    templateDeleteDialogCancel: "Tidak, batalkan",

    templateGuideTitle: "Perhatikan aturan penulisan template berikut:",
    templateGuideText1:
      "Untuk penawaran barang atau jasa, semua pesan dan media yang terkait dengan barang atau jasa, termasuk deskripsi produk, harga, pajak/ legal, merupakan tipe pesan bersifat transaksi yang semuanya",
    templateGuideText1Bold: "harus mengikuti ",
    templateGuideText1BoldUnderline: "Kebijakan Perdagangan WhatsApp.",
    templateGuideText2Bold: "Tidak mengandung identitas sensitif pengguna, ",
    templateGuideText2:
      "contohnya nomor kartu kredit/ debit pribadi, nomor rekening pengguna, nomor KTP, atau identifikasi sensitif yang bersifat pribadi lainnya.",
    templateGuideText3: "Pesan template",
    templateGuideText3Bold: "tidak mengandung",
    templateDuigeText3Cont:
      "konten bersifat kekerasan, menghina pihak lain, atau bersifat ancaman.",
    templateGuideText4: "Pesan template ",
    templateGuideText4Bold: "tidak boleh menduplikasi",
    templateGuideText4Cont:
      "pesan template yang sudah ada atau sudah pernah dibuat sebelumnya(kecuali tunggu 30 hari sejak dihapus).Pesan yang serupa/ sama akan ditolak oleh pihak Meta / Facebook.",
    templateGuideEditTemplate: "Edit template",
    templateGuideUnderstand: "Saya mengerti",

    chatSettingSurveyRatingRemarksSelectRemarks: "Cari remarks...",
    chatSettingSurveyRatingCSTagsSelectCSTag: "Cari CS Tags...",
    chatSettingSurveyRatingTemplateSelectTemplate: "Cari template...",
    createTemplateTitle: "Tambah Template",
    editTemplateTitle: "Edit Template",
    createTemplateFormRequiredError: "harus diisi",
    createTemplateFormTextContentError: "Tidak bisa lebih dari 1024 karakter",
    createtemplateFormOptional: "Opsional",
    createTemplateFormName: "Nama template",
    createTemplateFormNameSubtext:
      "Nama template hanya untuk memberi judul saja dan tidak akan muncul pada pesan broadcast",
    createTemplateFormLanguageSubtext: "Pilih bahasa untuk template Anda",
    createTemplateFormNamePlaceholder:
      "Isi nama template. Contoh: Promo produk, diskon akhir tahun, dll.",
    createTemplateFormLanguagePlaceholder: "Pilih bahasa",
    createTemplateFormNameError: "Tidak bisa lebih dari 150 karakter",
    createTemplateFormHeaderType: "Tipe header",
    createTemplateFormHeaderTypeSubtext:
      "Tipe header dapat berupa judul text atau media seperti image atau video",
    createTemplateFormHeaderTypePlaceholder: "Pilih tipe header",
    createTemplateFormHeaderTypeNone: "Tanpa Header",
    createTemplateFormHeaderTypeText: "Judul Text",
    createTemplateFormHeaderTypeMedia: "Media",
    createTemplateFormHeaderText: "Judul teks",
    createTemplateFormHeaderTextPlaceholder: "Isi judul template",
    createTemplateFormHeaderTextError: "Tidak bisa lebih dari 150 karakter",

    createTemplateFormHeaderMedia: "Pilih jenis media",
    createTemplateFormHeaderMediaSubtext1:
      "Format image dalam bentuk png, jpeg, atau bmp dengan resolusi 500x500px dan ukuran max 16MB",
    createTemplateFormHeaderMediaSubtext2:
      "Format video dalam bentuk mp4, avi, mkv, 3gp",
    createTemplateFormHeaderMediaSubtext3: "Format document dalam bentuk pdf",
    createTemplateFormHeaderMediaImage: "Image",
    createTemplateFormHeaderMediaVideo: "Video",
    createTemplateFormHeaderMediaDocument: "Dokumen",
    createTemplateFormHeaderMediaError:
      "Format atau ukuran file tidak sesuai ketentuan. Silakan upload kembali",
    createTemplateFormHeaderMediaErrorNoFile: "Silahkan upload file",
    createTemplateFormHeaderMediaErrorUpload:
      "Maksimal ukuran file adalah 16MB",
    createTemplateFormHeaderMediaGeneralErrorUpload:
      "Terjadi kesalahan. Silakan coba lagi.",

    createTemplateFormUpload: "Upload",
    createTemplateFormTextContent: "Isi pesan template",
    createTemplateFormTextContentPlaceholder: "Tulis pesanmu di sini",
    createTemplateFormTextContentEmptyError: "Isi pesan wajib diisi",
    createTemplateFormTextContentExceededError:
      "Tidak bisa lebih dari 1024 karakter",
    createTemplateFormTextGuide: "Lihat panduan agar template lolos verifikasi",
    seeHowToCategoryDetailGuide: "Lihat panduan memilih kategori",
    createTemplateFormCTAPhone: "Tombol panggilan telepon",
    createTemplateFormCTAPhoneSubtext:
      "Mau penerima langsung menghubungi kamu? Tambahkan tombol di template agar penerima pesan bisa mudah mencari info lebih lanjut.",
    createTemplateFormCTAPhoneTextPlaceholder:
      "Nama tombol. Contoh: Hubungi kami",
    createTemplateFormCTAPhoneTextExceededError:
      "Tidak bisa lebih dari 25 karakter",
    createTemplateFormCTAPhoneTextFormatError:
      "Format nomor telepon tidak valid",
    createTemplateFormCTAPhoneTextCountryCodeError:
      "Format wajib nomor telepon diawali 62",
    createTemplateFormCTAPhoneTextLengthError: "Nomor minimal 9-13 angka",
    createTemplateFormCTATextEmptyError: "Nama tombol wajib diisi",
    createTemplateFormCTAPhoneContentPlaceholder: "Nomor telepon",
    createTemplateFormCTAWebsite: "Tombol kunjungi website",
    createTemplateFormCTAWebsiteSubtext:
      "Mau penerima buka link webstore kamu? Tambahkan tombol di template agar penerima pesan bisa mudah mencari info lebih lanjut.",
    createTemplateFormCTAWebsiteTextPlaceholder:
      "Nama tombol. Contoh: Cek disini",
    createTemplateFormCTAWebsiteTextExceededError:
      "Tidak bisa lebih dari 25 karakter",
    createTemplateFormCTAWebsiteContentPlaceholder:
      "Link website atau link produk",
    createTemplatePreview: "Preview template",
    createTemplateSubmit: "Tambah template",
    createTemplateNext: "Lanjutkan",
    EditTemplateSubmit: "Edit template",

    createTemplateSubmitDisclaimerTitle:
      "Template akan dievaluasi terlebih dahulu. ",
    createTemplateSubmitDisclaimerContent:
      "Template yang kamu buat akan diperiksa dan diverifikasi terlebih dahulu oleh Meta maks. 1x24 jam. Lanjutkan pembuatan template?",
    createTemplateViberSubmitDisclaimerTitle: "Tambah template baru",
    createTemplateViberSubmitDisclaimerContent:
      "Template yang kamu buat akan langsung dapat digunakan untuk broadcast dan akan muncul dalam daftar template.",
    createTemplateSubmitDisclaimerUnderstandCheck:
      "Saya mengerti. Jangan tampilkan pesan ini lagi.",
    createTemplateSubmitDisclaimerLaterButton: "Nanti saja",
    createTemplateSubmitDisclaimerContinueButton: "Lanjutkan",
    createTemplateErrorAlertDuplicate:
      "Nama template sudah pernah terdaftar. Silakan ganti nama lain atau tunggu 30 hari sejak template sebelumnya dihapus.",
    createTemplateSubmitSuccess:
      "Berhasil membuat template dan tunggu maksimal 1x24 jam.",
    changeTemplateTypeCodeSuccess: "Berhasil mengganti template type.",
    changeTemplateTypeCodeFailed: "Gagal mengganti template type.",

    freeAccountSubscribeAction: "Berlangganan sekarang",
    freeAccountSubscribeActionAlt1: "Upgrade",
    freeAccountSubscribe:
      "Dengan berlangganan sekarang, kamu bisa menggunakan fitur ini untuk mendukung jualanmu",
    freeAccountSubscribeAlt1:
      "Masa free trial telah berakhir. Yuk, upgrade akunmu sekarang agar\nbisa terus pakai {0}!",
    freeAccountSubscribeAlt2:
      "Upgrade akunmu menjadi Premium dengan cara hubungkan akun {0} dengan akun WABA",
    freeAccountSubscribeBroadcast:
      "Dengan berlangganan sekarang, kamu bisa menggunakan fitur broadcast untuk mempromosikan tokomu",
    freeAccountSubscribeTemplate:
      "Dengan berlangganan sekarang, kamu bisa menggunakan fitur template untuk mempromosikan tokomu",
    freeAccountSubscribeChatbot:
      "Dengan berlangganan sekarang, kamu bisa menggunakan fitur chatbot untuk mempromosikan tokomu",
    freeAccountSubscribeChatCredits:
      "Dengan berlangganan sekarang, kamu bisa menggunakan fitur chat credits untuk mempromosikan tokomu",
    freeAccountLoadingText:
      "Sedang menyiapkan fitur untuk free trial. Mohon tunggu maks. 1 menit, ya.",
    freeAccountFailedLoadingText:
      "Maaf, fitur untuk free trial gagal dimuat. Coba muat ulang, ya.",
    freeAccountReloadText: "Muat ulang",

    accountLogout: "Keluar",
    accountUpgrade: "Upgrade",
    accountUserType: "Tipe User",
    accountSubscriptionEnd: "Berlaku sampai ",

    chatCreditBalance: "Total Saldo",
    chatCreditBill: "Total Tagihan {0}",
    chatCreditUnit: " Chat Credits",
    chatCreditMainBalance: "Saldo Utama",
    chatCreditMainBalanceDescription: "Untuk pesan inbound & outbound",
    chatCreditBonusBalance: "Saldo Bonus Inbound",
    chatCreditBonusBalanceDescription: "Hanya untuk pesan inbound",
    chatCreditInboundMessage: "pesan inbound",
    chatCreditOutboundMessage: "pesan outbound",
    chatCreditMutationCheck: "Lihat Mutasi",
    chatCreditMainBalanceAlertEmpty: "Yuk top up dulu untuk kirim pesan.",
    charCreditMainBalanceAlertAlmostEmpty: "Saldo hampir habis. Top up yuk!",
    chatCreditTopUp: "Top Up",
    charCreditBonusBalanceCheckDetail: "Pelajari selengkapnya",

    chatCreditTopUpSuccessLabel: "Berhasil Top Up",
    chatCreditTopUpFailedLabel: "Gagal Top Up",

    chatCreditTopUpAmount: "Total jumlah top up",

    chatSettingSurveyRatingTemplateSurveyDetail:
      "Daftarin template dulu dihalaman Template. hanya template yang di approve Meta yang bisa dipakai",
    chatSettingSurveyRatingRemarksSurveyDetail:
      "Daftarin keterangan dulu di halaman Keterangan. Hanya pesan yang ditutup dengan keterangan terpilih yang akan dikirimkan survey",
    chatSettingSurveyRatingCSTagsSurveyDetail:
      "Chat yang dihandle CS tertentu yang akan dikirimkan survey",
    paymentTotal: "Total Pembayaran",
    paymentSubtotal: "Subtotal",
    productSearch: "Cari barang...",

    menuWalletTitle: "Wallet",

    walletMutationTab: "Mutasi",
    walletPendingTab: "Pending",
    walletDetail:
      "Pantau mutasi dan saldo wallet tokomu. Pemasukan akan otomatis masuk ke rekening kamu maksimal 1x24 jam sejak transaksi selesai",
    walletMutationAutoDisbursementInfo: "Tentang Pencairan Dana Otomatis",
    walletMutationAutoDisbursementAlertTitle:
      "Pencairan Dana Otomatis Belum Aktif",
    walletMutationAutoDisbursementAlertDescription:
      "Silakan tambah rekening untuk mengaktifkan pencairan dana otomatis.",
    walletMutationTitle: "Mutasi",
    walletPendingTitle: "Saldo Pending",
    walletBankAccountCreate: "Tambah rekening",
    walletBankAccountUpdate: "Ubah",
    walletBankAccountDestination: "Rekening tujuan",
    walletBankAccountAlertTitle:
      "Selamat, pencairan dana otomatis sudah aktif!",
    walletBankAccountAlertDescription:
      "Kamu sudah menambahkan no. rekening, sehingga dana penjualanmu akan masuk ke rekening tujuan secara otomatis sesuai dengan jadwal.",
    walletBankAccountDescription: "Cairkan dana otomatis ke rekeningmu",
    walletTableHeaderCreatedAt: "Waktu",
    walletTableHeaderDescription: "Deskripsi Transaksi",
    walletTableHeaderMutation: "Mutasi",
    walletTableHeaderSaldo: "Saldo Wallet",
    walletTableHeaderPendingAmount: "Jumlah",
    walletTableHeaderPendingStatus: "Status",
    walletTableHeaderPendingStatusInProgress: "Diproses",
    WalletTableHeaderPendingCreatedAt: "Estimasi Cair",
    WalletTableNoDataHeader: "Belum ada rekening bank terpasang",
    WalletTableNoDataDescription:
      "Pasang rekening bank untuk pencairan dana setelah transaksi dengan pembeli.",

    menuContactTitle: "Kontak Pelanggan",
    menuAddContact: "Tambah Kontak",
    contactDetail:
      "Kelola kontak pelanggan untuk atur strategi pengiriman pesan yang tepat sasaran.",
    contactTagLabel: "Kelompok Pelanggan",
    contactTab: "Daftar Pelanggan",
    contactGroupTab: "Kelompok Pelanggan",
    contactAddNewContact: "Tambah Kontak",
    contactAddNewSingleContact: "Tambah satu kontak",
    contactAddNewBulkContact: "Tambah banyak kontak",
    contactAddNewCustomField: "Pengaturan Kolom",
    coachmarkButtonAddCustomField:
      "Sekarang kamu bisa tambah dan atur kolom di kontak pelanggan kamu!",
    labelContactSearch: "Cari nama kontak",
    labelContactFilterByTag: "Pilih kel pelanggan",
    labelContactFilterByChannel: "Pilih channel",
    labelContactNoDataHeader: "Mulai tambahkan kontak pelanggan",
    labelContactNoDataDescription:
      "Kirim broadcast ke pelanggan makin cepat dengan info kontak yang kamu simpan.",
    labelContactNotFoundHeader: "Kontak tidak ditemukan",
    labelContactNotFoundDescription: "Coba ubah kata kunci pencarian",
    labelContactHeaderName: "Nama",
    labelContactHeaderContact: "Kontak",
    labelContactHeaderChannel: "Channel",
    labelContactHeaderTag: "Kelompok Pelanggan",
    labelContactHeaderAverageTransaction: "Rata-rata Transaksi",
    tooltipAverageTransaction:
      "Total harga barang semua transaksi terbayar dibagi jumlah transaksi terbayar",
    labelContactHeaderAction: "Action",
    labelAddContactHeaderTitle: "Tambah / Edit Kontak",
    labelEditContactHeaderTitle: "Edit Info Kontak",
    labelRadioAddContactBulk: "Tambah banyak kontak sekaligus",
    labelRadioAddContactSingle: "Tambah kontak satu per satu",
    labelAddContactBulk: "Tambah banyak kontak",
    labelAddContactSingle: "Tambah satu kontak",
    labelContactTagContinueButton: "Lewati",
    labelContactTagSaveButton: "Simpan",
    labelContactListXMore: "+ {0} lainnya",
    labelBroadcastSelectedContact: "Pelanggan yang dipilih",
    labelDownloadTemplateContactBulk:
      "Download template Excel Kontak lalu isi detail kontak. Jangan menambah atau menghapus kolom dan pastikan format file tidak berubah",
    labelDownloadTemplateContact: "Download template Kontak",
    labelUploadContactBulk:
      "Upload atau geser file template yang sudah diisi ke area di bawah ini. Setelah berhasil upload, klik Simpan.",
    labelUploadContactExceedLimit: "Maksimal 2000 kontak/upload",
    labelUploadContactFileExceedLimit: "Ukuran file tidak boleh melebihi 5MB",
    labelUploadContact: "Upload File",
    labelUploadContactWrongFormat: "Format template harus .xlsx.",
    labelUploadContactExceedLimitError:
      "Maksimal 2000 kontak/upload. Pastikan data kontak baru tidak melebihi 2000.",
    labelContactUploadSuccess: "Berhasil upload file",
    labelContactUploadError: "Gagal proses file",
    labelContactUploadFileExtensionDetail: `File {0} dengan ekstensi {1} saja`,
    labelContactUploadFileTypeImage: "Gambar",
    labelContactUploadFileTypeDocument: "Dokumen",
    labelContactChangeFile: "Mau ganti file?",
    labelContactReuploadFile: "Ingin unggah data lain?",
    labelContactSuccessfullyProcessedContact:
      "Berhasil unggah {0} dari {1} kontak",
    labelContactReupload: "Upload baru",
    labelContactUploadInvalidNumber: `Ada {0} nomor yang tidak valid. <a href='{1}' style="color: black; text-decoration: underline">Download daftar nomor invalid.</a>`,
    labelContactUploadExistingNumber: `<br/>Ada {0} nomor yang sudah tersimpan di database. Nomor yang lama akan diganti dengan data yang baru.`,
    labelAddContactValidationFileRequired:
      "Silakan upload file terlebih dahulu",

    labelUploadContactDropZoneCannotChangeFileFormat:
      "Format file tidak boleh diubah",
    labelUploadContactDropZoneMaxLimit: "Ukuran file maksimal 5 MB",

    contactUploadAlertButton: "Muat Ulang",
    contactCompleteUploadAlertButton: "Buat Baru",
    contactUploadAlertOnProgressDescription:
      "Proses upload data kontak sedang berlangsung. Mohon menunggu proses selesai untuk menambahkan data baru",
    contactUploadAlertProcessedDescription: "Upload data telah selesai",
    contactDialogUploadButtonText: "Upload sekarang",
    contactDialogUploadTitleText: "Upload Data Kontak",
    contactDialogUploadTitleDescription:
      "Pastikan semua data yang akan diupload sudah benar, ya.",
    contactBulkTitle: "Daftar Kontak",
    contactBulkCounter: " penerima",
    contactBulkCounterSelection: " telah dipilih",
    contactBulkSelectedAll: "pilih semua",
    contactBulkSelectedCancel: "batalkan",
    contactBulkListReveal: "Selengkapnya",
    contactBulkListConceal: "Tutup",
    contactBulkDeleteAllConfirmTitle: "Hapus Daftar Kontak?",
    contactBulkDeleteAllConfirmContent:
      "Kamu akan menghapus semua kontak pada daftar",
    contactBulkDeleteAllConfirmNo: "Tidak",
    contactBulkDeleteAllConfirmYes: "Ya, Hapus",
    contactBulkDeletePartialConfirmTitle: "Hapus Daftar Kontak?",
    contactBulkDeletePartialConfirmContent:
      "Kamu akan menghapus kontak yang telah dipilih pada daftar",
    contactBulkDeletePartialConfirmYes: "Ya, hapus",
    contactBulkDeletePartialConfirmNo: "Tidak",
    contactBulkPhoneNumberHeader: "Nomor telepon dengan kode negara (wajib)",
    contactBulkNameHeader: "Nama (wajib)",
    contactBulkGroupHeader: "Kelompok pelanggan (opsional)",
    contactSubmitNewContact: "Simpan",
    labelAddContactOptional: "Optional",
    labelContactTagAddName: "Isi Nama Kelompok Pelanggan",
    labelErrorMessageDuplicateContactTag:
      "Nama kelompok pelanggan sudah terdaftar sebelumnya",
    labelAddContactDetailContactName: "Nama",
    labelAddContactDetailContactNameHint: "Masukkan nama pelanggan",
    labelAddContactErrorEmptyName: "Nama harus diisi",
    labelAddContactDetailContactChannelHint: "Masukkan kontak {0}",
    labelErrorMessageContactNameMaxCharacter:
      "Nama pelanggan maksimum 150 karakter",
    labelErrorMessageContactNameRequired: "Nama pelanggan harus diisi",
    labelAddContactDetailContactNumber: "No. WhatsApp",
    labelErrorMessageContactNumberInvalidFormat: "Format nomor salah",
    labelErrorMessageContactNumberDuplicate:
      "Nomor ini sudah tersimpan di database",
    labelErrorMessageContactNumberLength: "Nomor minmal {0} - {1} angka",
    labelErrorMessageGroupNameMaxCharacter:
      "Nama kelompok maksimum 30 karakter",
    labelErrorMessageGroupNameRequired: "Nama kelompok harus diisi",
    labelErrorMessageGroupDescriptionMaxCharacter:
      "Deskripsi maksimum 300 karakter",
    labelErrorMessageGroupDescriptionRequired: "Deskripsi harus diisi",
    labelAddContactDetailContactNumberHint:
      "Masukkan no. HP pelanggan yang aktif di WhatsApp",
    labelAddContactDetailContactTag: "Kelompok Pelanggan",
    labelAddContactDetailContactTagHint: "Pilih Kelompok Pelanggan",
    labelAddContactDetailAddContactTagText: "Tambah kelompok baru",
    labelContactMultipleDeleteConfirmationTitle:
      "Kamu akan menghapus {0} kontak",
    labelContactDelete: "Hapus",
    labelContactDeleteConfirmationTitle: "Hapus kontak ini?",
    labelContactDeleteCancelLabel: "Batalkan",
    labelContactDeleteConfirmLabel: "Hapus Sekarang",

    contactSuccessAddText: "Berhasil menambah {0} kontak",
    contactSuccessUpdateText: "Berhasil menyimpan perubahan kontak",
    contactSuccessRemoveText: "Berhasil menghapus kontak",
    contactFailedAddText: "Gagal menambah {0} kontak",
    contactFailedAddTextDuplicateHint:
      "Kontak duplikat. Hapus kontak yang ada ({0}) untuk memperbarui",
    contactFailedUpdateText: "Gagal menyimpan perubahan kontak",
    contactFailedRemoveText: "Gagal menghapus kontak",
    contactSelectedContactText: "{0} kontak dipilih",
    contactDialogCancelButtonText: "Batalkan",
    contactDialogRemoveButtonText: "Ya, hapus",
    contactDialogTitleText: "Hapus kontak pelanggan?",
    contactDialogTitleDescription:
      "Info kontak akan dihapus dari daftar pelanggan.",
    contactDetailPreviousPageButton: "Kembali ke Kontak pelanggan",
    contactDetailInfoDetail: "Detail Kontak",
    contactDetailTagMore: "+ {0] lainnya",
    contactDetailTagGroup: "Kelompok Pelanggan",
    contactDetailEdit: "Edit Kontak",
    contactDetailDelete: "Hapus Kontak",
    contactDetailChatHistoryTitle: "Riwayat Chat",
    contactDetailContinueChatButton: "Lanjutkan Percakapan",
    contactDetailChatListSeeDetail: "Lihat Chat",
    contactDetailTransactionProductMore: "+{0} produk lainnya",
    contactDetailChatColumnHeaderDate: "Tanggal",
    contactDetailChatColumnHeaderChat: "Chat",
    contactDetailChatColumnHeaderStatus: "Status",
    contactDetailChatColumnHeaderAction: "Action",
    contactDetailChatResolvedStatus: "Resolved",
    contactDetailChatOngoingStatus: "On going",

    contactDetailChatEmptyTitle: "Kamu belum memiliki riwayat chat",
    contactDetailChatEmptySubtitle:
      "Yuk, mulai chat dan terhubung dengan pelangganmu",
    contactDetailChatEmptyActionButtonLabel: "Chat Pelanggan",

    contactDetailTransactionTitle: "Riwayat Transaksi",
    contactDetailTransactionDetail: "Detail Transaksi",
    contactDetailTransactionListSeeDetail: "Lihat detail",
    contactDetailTransactionColumnHeaderDate: "Tanggal",
    contactDetailTransactionColumnHeaderTransactionNo: "Nomor Transaksi",
    contactDetailTransactionColumnHeaderItem: "Item",
    contactDetailTransactionColumnHeaderTotal: "Total",
    contactDetailTransactionColumnHeaderAction: "Action",

    contactDetailBroadcastEmptyActionButtonLabel: "Broadcast",
    contactDetailBroadcastTitle: "Riwayat Broadcast",
    contactDetailBroadcastColumnHeaderDate: "Tanggal",
    contactDetailBroadcastColumnHeaderTemplate: "Template Broadcast",
    contactDetailBroadcastColumnHeaderStatus: "Status",
    contactDetailBroadcastColumnHeaderStatusFail: "Gagal",
    contactDetailBroadcastColumnHeaderStatusDelivered: "Terkirim",
    contactDetailBroadcastColumnHeaderStatusRead: "Dibaca",
    contactDetailBroadcastColumnHeaderAction: "Action",
    contactDetailBroadcastListSeeDetail: "Lihat Template",

    contactDetailTransactionEmptyTitle: "Belum ada transaksi",
    contactDetailTransactionEmptySubtitle:
      "Semua transaksi nanti akan muncul disini. Buat transaksi melalui fitur transaksi di Chat Masuk",

    contactDetailBroadcastEmptyTitle: "Belum ada broadcast",
    contactDetailBroadcastEmptySubtitle:
      "Semua broadcast nanti akan muncul disini. Buat broadcast melalui fitur broadcast",

    contactTagAddDialogHeader: "Tambah Kelompok Pelanggan",
    contactTagDialogLaterButton: "Nanti saja",
    contactTagDialogSaveButton: "Tambahkan",
    contactTagSuccessAddText: "Berhasil menambah kelompok pelanggan",
    contactTagSuccessUpdateText:
      "Berhasil menyimpan perubahan kelompok pelanggan",
    contactTagSuccessRemoveText: "Berhasil menghapus kelompok pelanggan",
    contactTagDialogCancelButtonText: "Batalkan",
    contactTagDialogRemoveButtonText: "Ya, hapus",
    contactTagDialogTitleText: "Hapus {0}",
    contactTagDialogTitleDescription:
      "Info kelompok akan dihapus dari daftar kelompok pelanggan.",

    labelContactTagSearch: "Cari nama kelompok",
    contactTagAddNewContact: "Tambah Kelompok",
    contactSubmitNewContactTag: "Simpan",

    labelContactTagHeaderName: "Nama kelompok",
    labelContactTagHeaderContact: "Jumlah",
    labelContactTagTotalContact: "kontak",
    labelContactTagHeaderAction: "Kirim Broadcast",
    labelContactTagNoDataHeader: "Yuk tambah kelompok pelanggan",
    labelContactTagNoDataDescription:
      "Atur kelompok pelanggan agar kamu bisa kirim pesan broadcast sesuai tujuanmu",
    labelContactTagNotFoundHeader: "Kelompok pelanggan tidak ditemukan",
    labelContactTagNotFoundDescription: "Coba ubah kata kunci pencarian",
    labelContactTagBroadcast: "Kirim Broadcast",
    labelAddContactTagHeaderTitle: "Isi Detail Kelompok",
    contactTagDetailEdit: "Edit",
    contactTagDetailSaveEdit: "Lanjutkan",
    labelChooseContactHeaderTitle: "Pilih Kontak",
    labelAddContactTagDetailGroupName: "Nama Kelompok",
    labelAddContactTagDetailGroupNameHint: "Isi nama kelompok",
    labelAddContactTagDetailGroupDescription: "Deskripsi",
    labelAddContactTagDetailGroupDescriptionHint:
      "Isi deskripsi kelompok pelanggan",
    labelErrorMessageContactTagGroupDuplicate:
      "Nama kelompok ini sudah tersimpan di database",
    labelEditContactTagHeaderTitle: "Edit Kelompok Pelanggan",
    labelContactTagDeleteConfirmationTitle: "Hapus {0}?",

    labelRadioChooseContactBulk: "Tambah kontak sekaligus",
    labelRadioChooseContactSingle: "Tambah kontak dari database",
    labelDownloadTemplateContactTagBulk:
      "Download dulu Template Data Customer lalu isi informasinya. Jangan menambah/hapus kolom atau ubah format file.",
    labelDownloadTemplateContactTag: "Template Data Customer",
    labelUploadContactTagBulk:
      "Upload atau geser template yang sudah diisi ke area di bawah ini.",

    labelUploadContactTagDropZoneCannotChangeFileFormat:
      "Format file tidak boleh diubah",
    labelUploadContactTagDropZoneMaxLimit: "Ukuran file maksimal 5 MB",
    labelContactTagUploadSuccess: "Data selesai diunggah",
    labelContactTagChangeFile: "Ingin ganti file?",
    labelContactTagReupload: "Upload baru",

    menuCustomFieldTitle: "Pengaturan kolom",
    menuCustomFieldDetail:
      "Sesuaikan informasi kontak dengan menambah kolom baru. Kolom tambahan bisa kamu isi di halaman “Detail kontak”.",
    customFieldGuideButton: "Lihat Panduan",
    customFieldAddButton: "Tambah kolom",
    customFieldAlertBanner:
      "Kamu bisa menyembunyikan kolom yang tidak ingin ditampilkan dengan menggeser tombol “Tampilkan”.",
    customFieldPreviewTitle: "Preview detail kontak",
    customFieldGuideTitle: "Cara mengisi template",
    customFieldNameLabel: "Nama",
    customFieldContactLabel: "No. Whatsapp",
    customFieldGroupLabel: "Kelompok Pelanggan",
    customFieldBirthdayLabel: "Tanggal Lahir",
    customFieldEducationLabel: "Pendidikan Terakhir",
    customFieldNameTableHeader: "Judul Kolom",
    customFieldTypeTableHeader: "Tipe Kolom",
    customFieldDisplayTableHeader: "Tampilkan",
    customFieldExampleTableHeader: "Contoh data di kolom",
    customFieldActionTableHeader: "Aksi",
    customFieldActionTable: "Lihat Detail",
    customFieldAddComponentTitle: "Tambah Kolom",
    customFieldAddComponentName: "Judul kolom",
    customFieldAddComponentNameHint: "Contoh: Email, Alamat, dll",
    customFieldAddComponentType: "Tipe kolom",
    customFieldAddComponentTypeHint: "Pilih tipe kolom",
    customFieldAddComponentOption: "Opsi {0}",
    customFieldAddComponentAddOption: "Tambah Opsi",
    customFieldAddComponentValidation: "Validasi data",
    customFieldAddComponentValidationHint: "Pilih tipe validasi",
    customFieldAddComponentValidationTooltip:
      "Pemilihan validasi untuk mencegah kesalahan data",
    customFieldAddComponentGuideHint: "Contoh pengunaan tipe kolom",
    customFieldAddComponentGuideReveal: "Lihat detail",
    customFieldAddComponentGuideConceal: "Sembunyikan",
    customFieldAddComponentSaveButton: "Simpan",

    channelNameTableHeader: "Channel",
    channelDisplayTableHeader: "Tampilkan",
    channelActionTableHeader: "Aksi",
    channelActionIsMainChannelLabel: "Channel utama",
    channelActionSetMainChannelLabel: "Jadikan utama",
    mainChannelDialogTitle: "Ubah channel utama kontak pelanggan",
    mainChannelDialogContent:
      "Channel utama akan diubah dan akan berdampak pada pengolahan data kontak anda",
    mainChannelConfirmationDialogCancel: "Batalkan",
    mainChannelConfirmationDialogConfirm: "OK, Jadikan Utama",

    labelErrorMessageCustomFieldNameRequired: "Judul kolom harus diisi",
    labelErrorMessageCustomFieldNameDuplicate:
      "Judul field sudah tersimpan di database",
    labelErrorMessageCustomFieldTypeRequired: "Tipe kolom harus diisi",
    labelErrorMessageCustomFieldOptionRequired: "Opsi ini harus diisi",
    labelErrorMessageCustomFieldValidationRequired: "Validasi data harus diisi",

    customFieldAddDialogTitleText: "Tambah kolom",
    customFieldAddDialogTitleDescription:
      "Field yang ditambah <b>tidak dapat dihapus</b>, jadi pastikan informasi kolom kamu sudah sesuai ya.",
    customFieldAddDialogCancelButton: "Cek lagi",
    customFieldAddDialogApproveButton: "Tambahkan kolom",
    customFieldFileUploadButton: "Upload File",
    customFieldSuccessAddText: "Berhasil menambahkan kolom baru",
    customFieldFailedAddText: "Gagal menambahkan kolom baru",

    customFieldDetailComponentName: "Detail kolom",
    customFieldSuccessUpdateText: "Berhasil menyimpan perubahan kolom",
    customFieldFailedUpdateText: "Gagal menyimpan perubahan kolom",

    errorCustomFieldInvalidFormat: "Format tidak sesuai",
    errorCustomFieldInvalidFileSize: "Ukuran file harus lebih kecil dari 5MB",
    errorCustomFieldInternalServer: "Error pada sistem",

    customFieldBannerDescription:
      "Pesan broadcast kamu jadi lebih personal dengan variable yang terhubung otomatis ke informasi data kontak",
    customFieldBannerButton: "Pelajari fitur",

    menuContactBulkUploadTitle: "Tambah Banyak Kontak",
    contactBulkUploadGuideHint:
      "Ikuti langkah berikut untuk menambahkan banyak kontak",
    contactBulkUploadGuideStep:
      "Untuk mempelajari cara mengisi template, silakan ",
    contactBulkUploadGuideRef: "cek cara mengisi template.",
    contactBulkUploadGuideTitle: "Cara mengisi template",
    contactBulkUploadGuideStep1:
      "Pastikan download template terbaru setiap kamu akan menambah banyak kontak.",
    contactBulkUploadGuideStep2:
      "Mohon tidak menambah, mengubah atau menghapus kolom yang ada di template.",
    contactBulkUploadGuideStep3: "Pastikan format file tetap berupa",
    contactBulkUploadGuideStep4:
      "Data dengan nomor WhatsApp yang sama, akan diperbarui sesuai data terbaru.",
    contactBulkUploadDownloadTitle: "Download template",
    contactBulkUploadDownloadDescription:
      "Download template dengan klik tombol di bawah.",
    contactBulkUploadUploadTitle: "Isi dan upload template",
    contactBulkUploadUploadDescription:
      "Isi informasi kontak yang ada pada template lalu upload. Pastikan format file berupa <b>.xlsx</b> ya.",
    contactBulkUploadUploadButton: "Upload template",
    contactBulkUploadUploadTooltipLabel:
      "Mohon tunggu sebentar, ada file yang sedang diproses.",

    menuContactBulkUploadHistoryTitle: "Riwayat Upload",
    contactBulkUploadHistoryUploadTimeLabel: "Waktu upload",
    contactBulkUploadHistoryStatusLabel: "Status",
    contactBulkUploadHistoryFilenameLabel: "Nama file",
    contactBulkUploadHistorySavedLabel: "Berhasil disimpan",
    contactBulkUploadHistoryFailedLabel: "Gagal disimpan",
    contactBulkUploadHistoryActionLabel: "Aksi",

    contactBulkUploadHistorySuccessChipLabel: "Berhasil",
    contactBulkUploadHistorySuccessPartiallyChipLabel: "Berhasil sebagian",
    contactBulkUploadHistoryFailedChipLabel: "Gagal",
    contactBulkUploadHistoryProcessingChipLabel: "Sedang diproses",

    contactBulkUploadHistoryCounterLabel: "{0} kontak",
    contactBulkUploadHistoryDownloadLabel: "Download",
    contactBulkUploadHistoryDownloadTooltipLabel:
      "Download detail kontak yang gagal",

    contactBulkUploadHistoryInvalidTemplateLabel: "File template tidak sesuai",
    contactBulkUploadHistoryEmptyLabel: "File kosong",
    contactBulkUploadHistoryNotFoundLabel: "File tidak ditemukan",
    contactBulkUploadHistoryErrorSystemLabel: "Error pada sistem",
    contactBulkUploadHistoryNoDataLabel: "Belum ada data",

    categoryListTitle: "Daftar Kategori",
    categoryAddButton: "Tambah Kategori",
    categorySearch: "Cari nama kategori",
    categoryDisplay: "Tampilkan",
    categoryName: "Nama Kategori",
    categoryTableAction: "Action",
    categoryEmptyTitle: "Belum ada data kategori",
    categoryEmptyDescription:
      "Yuk buat kategori produk untuk memudahkan pembeli melihat-lihat produkmu di website jualan",
    categoryAddDialogHeader: "Tambah Kategori",
    categoryDialogLaterButton: "Nanti saja",
    categoryDialogSaveButton: "Tambahkan",
    categoryDialogCancelButton: "Batalkan",
    categoryDialogDeleteButton: "Hapus sekarang",
    categoryDialogDeleteHeader: "Hapus Kategori",
    categoryDialogDeleteDescription:
      "Kamu akan menghapusnya dari Daftar Kategori.",
    categoryEditDialogHeader: "Edit Kategori",
    categoryDialogUpdateButton: "Simpan",
    categoryDialogDeleteAllHeader: "Hapus semua kategori?",
    productListHeader: "Daftar Produk",
    productListDescription: "Lihat semua produk atau tambahkan disini",
    productAddNewProduct: "Tambah Produk",
    productAllProducts: "Semua Produk",
    whatsappCatalogueAllProducts: "Semua Produk",
    whatsappCatalogueTemplateSPM: "Template SPM",
    productAvailableStatus: "Dijual",
    productNotAvailableStatus: "Tidak Dijual",

    labelProductSearch: "Cari nama produk",
    labelProductFilterByCategory: "Filter Kategori",
    labelProductHeaderPhoto: "Foto Produk",
    labelProductHeaderName: "Nama Produk",
    labelProductHeaderCategory: "Kategori",
    labelProductHeaderPrice: "Harga",
    labelProductHeaderDescription: "Deskripsi",
    labelProductHeaderActiveStatus: "Aktif",
    labelProductHeaderAction: "Action",

    labelProductActivateAll: "Aktifkan semua",
    labelProductDeactivateAll: "Non aktifkan semua",
    labelProductDelete: "Hapus",
    labelProductNoDataHeader: "Belum ada produk",
    labelProductNoDataDescription:
      "Yuk tambah produk sekarang, biar website jualan kamu bisa langsung aktif.",
    labelProductDeleteConfirmationTitle: "Hapus produk ini?",
    labelProductDeleteConfirmationLabel:
      "Kamu akan menghapus {0} dari Daftar Produk.",
    labelProductDeleteCancelLabel: "Batalkan",
    labelProductDeleteConfirmLabel: "Hapus Sekarang",
    labelProductMultipleDeleteConfirmationTitle:
      "Kamu akan menghapus {0} produk.",

    labelCategoryMultipleDeleteConfirmationTitle:
      "Kamu akan menghapus {0} kategori.",

    labelCategoryMultipleDeleteAllConfirmationTitle:
      "Kamu akan menghapus semua yang ada di Daftar Kategori.",

    labelCategoryDeleteCategoryWithName: "Hapus Kategori {0}?",

    labelProductMultipleActivationConfirmationTitle: "Ubah status stok?",
    labelProductMultipleActivationLabel:
      "Kamu akan merubah status stok barang dari {0} produk.",
    labelProductMultipleActivationConfirmLabel: "Ya",
    labelErrorMessageDuplicateCategory:
      "Nama kategori sudah terdaftar sebelumnya",

    labelAddProductDetailHeaderTitle: "Detail Produk",
    labelAddProductDetailProductName: "Nama produk",
    labelAddProductDetailProductNameHint: "Isi nama produk",
    labelAddProductDetailProductCategory: "Kategori",
    labelAddProductDetailProductCategoryHint: "Pilih kategori",
    labelAddProductDetailProductAddCategoryText: "Tambah kategori baru",
    labelAddProductDetailProductPrice: "Harga produk",
    labelAddProductDetailProductPriceHint: "Isi harga produk",
    labelAddProductDetailProductType: "Tipe produk",
    labelAddProductDetailPhysicalProduct: "Fisik",
    labelAddProductDetailNonPhysicalProduct: "Non Fisik",
    labelAddProductDetailProductMeasurementUnit: "Satuan",
    labelAddProductDetailProductWeight: "Berat",
    labelAddProductDetailProductWeightHint: "Isi  berat",

    labelAddProductVariantHeaderTitle: "Varian ",
    labelAddProductOptional: "Opsional",
    labelAddProductVariantActivateButtonText: "Aktifkan Varian",
    labelAddProductVariantRemoveButtonText: "Hapus Grup Varian",
    labelAddProductVariantAddOptionText: "+ Tambah pilihan varian ({0}/10)",
    labelAddProductVariantMandatoryText: "Apa pelanggan wajib memilih varian?",
    labelAddProductVariantMultipleSelectionText:
      "Pelanggan bisa pilih beberapa varian?",
    labelAddProductVariantGroupAddOptionText: "Tambah grup varian ({0}/3)",

    labelAddProductDetailVariantGroupName: "Nama grup varian",
    labelAddProductDetailVariantGroupNameHint: "Isi nama grup varian",
    labelAddProductDetailVariantGroupDescription:
      "Contoh: Ukuran, Warna, Bahan, dll",
    labelAddProductDetailVariantOptions: "Pilihan varian",
    labelAddProductDetailVariantOptionsDescription1:
      "Harga tambahan adalah harga satuan produk ditambah dengan harga varian",
    labelAddProductDetailVariantOptionsDescription2:
      "Toggle on/off untuk mengatur ketersediaan produk",
    labelAddProductDetailVariantOptionNameHint: "Contoh: Hitam",
    labelAddProductDetailVariantOptionPriceHint: "jika gratis, isi 0",
    labelAddProductDetailVariantOptionalConfirm: "Ya",
    labelAddProductDetailVariantOptionalDeny: "Tidak",
    labelAddProductDetailVariantSingleSelect: "Hanya 1 pilihan",
    labelAddProductDetailVariantMultipleSelect: "Banyak pilihan",

    labelAddProductImageHeaderTitle: "Foto Produk",

    labelProductDescriptionHeaderTitle: "Deskripsi Produk",
    labelAddProductDescriptionHint: "Ketik deskripsi barang di sini",
    labelAddProductAddButton: "Tambah produk",
    labelAddVariant: "Tambah varian",
    labelAddVariantManual: "Tambah varian sendiri",
    labelAddVariantName: "Nama varian",
    labelAddVariantPrice: "Harga varian",
    labelAddVariantNameManual: "Varian tambahan",
    labelAddVariantSuccess: "Berhasil menambahkan varian.",
    labelEditVariant: "Edit varian",
    labelVariantRequired: "Wajib",
    labelVariantOptional: "Opsional",
    labelVariantTitle: "Variannya tidak ketemu?",
    labelVariantDesc: "Silakan tambahkan varian",
    labelVariantManual: "secara manual",
    labelPlaceholderNote: "Tulis catatan untuk penjual",
    labelAddProductSuccess: "Produk berhasil ditambahkan",

    labelRecheck: "Cek ulang",
    labelRecheckTitle: "Periksa lagi produk kamu",
    labelRecheckDescription:
      "Jika produk sudah ditambahkan, kamu tidak bisa mengubah varian dan catatan",

    settingAdmin: "Pengaturan Admin",
    settingAdminDetail: "Kamu bisa mengatur tugas dan peran anggota tim kamu",
    settingAdminList: "Daftar Pengguna",
    settingAdminAdd: "Tambah Pengguna",
    settingAdminInfo:
      "Maksimal tambah {0} pengguna. Untuk pengguna ke {1} dikenakan biaya Rp199.000/bulan. Untuk mengajukan penambahan silakan email ke business@koalaapp.id",
    settingFeaturesAccessList: "Daftar Akses",
    settingFeaturesAccessDetail: `Atur jenis akses menu sesuai dengan kebutuhan masing-masing role
    dalam mengakses {0}.`,
    settingFeaturesAccessTableHeaderName: "Nama",
    settingFeaturesAccessTableHeaderDesc: "Deskripsi",
    settingFeaturesAccessAddButton: "Tambah Role",
    settingFeaturesAccessTableHeaderAction: "Aksi",
    settingFeaturesAccessDeleteConfirm: "Ya, Hapus",
    settingFeaturesAccessDeleteTitle: "Hapus Daftar Akses?",
    settingFeaturesAccessDeleteContent:
      "Pastikan tidak ada pengguna yang masih memiliki akses ini",
    settingFeaturesAccessDeleteCancel: "Tidak",
    settingFeaturesAccessFormNameLabel: "Nama Role",
    settingFeaturesAccessFormNamePlaceHolder: "Isi nama role",
    settingFeaturesAccessFormDescLabel: "Deskripsi",
    settingFeaturesAccessFormDescPlaceHolder: "Isi deskripsi dari role",
    settingFeaturesAccessFormFeaturesLabel: "Akses Menu",
    settingFeaturesAccessFormAllMenuToogleLabel: "Semua Menu",
    settingFeaturesAccessDialogCreateButton: "Tambahkan",
    settingFeaturesAccessDialogUpdateButton: "Simpan",
    settingFeaturesAccessFormNameValidationErrorEmpty: "Nama Wajib diisi",
    settingFeaturesAccessFormNameValidationErrorLength: "Maksimal 15 karakter",
    settingFeaturesAccessSuccessCreateUpdateMessage: "Role Berhasil Disimpan",
    settingFeaturesAccessFailedCreateUpdateMessage: "Role Gagal Disimpan, {0}",
    settingFeaturesAccessSuccessDeleteMessage: "Role Berhasil Dihapus",
    settingFeaturesAccessFailedDeleteMessage: "Role Gagal Dihapus, {0}",
    settingFeaturesAccessFailedGetList: "Gagal Memuat List Access",
    settingAdminSearchPlaceholder: "Cari nama atau email",
    settingAdminFilterPlaceholder: "Filter posisi",
    partnerSettingAdminInfo: "Bisa tambah anggota tim hingga {0} pengguna.",
    partnerSettingAdminInfoAlt:
      "Maksimal tambah {0} pengguna. Untuk mengajukan penambahan silakan email ke support@kokatto.com atau melalui sales team kami",
    settingAdminEmailDesc: "Pastikan email anggota tim sudah terdaftar di {0}",
    settingAdminSearch: "Cari nama atau email",
    settingAdminFilter: "Filter Role",
    settingAdminName: "Nama",
    settingAdminEmail: "Email",
    settingAdminRole: "Posisi",
    settingAdminInboundRole: "CS",
    settingAdminStatus: "Status",
    settingAdminAction: "Aksi",
    settingAdminPassword: "Password",
    settingAdminPasswordConfirm: "Konfirmasi Password",
    settingAdminSelectRole: "Pilih Posisi Customer Service",
    settingAdminSelectGroup: "Pilih Group",
    settingAdminPlaceholderSelectGroup:
      "Wajib untuk diisi bila sudah memiliki min. 1 group",
    settingAdminSelectRoleSupervisor: "Supervisor 1",
    settingAdminSelectRoleSupervisorDescription:
      "Dapat melihat dan menugaskan semua chat, namun tidak dapat membalas atau ditugaskan.",
    settingAdminSelectRoleCS: "Customer Service 2",
    settingAdminSelectRoleCSDescription:
      "Dapat melihat dan membalas semua chat yang ditugaskan.",
    settingAdminSelectFeaturesAccess: "Pilih Role Akses",
    settingAdminPlaceholderSelectRole: "Pilih posisi anggota tim kamu",
    settingAdminCsTag: "CS Tagging",
    settingAdminCsTagDescription:
      "Tagging digunakan untuk mengkategorikan layanan CS. Contoh: Jakarta, Bali, dll.",
    settingAdminCsTagOptional: "Opsional",
    settingAdminPlaceholderName: "Isi nama anggota tim",
    settingAdminPlaceholderEmail: "Isi alamat email",
    settingAdminPlaceholderCSRole: "Pilih posisi",
    settingAdminPlaceholderCSRoleDisable:
      "Hanya untuk user yang memiliki akses halaman Chat Masuk",
    settingAdminPlaceholderFeaturesAccess:
      "Pilih sesuai dengan role akses yang sudah ditentukan",
    smartChatSettingAdminPlaceholderEmail: "Pilih email anggota tim",
    settingAdminPlaceholderCsTag: "Isi tagging dan dipisahkan dengan koma (,)",
    settingAdminSave: "Tambahkan",
    settingAdminSaveEdit: "Simpan",
    settingAdminEdit: "Edit Pengguna",
    settingAdminDeleteTitle: "Hapus Akses Pengguna?",
    settingAdminDeleteDescription:
      "Pengguna yang sudah dihapus tidak bisa login lagi di {0}",
    settingAdminDeleteYes: "Ya, hapus",
    settingAdminDeleteNo: "Tidak",
    settingAdminAddSuccess: "Berhasil tambahkan pengguna baru",
    settingAdminAddFailed: "Gagal tambahkan pengguna baru",
    settingAdminAddSuccessAlt1: "Berhasil tambah anggota tim",
    settingAdminDeleteSuccess: "Berhasil hapus pengguna",
    settingAdminDeleteFailed: "Gagal hapus pengguna",
    settingAdminDeleteFailedChatroomExist:
      "Gagal menghapus user. Selesaikan dulu tiket yang ditugaskan untuk menghapus user.",
    settingAdminAddMax:
      "Tidak bisa menambahkan user baru karena sudah mencapai batas maksimal",
    settingAdminAddMaxAlt1:
      "Hanya bisa tambah hingga {0} pengguna. Silakan hapus salah satu.",
    settingAdminUpdateSuccess: "Berhasil edit pengguna",
    settingAdminUpdateError: "Gagal edit pengguna",

    settingAdminPasswordError:
      "Password harus minimal 8 digit alfanumerik dan boleh karakter spesial",
    settingAdminRequiredForm: "Wajib diisi",
    settingAdminValidEmail: "Email tidak valid",
    settingAdminPasswordMatch: "Password tidak sama",
    settingAdminRoleAdminInfo: "Bisa akses semua fitur di {0}",
    settingAdminRoleCSInfo:
      "Bisa akses fitur Transaksi, Produk, dan Customer Service di {0}",
    settingAdminRoleCSInfoAlt:
      "CS dapat mengakses fitur Customer Service di {0}",
    settingAdminEmailUsed: "Email sudah terdaftar di {0}",
    settingAdminCSRoleMustEmpty:
      "Role tidak memiliki akses ke halaman Chat Masuk",
    settingAdminCSRoleMustNotEmpty:
      "Wajib menentukan posisi user untuk akses halaman Chat Masuk",

    settingStore: "Pengaturan Toko",
    settingStoreTabStoreInfo: "Informasi Toko",
    settingStoreTabAccountBank: "Rekening Bank",
    settingStoreTabCourier: "Pengiriman",
    settingStoreTabChannel: "Channel",
    settingStoreCourier: "Layanan Jasa Pengiriman",
    settingStoreSellerCourier: "Kurir Penjual",
    settingStoreContact: "Kontak Toko",
    settingStoreBankTitle: "Daftar Rekening Bank",
    settingStoreName: "Nama Toko",
    settingStoreNamePlaceHolder: "Isi nama toko",
    settingStoreAddress: "Alamat Toko",
    settingStoreAddressPlaceHolder: "Isi alamat toko",
    settingStoreCategory: "Bidang Usaha",
    settingStoreCategoryPlaceHolder: "Pilih Bidang Usaha",
    settingStoreBusinessType: "Tipe Bisnis",
    settingStoreBusinessTypePlaceHolder: "Pilih Tipe Bisnis",
    settingStoreBusinessMode: "Bidang Usaha",
    settingStoreBusinessModeIndustrySelection:
      "Pilih maksimal {0} bidang usaha",
    settingStoreService: "Layanan",
    settingStoreServicePlaceHolder: "Pilih Layanan",
    settingStoreSelectRule: "Pilih maksimal 3 bidang usaha",
    settingStoreCourierSellerName: "Nama kurir",
    settingStoreCourierSellerNamePlaceholder: "Isi nama kurir",
    settingStoreCourierSellerDeliveryFeeType: "Pilih jenis ongkos kirim",
    settingStoreCourierSellerDeliveryFee: "Biaya ongkos kirim",
    settingStoreCourierMaxPrice: "harga maksimal",
    settingStoreCourierSellerDeliveryFeeTotalProductRule:
      "Isi harga maksimal terlebih dahulu lalu isi biaya ongkirnya",
    settingStoreCourierSellerDeliveryFeeTotalProduct:
      "Biaya ongkos kirim diatas harga maksimal",
    settingStoreCourierSellerAddDeliveryFeeTotalProduct:
      "Tambah opsi biaya ongkir",
    settingStoreCourierSellerDeliveryFeePlaceholder:
      "Jika gratis, masukkan angka 0",
    settingStoreCourierSellerDeliveryEta: "Estimasi waktu pengiriman",
    settingStoreCourierSellerDeliveryEtaPlaceholder:
      "Isi estimasi waktu pengiriman",
    settingStoreCourierSellerWABA: "Anda belum menghubungkan akun WABA",
    settingStoreSaveEdit: "Simpan",
    settingStoreChannelDesc:
      "Hubungkan akun marketplace, website jualan dan sosial mediamu di {0} agar kamu bisa membalas chat pelanggan langsung di sini.",
    settingStoreRemove: "Hapus",
    settingStoreConnect: "Hubungkan akun",
    settingStoreChangeToRestaurantConfirmationTitle:
      "Ganti ke dashboard restoran?",
    settingStoreChangeToRestaurantConfirmationDescription:
      "Kamu akan mengganti dashboard dengan fitur dan layanan khusus restoran. Pastikan layanan dan jadwal operasional sudah sesuai, karena akan memengaruhi pesanan.",
    settingStoreChangeToOnlineStoreConfirmationTitle:
      "Ganti ke dashboard toko online?",
    settingStoreChangeToOnlineStoreConfirmationDescription:
      "Kamu akan mengganti dashboard dengan fitur dan layanan khusus toko/jualan online.",
    settingStoreChannelLimitationInfoBanner:
      "Channel kamu bisa terhubung ke {0} akun. Perlu lebih banyak? Hubungi {1}",

    settingStoreDialogAddMpTitle: "Akun Tokopedia",
    settingStoreDialogAddMpDescription:
      "Pastikan status toko kamu adalah Power Merchant, Power Merchant Pro, atau Official Store.",
    settingStoreDialogAddMpInputName: "Username/URL toko",
    settingStoreDialogAddMpInputLabel:
      "Masukkan username atau link toko Tokopedia kamu",
    settingStoreDialogAddMpButton: "Hubungkan",
    settingStoreStatusChannelConnected: "Terhubung",
    settingStoreStatusChannelRefresh: "Refresh untuk update status terbaru.",
    settingStoreStatusChannelErrorMpChannel: "Gagal menghubungkan akun. {0}",
    settingStoreStatusChannelTokopediaAccess:
      'Beri izin akses {0} via halaman <a href="https://seller.tokopedia.com/settings/thirdparty" target="_blank" style="color:#3d3d3d">Tokopedia Seller</a> atau email yang dikirim Tokopedia.',
    settingStoreStatusChannelContactSupport: "Hubungi {0}",
    settingStoreDeleteChannelDialogTitle: "Hapus akun?",
    settingStoreDeleteChannelDialogDescription:
      "Jika akun dihapus, kamu tidak dapat mengakses ruang obrolan akun tersebut.",
    settingStoreDeleteChannelDialogConfirmButton: "Ya, hapus",
    settingStoreDeleteChannelDialogCancelButton: "Kembali",
    settingStoreSuccessDeleteAccountChannel: "Berhasil menghapus akun {0}.",
    settingStoreStatusChannelAlreadyExist: `<div class="text-color-error"> Akun sudah terhubung di {0} atau platform serupa.</div>`,
    settingStoreStatusChannelNotFound:
      "Gagal menghubungkan akun. Cek lagi username atau link toko kamu untuk menghubungkan. {0}",
    settingStoreStatusChannelTryAgain: "Coba hubungkan lagi",
    settingStoreStatusChannelNotPowerMerchant:
      "Gagal menghubungkan akun. Pastikan status tokomu Power Merchant/Power Merchant Pro/Official Store. {0}",
    settingStoreDialogAddMpInputNumber: "Nomor {0}",
    settingStoreDialogAddMpInputDisplayName: "Nama di {0}",
    settingStoreDialogAddMpInputPhoneNumberLabel: "Contoh: 081234567890",
    settingStoreDialogAddMpInputDisplayNameLabel:
      "Masukkan nama toko atau bisnis kamu untuk display name",
    settingStoreDialogAddMpNextButton: "Lanjutkan",
    settingStoreDialogAddMpErrorEmptyStore:
      "Wajib isi username atau link toko.",
    settingStoreStatusChannelConnecting: "Sedang menghubungkan akun.",
    settingStoreDialogAddViberInputName: "Token Bot",
    settingStoreDialogAddViberInputLabel: "Masukkan token bot akun Viber kamu",
    settingStoreDialogAddTelegramInputName: "Token Bot",
    settingStoreDialogAddTelegramInputLabel:
      "Masukkan token bot akun Telegram kamu",
    settingStoreDialogAddMpErrorEmptyToken: "Wajib isi Token Bot.",
    settingStoreDialogAddMpGetTokenText:
      "Bagaimana cara mendapatkan Token Bot?",
    settingStoreDialogAddLineInputChannelSecret: "Channel Secret",
    settingStoreDialogAddLineInputChannelSecretLabel: "Masukkan Channel Secret",
    settingStoreDialogAddLineInputChannelToken: "Channel Access Token",
    settingStoreDialogAddLineInputChannelTokenLabel:
      "Masukkan Channel Access Token",
    settingStoreDialogAddMpGetChannelSecret:
      "Bagaimana cara mendapatkan Token?",
    settingStoreDialogAddLineInputChannelName: "Channel Name",
    settingStoreDialogAddLineInputChannelNameLabel: "Masukkan Nama Channel",

    settingStoreQRWhatsappDialogTitle: "Scan QR Code",
    settingStoreQRWhatsappDialogExpiredCountdown:
      "QR Code akan kedaluwarsa dalam {0}",
    settingStoreQRWhatsappDialogAlreadyExpired: "QR Code kedaluwarsa",
    settingStoreQRWhatsappDialogBannerInfo:
      'Pastikan scan QR dengan akun WhatsApp <span class="text-body-16--bold">{0}</span> ',
    settingStoreQRWhatsappDialogInstructionFirst:
      "1. Buka aplikasi WhatsApp di handphone kamu.",
    settingStoreQRWhatsappDialogInstructionSecond: `<div class="text-with-icons">2. Tap <span class="text-body-16--bold">Menu</span> <div class="icon-three-dots"></div> atau <span class="text-body-16--bold">Settings</span> <div class="icon-setting"></div> lalu pilih <span class="text-body-16--bold">Perangkat tertaut.</span><div>`,
    settingStoreQRWhatsappDialogInstructionThird:
      '3. Tap <span class="text-body-16--bold">Tautkan perangkat.</span>',
    settingStoreQRWhatsappDialogInstructionFourth:
      "4. Arahkan handphone ke layar ini untuk scan QR Code di atas.",
    settingStoreQRWhatsappDialogInstructionFifth:
      "5. Tunggu sampai info QR ini tertutup otomatis setelah terhubung.",
    settingStoreQRWhatsappDialogRegenerateQR: "Buat QR Code baru",
    settingStoreQRWhatsappDialogErrorShowingQR:
      "Gagal menampilkan QR Code. Silakan coba tutup dan buka lagi halaman ini.",
    settingStoreQRWhatsappDialogErrorExceedLimit:
      "Melebihi batas pembuatan QR Code. Silakan coba tutup dan buka lagi halaman ini.",
    settingStoreQRWhatsappDialogErrorScanWrongNumber:
      "Akun WhatsApp yang dipakai untuk scan berbeda dengan nomor terdaftar. Silakan buat QR Code baru dan scan pakai akun yang sesuai.",
    settingStoreSuccessConnectChannelNotif:
      'Berhasil menghubungkan <span class="text-body-16--bold">{0}<span>',
    settingStoreConfirmDialogCloseQRDialogTitle:
      "Batal menghubungkan Whatsapp?",
    settingStoreConfirmDialogCloseQRDialogDescription:
      "Kamu perlu mengulangi proses menghubungkan dari awal jika menutup info QR ini.",
    settingStoreConfirmDialogCloseQRDialogCancelButton: "Lanjut hubungkan",
    settingStoreConfirmDialogCloseQRDialogConfirmButton: "Ya, batalkan",
    settingStoreFailedDeleteChannel:
      "Gagal menghapus akun email. Silakan coba lagi.",
    settingStoreUpgradePackagePopUpDialogTitle: "Upgrade ke paket Pro",
    settingStoreUpgradePackagePopUpDialogDescription:
      'Channel WhatsApp hanya tersedia di paket Pro. Kamu bisa upgrade paket langganan mulai dari <span class="text-body-16--bold">71rb/bulan.</span>',
    settingStoreUpgradePackagePopUpDialogCancelButton: "Nanti saja",
    settingStoreUpgradePackagePopUpDialogConfirmButton: "Upgrade paket",
    settingStoreConnectShopeeDialogDataSafetyGuarantee:
      "Data kamu dijamin aman",
    settingStoreConnectShopeeDialogInstructionFirst:
      'Klik tombol <span class="text-body-14--bold">Hubungkan.</span> Selanjutnya kamu akan diarahkan ke halaman Shopee.',
    settingStoreConnectShopeeDialogInstructionSecond:
      '<span class="text-body-14--bold">Login ke akun Shopee kamu.</span> Pastikan akun belum pernah kamu hubungkan di akun {0} lain.',
    settingStoreConnectShopeeDialogInstructionThird:
      'Klik <span class="text-body-14--bold">Confirm Authorization.</span> Setelah berhasil dihubungkan, kamu bisa balas chat pelanggan di Shopee lewat {0}.</span>',
    settingStoreSuccessConnectMPChannelNotif:
      'Berhasil menghubungkan akun <span class="text-body-16--bold">{0}<span>',
    settingStoreFailedConnectChannel:
      "Gagal menghubungkan channel. Silahkan coba lagi",
    settingStoreQRWhatsappDialogPreparingServerTitle:
      "Sebentar ya, QR Code lagi kami siapkan untuk menghubungkan WhatsApp kamu",
    settingStoreQRWhatsappDialogPreparingServerSubtitle:
      "QR Code akan muncul dalam 2 menit.",
    settingStoreQRWhatsappDialogPreparingServerContactCS:
      "QR Code tidak muncul? Hubungi {0}",
    settingStoreDialogAddMpWhatsappInfo:
      "Kamu hanya bisa menghubungkan ke 1 nomor WhatsApp.",
    settingStoreGiveAccessTokopediaDialogFirstTitle:
      "Izinkan akses {0} di Tokopedia Seller",
    settingStoreGiveAccessTokopediaDialogFirstDescription:
      'Untuk melanjutkan, beri izin akses dulu via halaman <a href="https://seller.tokopedia.com/settings/thirdparty" target="_blank" style="color:#3d3d3d">Tokopedia Seller</a> di menu Pengaturan Aplikasi Pihak Ketiga atau cek email yang dikirim oleh pihak Tokopedia.',
    settingStoreGiveAccessTokopediaDialogFirstButton: "Lanjut",
    settingStoreGiveAccessTokopediaDialogSecondTitle:
      "Izinkan akses Balas Chat",
    settingStoreGiveAccessTokopediaDialogSecondDescription:
      "Selanjutnya kamu perlu centang opsi Balas Chat di pengaturan Chat agar kamu bisa membalas pesan menggunakan {0} yang sudah terhubung.",
    settingStoreGiveAccessTokopediaDialogSecondButton: "Selesai",
    settingStoreConnectViberDialogInstructionDescription:
      'Untuk menghubungkan akun Viber akan dibutuhkan Bot Token, <span class="text-body-14--bold">pastikan kamu sudah memiliki akun Bot.</span>',
    settingStoreConnectViberDialogInstructionTitle:
      "Cara membuat akun Bot dan mendapatkan Bot Token:",
    settingStoreConnectViberDialogInstructionFirst:
      'Login ke akun Viber mu dan masuk ke halaman  <a href="https://partners.viber.com/account/create-bot-account" class="text-body-14--bold" target="_blank" style="color:#3d3d3d">Create bot account.</span>',
    settingStoreConnectViberDialogInstructionSecond:
      '<span class="text-body-14--bold">Input informasi bisnis kamu</span> dan klik <span class="text-body-14--bold">Create</span> untuk membuat akun Bot.',
    settingStoreConnectViberDialogInstructionThird:
      'Setelah bot berhasil dibuat, klik <span class="text-body-14--bold">Info pada akun Bot yang akan digunakan dan salin Token</span> untuk dimasukan dihalaman {0}',
    settingStoreConnectTelegramDialogInstructionDescription:
      'Untuk menghubungkan akun Telegram akan dibutuhkan Bot Token, <span class="text-body-14--bold">pastikan kamu sudah memiliki akun Bot.</span>',
    settingStoreConnectTelegramDialogInstructionTitle:
      "Cara membuat akun Bot dan mendapatkan Bot Token:",
    settingStoreConnectTelegramDialogInstructionFirst:
      'Login ke akun Telegram mu dan <a href="https://t.me/botfather" class="text-body-14--bold" target="_blank" style="color:#3d3d3d">chat BotFather.</span>',
    settingStoreConnectTelegramDialogInstructionSecond:
      'Kirim <span class="text-body-14--bold">/newbot</span> untuk membuat akun baru atau <span class="text-body-14--bold">/token</span> untuk langsung mengambil token',
    settingStoreConnectTelegramDialogInstructionThird:
      'Setelah bot berhasil dibuat, <span class="text-body-14--bold">salin Token</span> untuk dimasukan dihalaman {0}',
    settingStoreFailedConnectChannelInvalidToken:
      "Gagal menghubungkan akun. Token invalid.",
    settingStoreFailedConnectChannelDuplicateOnOtherClient:
      "Token sudah terdaftar. Silahkan coba dengan token lain.",
    settingStoreVerificationLineChannelStatus:
      "Lakukan verifikasi Webhook URL via halaman LINE Console. Salin Webhook URL {0}.",
    settingStoreVerificationLineChannelStatusOpenDialog: "disini",
    settingStoreConnectLineDialogInstructionDescription:
      'Untuk menghubungkan akun Line akan dibutuhkan Channel Access Token dan Channel Secret, <span class="text-body-14--bold">pastikan kamu sudah memiliki akun LINE Official Account.</span>',
    settingStoreConnectLineDialogInstructionTitle:
      "Cara membuat Official Account dan mendapatkan token:",
    settingStoreConnectLineDialogInstructionFirst:
      "Login ke akun LINE Developers atau buat akun di {0} , buat provider dan channel dengan mengikuti langkah {1}.",
    settingStoreConnectLineDialogInstructionFirstHowToLink: "berikut",
    settingStoreConnectLineDialogInstructionSecond:
      'Untuk mendapatkan <span class="text-body-14--bold">Channel Secret:</span> LINE Developer Console > Basic Settings > Channel Secret',
    settingStoreConnectLineDialogInstructionThird:
      'Untuk mendapatkan <span class="text-body-14--bold">Channel Access Token:</span> LINE Developer Console > Messaging API > Channel access token > klik Issue untuk mendapatkan token',
    settingStoreActivateWebhookLineDialogTitle:
      "Masukan dan Aktifkan Webhook {0} di LINE Developers",
    settingStoreActivateWebhookLineDialogDescription:
      "Untuk melanjutkan, salin dan masukkan Webhook URL berikut pada halaman Webhook Settings LINE Developers. Verifikasi dan Aktifkan webhook untuk menghubungkan chat dengan {0}.",

    tooltipSuccessCopyText: "Berhasil disalin",

    settingStoreModeConfirmationCancel: "Tidak, batalkan",
    settingStoreModeChangeConfirmationProceed: "Ya, ganti",
    settingStoreChannelMPChatTitle:
      "Hubungkan ruang obrolan channel jualan kamu",
    settingStoreChannelMPChatDecription:
      "Kamu bisa menghubungkan lebih dari satu akun ke {0}.",
    settingStoreChannelSocialMediaTitle: "Hubungkan fitur pesan media sosial",
    settingStoreChannelFooterDescription: "Datamu terjamin 100% aman di {0}.",
    settingStoreChannelPopUpDialogCreateChannel: "Hubungkan akun {0}",
    settingStoreChannelButtonAddAccount: "Tambah akun",
    settingStoreChannelRestrictionChip:
      "Hanya tersedia untuk paket Pro dan Enterprise",
    settingStoreChannelRestrictionChipConnectedMoreThanLimit:
      "Sudah mencapai batas maksimal {0} akun terhubung. Kamu bisa hapus dulu salah satu akun atau hubungi {1} untuk tambah akun lagi.",
    settingStoreChannelSocialMediaDecription:
      "Kamu hanya bisa menghubungkan satu akun dari tiap platform.",
    surveyRatingTemplateDescription: "Template Survey",
    surveyRatingRemarksDescription: "Keterangan",
    surveyRatingCSTagDescription: "Tanda CS",
    loginBannerTitle: "Kelola chat & transaksi<br>di 1 dashboard",
    loginBannerSubTitle:
      "Partner bisnis resmi Facebook yang terintegrasi dengan<br>" +
      "WhatsApp Business API",
    loginFormTitle: "Masuk",
    loginFormInputEmail: "Email",
    loginFormInputEmailMessageRequired: "Email wajib diisi",
    loginFormInputEmailMessageError: "Format email salah",
    loginFormInputPassword: "Password",
    loginFormInputPasswordMessageRequired: "Password wajib diisi",
    loginFormInputPasswordMessageError: "Email atau password tidak sesuai",
    loginFormSubmit: "Lanjut",
    loginFormSubmitError: "Email atau password tidak sesuai",
    loginFormSubmitErrorAlt:
      "Email atau password tidak sesuai. Pastikan email kamu sudah terdaftar di {0}",
    loginFormForgotPassword: "Lupa Password?",
    loginFormTermHtml:
      'Dengan masuk, kamu menyetujui <a style="color:#8D8D8D; text-decoration:underline;" href="https://koala-asset.s3.ap-southeast-3.amazonaws.com/public/koala-plus-tnc.pdf" target="_blank">syarat & ketentuan</a> KOALA+.',
    loginFormRegisterLabel: "Buat Akun Baru",
    loginFormRegisterAction: "Daftar",

    resetPhoneInputFormTitle: "Lupa Password?",
    resetPhoneInputFormSubtitle:
      "Masukkan no handphone yang terhubung dengan<br>KOALA+ dan kami akan kirim kode OTP.",
    resetPhoneInputFormPhoneLabel: "Nomor HP",
    resetPhoneInputFormPhoneRequired: "Nomor HP wajib diisi",
    resetPhoneInputFormPhoneError: "Format nomor HP tidak valid",
    resetPhoneInputFormPhoneUnregister: "Nomor HP tidak terdaftar di KOALA+",
    resetPhoneInputFormSubmit: "Lanjut",
    resetPhoneInputFormOTPTitle: "Masukkan kode OTP",
    resetPhoneInputFormOTPSubtitle:
      "Tulis 5 digit kode rahasia yang kami kirim via<br>WhatsApp ke nomor:",
    resetPhoneInputFormOTPTimerTitle: "Belum terima kode? ",
    resetPhoneInputFormOTPTimerCount: "Tunggu {0} detik",
    resetPhoneInputFormOTPTimerFinish: "Kirim Ulang",
    resetPhoneInputFormPasswordTitle: "Masukkan password baru",
    resetPhoneInputFormPasswordSubtitle:
      "Password harus 8 digit alfanumerik dan<br>boleh karakter spesial.",
    resetPhoneInputFormPasswordSubmit: "Ubah Password",
    resetPhoneInputFormPasswordNewLabel: "Password",
    resetPhoneInputFormPasswordConfirmLabel: "Konfirmasi Password",
    resetPhoneInputFormSuccessTitle: "Berhasil ubah password",
    resetPhoneInputFormSuccessSubtitle:
      "Sebentar lagi kamu akan diarahkan ke halaman login.",
    resetPhoneInputFormPasswordNewMessageRequired: "Password wajib diisi",
    resetPhoneInputFormPasswordMessageError:
      "Password harus 8 digit alfanumerik dan memiliki karakter spesial.",
    resetPhoneInputFormPasswordConfirmMessageRequired:
      "Konfirmasi Password wajib diisi",
    resetPhoneInputFormPasswordConfirmMessageError:
      "Konfirmasi Password harus sama",

    signUpStepperAccount: "Informasi Akun",
    signUpStepperStore: "Informasi Toko",
    signUpStepperWaba: "Hubungkan WABA",
    signUpStepperSubscription: "Langganan Premium",
    signUpAccountInputFormTittle: "Buat Akun Baru",
    signUpAccountInputFormPhoneLabel: "Nomor HP",
    signUpAccountInputFormPhoneRequired: "Nomor HP wajib diisi",
    signUpAccountInputFormPhoneInfo:
      "Pastikan no. HP bukan nomor yang terdaftar di akun WABA (WhatsApp Business API)",
    signUpAccountInputFormEmailLabel: "Email",
    signUpAccountInputFormEmailRequired: "Email wajib diisi",
    signUpAccountInputFormEmailInvalid: "Email tidak valid",
    signUpAccountInputFormPasswordLabel: "Password",
    signUpAccountInputFormPasswordRequired: "Password wajib diisi",
    signUpAccountInputFormPasswordInfo:
      "Password harus minimal 8 digit alfanumerik dan boleh karakter spesial.",
    signUpAccountInputFormPasswordConfirmLabel: "Konfirmasi Password",
    signUpAccountInputFormPasswordConfirmRequired:
      "Konfirmasi Password wajib diisi",
    signUpAccountInputFormPasswordConfirmHasEqual:
      "Konfirmasi Password harus sama",
    signUpAccountInputFormReferralLabel: "Kode Referral",
    signUpAccountInputFormTermHtml:
      'Dengan masuk, kamu menyetujui<a style="color:#8D8D8D; text-decoration:underline;" href="https://koala-asset.s3.ap-southeast-3.amazonaws.com/public/koala-plus-tnc.pdf" target="_blank"> syarat & ketentuan </a>KOALA+.',
    signUpAccountInputFormLoginLabel: "Sudah punya akun? ",
    signUpAccountInputFormLoginAction: "Login",
    signUpAccountInputFormSubmit: "Daftar",
    signUpStoreInputFormTittle: "Isi detail tokomu",
    signUpStoreInputFormNameLabel: "Nama Toko",
    signUpStoreInputFormAddressLabel: "Alamat Toko",
    signUpStoreInputFormBFieldLabel: "Bidang Usaha",
    signUpStoreInputFormBTypeLabel: "Tipe Bisnis",
    signUpStoreInputSubmit: "Lanjut",
    signUpStoreInputSkip: "Lewati",
    signUpConfirmNewTitle: "Daftar Nomor Baru",
    signUpConfirmNewContent:
      "Dengan daftar KOALA+, maka otomatis Anda telah terdaftar di aplikasi KOALA yang bisa Anda download di Playstore. Semua data akan saling terhubung.",
    signUpConfirmExistTitle: "Nomor HP sudah terdaftar",
    signUpConfirmExistContent:
      "Anda sudah memiliki akun di KOALA App.<br>" +
      "Semua data di akun KOALA App Anda saling terhubung dengan KOALA+.",
    signUpConfirmCancel: "Ubah",
    signUpConfirmSubmit: "Ya, lanjutkan",
    signUpWabaInputFormTitleHtml: "Hubungkan Akun<br>WhatsApp Business API",
    signUpSubscriptionTitle: "Pilih paket berlangganan KOALA+",
    signUpSubscriptionNotes:
      "*Tambah agen Customer Service Live dengan Rp199.000/agen/bulan",
    signUpSuccessTitle: "Berhasil buat akun!",
    signUpSuccessSubtitle:
      "Sebentar lagi kamu akan diarahkan ke halaman dashboard.",
    dashboardGreeting: "Hi, ",
    dashboardSetupAccount: "Yuk atur akun {0} kamu!",
    dashboardSetupStep: "langkah selesai",
    dashboardUpgradeTitle: "Upgrade ke premium",
    dashboardUpgradeTitleAlt: "Hubungkan WABA",
    dashboardUpgradeDescription:
      "Atur akun WhatsApp Business lalu langganan KOALA+ premium untuk menikmati fitur pendukung jualan yang lebih maksimal.",
    dashboardUpgradeDescriptionAlt:
      "Atur akun WhatsApp Business API (WABA) untuk menikmati fitur yang lengkap.",
    dashboardKoalaRestoGreetingTitle: "Selamat datang di KOALA Resto!",
    dashboardKoalaRestoGreetingDescription:
      "Tingkatkan penjualan dengan fitur yang dirancang khusus bagi restoran.",
    dashboardBonusFreeTitle: "Bonus Chat Credits hingga 300.000 buat kamu!",
    dashboardBonusFreeDesc:
      "Dengan berlangganan KOALA+ premium, kamu bisa dapat Saldo Bonus Inbound tiap bulannya.",
    dashboardBonusPremiumTitle: "Bonus Chat Credits sampai total 350.000!",
    dashboardBonusPremiumDesc:
      "Dengan langganan KOALA+ premium, chat creditsmu akan di top up sampai total 350.000 setiap bulannya.",
    dashboardBonusFreeTrialTitle:
      "Selamat! Kamu mendapat akses free trial KOALA+ premium selama 30 hari!",
    dashboardBonusFreeTrialDesc:
      "Kini kamu bisa menggunakan seluruh fitur KOALA+. Masa free trial akan berakhir tanggal",
    dashboardSubsAlert1: "Masa langgananmu berakhir di ",
    dashboardSubsAlert2:
      ". Perpanjang langgananmu yuk agar bisa terus pakai fitur KOALA+.",
    dashboardSubsRenew: "Perpanjang Langganan",
    dashboardTrxSumTitle: "Ringkasan Transaksi",
    dashboardProdListTitle: "Daftar Produk",
    dashboardTrxUnpaid: "Pesanan Baru",
    dashboardTrxProcessed: "Disiapkan",
    dashboardTrxSent: "Siap",
    dashboardProdSale: "Produk Dijual",
    dashboardProdNotSale: "Tidak Dijual",
    dashboardFeatureCheck: "Cek fiturnya",
    dashboardFeatureTitle: "Chat dengan pelangganmu",
    dashboardFeatureBroadcast: "Pesan Broadcast",
    dashboardFeatureBroadcastDetail:
      "Kirim pesan ke banyak kontak dengan praktis.",
    dashboardFeatureChatbot: "Chatbot",
    dashboardFeatureChatbotDetail:
      "Aktifkan chatbot agar kamu bisa balas pesan dengan cepat.",
    dashboardFeatureCS: "Customer Service",
    dashboardFeatureCSDetail:
      "Atur layanan pelangganmu sendiri untuk menjawab setiap pesan yang masuk langsung di dashboard.",
    dashboardViewMore: "Lihat Selengkapnya",
    dashboardHide: "Sembunyikan",
    dashboardSetWABA: "Hubungkan akun WABA kamu",
    dashboardSetWABAAction: "Hubungkan",
    dashboardSubs: "Subscribe KOALA+ premium",
    dashboardSubsAction: "Langganan",
    dashboardStartSellTitle: "Mulai Berjualan",
    dashboardStartSellDescription:
      "Isi informasi toko, tambah produk, dan verifikasi rekening bank untuk mulai jualan",
    dashboardFillStoreInfo: "Isi informasi toko",
    dashboardFillStoreInfoAction: "Isi sekarang",
    dashboardAddProduct: "Tambah produk",
    dashboardAddProductAction: "Tambah",
    dashboardVerifBankAccount: "Verifikasi rekening",
    dashboardVerifBankAccountAction: "Verifikasi",
    dashboardChatCustomerTitle: "Chat Pelangganmu",
    dashboardChatCustomerDescription:
      "Top up chat credits untuk gunakan WhatsApp Business. Kamu juga bisa gunakan fitur lain untuk berkomunikasi dan mempromosikan tokomu ke pelanggan.",
    dashboardTopUpChatCredit: "Top Up Chat Credits",
    dashboardTopUpChatCreditAction: "Top Up",
    dashboardSendBroadcast: "Kirim pesan broadcast",
    dashboardSendBroadcastAction: "Mulai kirim",
    dashboardSetCS: "Atur Chat Customer Service",
    dashboardSetCSAction: "Atur sekarang",
    dashboardActivateChatbot: "Aktifkan Chatbot",
    dashboardActivateChatbotAction: "Aktifkan",
    dashboardWABAReject0:
      "Permohonanmu ditolak Facebook. Silakan atur ulang akun WABA kamu. Mohon perhatikan beberapa hal berikut:",
    dashboardWABAReject1:
      "Pastikan display name kamu sesuai dengan Syarat dan Ketentuan Facebook.",
    dashboardWABAReject2:
      "Pastikan FB Business Manager diverifikasi sesuai dengShopeean",
    dashboardWABARejectFBTerms: "Syarat dan Ketentuan Facebook",
    dashboardWABAReject3:
      "Jika kamu menghadapi kendala lain saat menghubungkan akun WABA, silakan hubungi kami via email ke",
    dashboardCheckDetail: "Lihat Detail",
    dashboardNewFeatureTitle:
      "Atur & tambah informasi kontak untuk data pelanggan",
    dashboardNewFeatureDescription:
      "Kini kamu bisa menambah kolom informasi untuk data pelanggan agar sesuai dengan kebutuhanmu.",
    dashboardNewFeatureButton: "Coba Sekarang",

    transactionTitle: "Transaksi",
    transactionUnpaid: "Pesanan Baru ",
    transactionProcessing: "Disiapkan ",
    transactionSent: "Siap ",
    transactionDone: "Selesai ",
    transactionCancel: "Batal ",
    transactionEmptyTitle: "Belum ada transaksi",
    transactionEmptyInfo:
      "Semua transaksi nanti akan muncul di sini. Buat transaksi melalui aplikasi KOALA ya.",
    transactionNotes: "Catatan",

    additionalVariant: "Varian tambahan",

    transactionOutOfStock: "Stok tidak tersedia",
    transactionMakeAnother: "Buat pesanan lain",

    transactionDeleteProductVariantTitle: "Hapus produk dan varian ini?",
    transactionDeleteProductVariantDesc:
      "Kamu akan menghapus produk dan varian tersebut, sehingga tidak akan masuk dalam pesanan",
    transactionDeleteProductVariantToastSuccess:
      "Berhasil menghapus produk dan varian.",

    transactionAddProductManualSuccess: "Berhasil menambahkan produk.",
    transactionAddProductManual: "Tambahkan produk",
    transactionAddProductManualDisclaimer:
      "Aksi ini tidak akan tersimpan di katalog.",
    transactionAddProductManualDetail: "Detail produk",

    moveChatConfirmationDialogTitle:
      "Pindah ke menu lain dan hapus data transaksi?",
    moveChatConfirmationDialogDescription:
      "Jika pindah menu, data transaksi yang kamu masukkan akan terhapus",
    moveChatConfirmationDialogContinueButton: "Tidak, kembali",
    moveChatConfirmationDialogMoveTo: "Ya, pindah menu",

    forwardChatConfirmationDialogTitle:
      "Teruskan chat dan hapus data transaksi?",
    forwardChatConfirmationDialogDescription:
      "Jika teruskan chat, data transaksi yang kamu masukkan akan terhapus",
    forwardChatConfirmationDialogContinueButton: "Tidak, kembali",
    forwardChatConfirmationDialogMoveTo: "Ya, teruskan chat",

    resolveChatConfirmationDialogTitle:
      "Selesaikan chat dan hapus data transaksi?",
    resolveChatConfirmationDialogDescription:
      "Jika selesaikan chat, data transaksi yang kamu masukkan akan terhapus",
    resolveChatConfirmationDialogContinueButton: "Tidak, kembali",
    resolveChatConfirmationDialogMoveTo: "Ya, selesaikan chat",
    chatbotCustomComponentTitle: "Custom Auto Reply Chatbot",
    chatbotCustomComponentDescription:
      "Saat ini kamu menggunakan custom chatbot paket {0} dan biaya auto reply chatbot {1} chat credits/conversation dalam 24 jam. Untuk melakukan perubahan, kirim email ke",

    infoAllPaymentConfigurationDashboardTitle:
      "Pembayaran online nonaktif sementara",
    infoAllPaymentConfigurationDashboardDescription:
      "Transaksi dengan pembayaran online saat ini tidak dapat dilakukan.",
    infoPaymentConfigurationDashboardTitle: "Pembayaran {0} nonaktif sementara",
    infoPaymentConfigurationDashboardDescription:
      "Transaksi dengan pembayaran {0} saat ini tidak dapat dilakukan.",
    infoPaymentConfigurationChatCreditTitle:
      "Metode pembayaran sementara nonaktif",
    infoPaymentConfigurationChatCreditDescription:
      "Maaf, kamu tidak bisa melakukan top up chat credits saat ini.",
    infoPaymentConfigurationWalletTitle: "Metode pembayaran sementara nonaktif",
    infoPaymentConfigurationWalletDescription:
      "Maaf, saat ini kamu tidak bisa melakukan pencairan dana (otomatis & manual).",

    templateListDescription:
      "Tambah dan atur template yang dapat dikirim ke pelanggan di sini.",
    tabTitleBroadcastAndNewMessage: "Broadcast & Pesan Baru",
    tabTitleTemplate24H: "Template Follow Up",
    tabTitleBroadcastMessage: "Template Broadcast",
    labelNew: "Baru",
    broadcastInformation:
      "Berikut ini adalah daftar template pesan broadcast atau pesan baru.",
    templateInformation:
      "Percakapan akan berakhir (kedaluwarsa) dihitung setelah 24 jam sejak pesan WhatsApp terakhir. Jika ingin melanjutkan percakapan, kamu bisa mengirimkan follow up chat dengan biaya 511.5 chat credits/kontak (sudah termasuk PPN 11%).",

    followUpPageTitle: "Template Follow Up Chat",
    followupHeaderName: "Judul Template",
    followupHeaderContent: "Isi Pesan Template",
    followUpHeaderNameDescription:
      "Beri judul template untuk memudahkan kamu memilih template. Judul tidak akan muncul pada pesan broadcast.",

    createFollowUpSubmitDisclaimerTitle: "Lanjutkan penambahan template?",
    createFollowUpSubmitDisclaimerContent:
      "Template akan melalui proses evaluasi dan verifikasi oleh Meta terlebih dahulu maks. dalam 1x24 jam.",
    createFollowUpSubmitDisclaimerCancelButton: "Batal",

    switcherNewMessageOnText: "Nonaktifkan sebagai template pesan baru",
    switcherNewMessageOffText:
      "Aktifkan agar dapat digunakan sebagai template pesan baru",

    chatroomContactDetailTitle: "Detail percakapan",
    chatroomContactDetailEditTitle: "Edit Kontak",
    chatroomPickPriority: "Prioritas chat",
    chatroomChatDetail: "Detail chat",
    chatroomMediaNoPreview: "Tidak ada preview yang dapat ditampilkan",
    chatroomMediaCaption: "Ketik isi keterangan disini..",
    chatroomMediaDropInstruction:
      "Tarik dan letakkan file yang ingin kamu tautkan ke sini.",
    chatroomPickCS: "Pilih CS",
    chatroomSearchCSNotFoundTitle: "Pencarian tidak ditemukan",
    chatroomSearchCSNotFoundSubtitle: "Silakan gunakan kata kunci yang lain",
    chatroomCSTotalOpenTicket: "{0} Chats",

    chatroomErrorMessageFileWrongFormat:
      "Format gambar tidak didukung. Gunakan format jpeg atau png dan 8bit atau RGB.",
    chatroomErrorMessageFileTooLarge:
      "Ukuran tautan melebihi batas maksimal {0}.",
    chatroomErrorMessageFileNotSupported:
      "Format file tidak didukung di WhatsApp.",
    chatroomErrorMessageContentNotSupported:
      "Tidak bisa menampilkan isi pesan.",
    chatroomErrorMessageVideoDuration:
      "Durasi video melebihi batas maksimal {0} detik",
    chatroomErrorMessageVideoFormat:
      "Format video tidak didukung. Gunakan format MP4 atau H264",
    chatroomErrorMessageImageFormat:
      "Format gambar tidak didukung. Gunakan format jpeg, png atau gif",
    chatroomErrorMessageFileName: "Nama dokumen melebihi batas {0} karakter.",

    chatroomAssignedEvent: "Chat ini telah ditugaskan dari {0} kepada {1}",
    chatroomClosedEvent: "Chat ini telah diselesaikan oleh {0}",

    tooltipNewMessage: "Kirim pesan baru",

    formNewMessageTitle: "Kirim Pesan Baru",
    formNewMessageInformation:
      "Biaya untuk kirim pesan baru mengikuti harga sesuai kategori template.",
    formNewMessagePhoneNumberPlaceHolder: "No. handphone penerima",
    formNewMessagePhoneNumberHint:
      "Masukkan no. HP penerima secara manual, format 628xxx atau 08xxx.",
    formNewMessageSelectTemplatePlaceHolder: "Pilih template pesan baru",
    formNewMessageFooter:
      "Silakan hubungi admin toko untuk menambahkan template pesan baru lainnya.",
    formNewMessageCancel: "Batal",
    formNewMessageSubmit: "Kirim Pesan",

    formNewMessageNoTemplateHeader: "Belum ada template yang bisa dipakai",
    formNewMessageNoTemplateContent:
      'Silakan hubungi admin toko untuk menambahkan template pesan baru. Jika kamu memiliki kendala seputar ini, silakan kirim pesan WhatsApp ke KOALA Customer Care melalui no. <a style="color:#1843A1; text-decoration:none;" href="https://wa.me/6287752670270" target="_blank">087752670270</a>.',

    formatNewMessagePhoneNumberInvalid: "No. handphone tidak valid",
    formatNewMessagePhoneNumberIsEmpty: "No. handphone penerima wajib diisi",
    formatNewMessageSelectedTemplateIsEmpty: "Template pesan baru wajib diisi",
    formatNewMessageSelectedTemplateLength: "No. handphone minimal 9-13 angka",
    formatNewMessageNumberIsActive:
      "Tidak bisa mengirimkan pesan baru karena nomor ini masih berada di list chat yang masih aktif. Silahkan selesaikan chat terlebih dahulu.",

    chatRoomCantSendMessage:
      "Kamu berhasil mengirimkan pesan baru, namun kamu belum dapat membalas pesan ini. Tunggu sampai pelanggan membalas.",
    chatRoomExpiredChatHeader: "Chat ini telah kedaluwarsa",
    chatRoomExpiredChatAttachmentInfo:
      "Tidak bisa download attachment jika chat expired",
    chatRoomExpiredChatContentWhatsApp:
      "Jika masih ingin mengirim chat, silakan kirim template follow up chat. Namun, chat akan tetap kedaluwarsa hingga pelanggan membalas.",
    chatRoomExpiredChatContentFacebook:
      "Kamu tidak dapat membalas chat dari Facebook ini karena sudah lebih dari 24 jam sejak terakhir kali pelanggan membalas. Silakan untuk selesaikan chat ini.",
    chatRoomExpiredChatContentInstagram:
      "Kamu tidak dapat membalas chat dari Instagram ini karena sudah lebih dari 24 jam sejak terakhir kali pelanggan membalas. Silakan untuk selesaikan chat ini.",
    chatRoomExpiredChatContentTelegram:
      "Kamu tidak dapat membalas chat dari Telegram ini karena sudah lebih dari 24 jam sejak terakhir kali pelanggan membalas. Silakan untuk selesaikan chat ini.",
    chatRoomExpiredChatButton: "Kirim Follow Up Chat",
    chatRoomTextAreaMaximumCharacterError: "Tidak bisa lebih dari {0} karakter",

    formFollowUpTitle: "Kirim Follow Up Chat 24 Jam",
    formFollowUpInformation:
      "\u2022 Chat akan tetap kedaluwarsa hingga pelanggan membalas.<br>" +
      "\u2022 Biaya kirim pesan template mengikuti harga sesuai kategori template.",
    formFollowUpSelectTemplatePlaceHolder: "Pilih template follow up chat",
    formFollowUpFooter:
      "Silakan hubungi admin toko untuk menambahkan template lainnya.",

    formFollowUpNoTemplateHeader: "Belum ada template yang bisa dipakai",
    formFollowUpNoTemplateContent:
      'Silakan hubungi admin toko untuk menambahkan template. Jika kamu memiliki kendala, silakan kirim pesan WhatsApp ke KOALA Customer Care melalui no. <a style="color:#1843A1; text-decoration:none;" href="https://wa.me/6287752670270" target="_blank">087752670270</a>.',

    followUpSelectedTemplateIsEmpty: "Wajib memilih template follow up chat",

    newMessageSuccessSnackBar: "Pesan baru berhasil dikirim",
    followUpChatSuccessSnackBar: "Template Follow Up Chat berhasil dikirim",

    chatCreditIsEmptyHeader: "Chat credits tidak cukup",
    chatCreditIsEmptyNewMessageContent:
      "Kamu tidak dapat mengirim pesan baru karena chat credits tidak cukup.<br><br>" +
      "Silakan hubungi admin toko untuk melakukan top up chat credits agar dapat mengirimkan pesan baru kembali.",
    chatCreditIsEmptyFollowUpChatContent:
      "Kamu tidak dapat mengirim follow up chat 24 jam karena chat credits tidak cukup.<br><br>" +
      "Silakan hubungi admin toko untuk melakukan top up chat credits agar dapat mengirimkan follow up chat 24 jam kembali.",

    smartChatSettingStoreGoToSmartSeller:
      "Penggantian data bisa dilakukan di SmartSeller",

    subscriptionFixedBannerFreeTrialPeriod:
      "Free trial akan berakhir {0} hari lagi.",
    subscriptionFixedBannerFreeTrialPeriodEndsToday:
      "Free trial akan berakhir hari ini.",
    subscriptionFixedBannerPackagePeriod:
      "Paket langgananmu akan habis dalam {0} hari.",
    subscriptionFixedBannerPackageEndsToday:
      "Paket langgananmu akan berakhir hari ini.",
    subscriptionFixedBannerUpgradeCTAOwner: "Yuk, upgrade akunmu sekarang!",
    subscriptionFixedBannerUpgradeCTACS:
      "Hubungi owner untuk upgrade paket langganan.",
    subscriptionFixedBannerRenewCTAOwner: "Segera perpanjang sekarang.",
    subscriptionFixedBannerRenewCTACS:
      "Ingatkan owner untuk perpanjang paket langgananmu.",

    forgotPasswordConfirmationTitle: "Lupa Password?",
    forgotPasswordConfirmationInfo:
      "Kamu akan diarahkan ke Smart Seller untuk mengubah password kamu",
    forgotPasswordConfirmationButtonCancel: "Kembali",
    forgotPasswordConfirmationButtonConfirm: "Ya, lanjutkan",
    downloadHistoryChatDialogTitle: "Download Riwayat Chat",
    downloadHistoryChatDialogFrom: "Dari",
    downloadHistoryChatDialogUntil: "Sampai (maks. 31 hari)",
    downloadHistoryChatDialogXls: "Format file download adalah .xls",
    downloadHistoryChatDialogButton: "Download",
    downloadHistoryChatErrorDownload:
      "Download gagal, silahkan cek kembali jaringan internet kamu",

    resolvedDuplicateChatConfirmationDialogTitle: "Konfirmasi selesaikan chat",
    resolveDuplicateChatConfirmationDialogDescription:
      "Kami mendeteksi adanya lebih dari 1 chat terhadap nomor ini yang sedang berlangsung. Apakah Anda yakin untuk menyelesaikan seluruh chat yang aktif pada nomor customer ini?",
    resolveDuplicateChatConfirmationDialogCancelButton: "Kembali",
    resolveDuplicateChatConfirmationDialogConfirmButton: "Ya, lanjutkan",
    chatMultipleStoreLabel: "Semua channel",
    chatRoomPermissionAccessChatHeader:
      "Beri izin akses balas chat pihak ketiga",
    chatRoomPermissionAccessChatContent: `Agar bisa membalas chat ini, kamu perlu beri izin akses {0} untuk balas chat via halaman <a href="https://seller.tokopedia.com/settings/thirdparty" target="_blank">Tokopedia Seller</a> atau via email yang dikirim oleh pihak Tokopedia.`,

    tooltipAttachmentButtonChannelSupport:
      "Fitur kirim tautan tidak tersedia di channel {0}",

    tooltipAddChannelButton: "Hanya owner/admin yang dapat menambahkan channel",

    koalaPlusLoginIllustrationHeaderText:
      "Kelola chat & transaksi di 1 dashboard",
    koalaPlusLoginIllustrationSubtitleText:
      "Partner bisnis resmi Facebook yang terintegrasi dengan WhatsApp Business API",

    emptyString: "",
    seeProductDetail: "Lihat detail produk",
    seeTransactionDetail: "Lihat detail transaksi",
    productInformation: "Informasi Produk",
    customerCreatesTransaction: "Customer membuat transaksi",

    chatroomMPChatInfo:
      "{0} hanya akan menampilkan tautan yang kamu kirim di dalam {0} atau sesudah channel terhubung.",

    loginFormEmailNotRegisteredPartner:
      "Ups, email tersebut belum terdaftar. Pastikan owner sudah mendaftarkan email kamu ke {0}.",

    labelChatCreditsTopUpSelectPayment: "Pembayaran",
    labelChatCreditsTopUpSelectPaymentSummary: "Ringkasan",
    labelChatCreditsTopUpGuide: "Isi nominal top up dengan angka",

    labelSubscriptionPaymentSelection: "Pilih Metode Pembayaran",
    labelSubscriptionPackageSelection: "Pilih Paket Langganan",
    labelSubscriptionPackageSelectionUpgrade: "Harga Upgrade",
    labelSubscriptionCalculationSummary: "Ringkasan",
    labelSubscriptionPaymentBaseAmount: "Total Harga Langganan",
    labelSubscriptionExtendDuration: "Sekalian tambah durasi langganan",
    labelSubscriptionPackageEndUntil: "paket aktif sampai",
    labelServiceFee: "Biaya Layanan",
    labelServiceFeeDescription:
      "Biaya administrasi yang dikenakan KOALA+ untuk pengguna",
    labelTaxDescription: "{0} dari total harga langganan + biaya layanan",
    labelSubscriptionCalculationTotal: "Total Pembayaran",
    subscriptionPaymentEmptySelectionMessage: "Wajib memilih metode pembayaran",
    paymentSelectionFeeAmountDescription: "Dikenakan biaya layanan ",
    subscriptionPaymentPreTncDesc: "Dengan lanjut bayar, kamu menyetujui",
    subscriptionPaymentPostTncDesc: "yang berlaku",
    subscriptionPaymentTncDesc: "syarat dan ketentuan",
    subscriptionTncTitle: "Syarat dan Ketentuan Langganan {0}",
    subscriptionTncContentsmart_seller: `<ol>
    <li>Kamu dapat berlangganan paket SmartChat (Starter dan Pro) saat akun masih dalam status Free atau Free Trial dengan pilihan periode 3/6/12 bulan.</li>
    <li>Lama 1 bulan dalam berlangganan adalah 30 hari.</li>
    <li>Kamu tidak dapat membatalkan atau refund paket berlangganan SmartChat yang sudah aktif.</li>
    <li>Kamu tidak dapat ganti dari paket Pro ke paket Starter (downgrade) jika masih aktif berlangganan Pro.</li>
    <li>Kamu bisa ganti ke paket Pro (upgrade) dengan biaya Rp71.000 per bulan (30 hari). Perhitungan bulan akan dilakukan pembulatan ke atas untuk perhitungan biaya upgrade.</li>
    <li>Upgrade paket tidak akan menambah masa berlaku paket. Untuk menambah masa berlaku, kamu perlu perpanjang langganan dan akan dikenakan biaya seharga paket yang dipilih.</li>
  </ol>`,
    subscriptionTncContentkoala_plus: `<ol>
    <li>Kamu dapat berlangganan paket Koala+ (Starter dan Pro) saat akun masih dalam status Free atau Free Trial dengan pilihan periode 3/6/12 bulan.</li>
    <li>Lama 1 bulan dalam berlangganan adalah 30 hari.</li>
    <li>Kamu tidak dapat membatalkan atau refund paket berlangganan Koala+ yang sudah aktif.</li>
    <li>Kamu tidak dapat ganti dari paket Pro ke paket Starter (downgrade) jika masih aktif berlangganan Pro.</li>
    <li>Kamu bisa ganti ke paket Pro (upgrade) dengan biaya Rp300.000 per bulan (30 hari). Perhitungan bulan akan dilakukan pembulatan ke atas untuk perhitungan biaya upgrade.</li>
    <li>Upgrade paket tidak akan menambah masa berlaku paket. Untuk menambah masa berlaku, kamu perlu perpanjang langganan dan akan dikenakan biaya seharga paket yang dipilih.</li>
  </ol>`,
    infoBannerTitle:
      "Penambahan kategori dan subkategori untuk template broadcast",
    infoBannerSubTitle:
      "Sekarang, template akan dibagi ke dalam beberapa kategori dan subkategori berdasarkan isi pesan",
    categoryMessageTitle: "Kategori Pesan",
    categoryMessageSubTitle:
      "Pilih kategori yang mendeskripsikan pesan. Kategori akan berpengaruh pada persetujuan oleh Meta.",
    categoryMessageLearnMore: "Pelajari selengkapnya",
    categoryMessageTnC:
      "Apabila isi konten pada template tidak sesuai dengan kategori yang dipilih, maka akan ada penyesuaian untuk menentukan kategori yang sesuai oleh Meta.",
    authentication: "Keamanan",
    authenticationDetail:
      "Pesan berisi kode verifikasi (OTP) atau login akun. Untuk membuat template dengan kategori ini, silakan hubungi",
    verificationCode: "Kode Verifikasi (OTP)",
    recoveryAccount: "Kode Pemulihan Akun",
    utility: "Akun & Transaksi",
    utilityDetail: "Pesan berisi info terkait proses transaksi dan akun.",
    infoOrderStatus: "Info Status Pesanan",
    infoPayment: "Info Pembayaran",
    notificationReminder: "Notifikasi Pengingat",
    updateAccountInfo: "Pembaruan Info Akun",
    marketing: "Marketing",
    marketingDetail:
      "Pesan berisi info produk, layanan, atau info lainnya yang tidak berkaitan dengan transaksi atau verifikasi.",
    greeting: "Pesan Sambutan",
    promotion: "Promosi",
    infoAndRecomenndation: "Info & Rekomendasi",
    catalog: "Katalog",
    categoryTnC:
      "Jika isi pesan template tidak sesuai dengan kategori yang dipilih, pihak Meta akan melakukan penyesuaian kategori pesan.",
    infoCategoryMessage: "Info Kategori Pesan",
    hikingShoes: "Sepatu Mendaki",
    marketingBodyText:
      "Halo Kak, bersiaplah untuk pergi kegunung dengan sepatu baru kami! Berpetualang kemanapun bersama #SHO",
    checkHere: "Cek di sini",
    utilityBodyText:
      "Halo Kak, pesanan kamu sedang diproses dan akan kami kirim secepatnya.",
    seeDetail: "Lihat detail",
    authenticationBodyText:
      "Kode verifikasi kamu adalah 288955. Untuk keamanan jangan beri tau kode ini kesiapun.",
    autofill: "Autofill",
    marketingExampleDetail:
      "Pesan tentang produk, layanan, dan lainnya yang bukan bagian dari utilitas atau autentikasi.",
    marketingExample:
      "Contoh: pesan sambutan, buletin, promo, kupon, katalog, info jam buka toko baru.",
    utilityExampleDetail:
      "Pesan berisi info terkait proses transaksi, status pesanan, dan pembaruan akun.",
    utilityExample:
      "Contoh: konfirmasi pesanan, pembaruan akun, tanda terima, pengingat janji temu, tagihan.",
    authenticationExampleDetail:
      "Pesan berisi kode untuk membantu verifikasi pembelian atau login akun.",
    authenticationExample:
      "Contoh: kode verifikasi (OTP), kode pemulihan akun.",
    learnMoreTnC:
      "Jika isi pesan tidak sesuai dengan kategori yang dipilih, pihak Meta akan melakukan penyesuaian kategori pesan.",
    okUnderstand: "Oke, Mengerti",
    categoryErrorCase: "Kategori harus dipilih",

    chatroomLoadingText:
      "Please wait, we are still preparing the chat details...",
    chatroomLoadingError: "Data gagal diambil. Silakan coba lagi",
    subscriptionFreeTrialText: "Free Trial",
    subscriptionFreeTrialValid:
      "Coba gratis semua fitur paket starter sampai {0}", // 16 Mei 2023
    subscriptionPackageActiveText: "Paket Aktif",
    subscriptionPackageExpiredText: "Paket Kedaluwarsa",
    subscriptionStartButtonText: "Mulai berlangganan",
    subscriptionExtendButtonText: "Perpanjang langganan",
    subscriptionUpgradeButtonText: "Upgrade paket",
    subscriptionValidText: "sampai",
    subscriptionBannerSuccessText: "Berhasil mengaktifkan paket langganan",
    subscriptionPackageActivePackageText: "Paket langganan {0} masih aktif",
    subscriptionContactUsText: "Hubungi kami",
    subscriptionDialogCancelText: "Batalkan",
    subscriptionDialogProceedText: "Ya, ganti paket",
    subscriptionDialogProceedTextExceedLimit: "Oke, mengerti",
    subscriptionDialogTitleText: "Ganti paket langganan",
    subscriptionDialogTitleTextExceedLimit:
      "Ganti paket langganan yang lebih hemat?",
    subscriptionDialogDescriptionText:
      "Akun {0} yang terhubung tidak akan bisa kamu gunakan jika mengganti ke paket Starter",
    subscriptionDialogDescriptionTextExceedLimit:
      "Jika ingin turun paket, kamu perlu menghapus jumlah channel hingga menjadi maks. 50 channel saja.",
    subscriptionFreeTrialButtonText: "Coba gratis {0} hari",

    addingVariableCTA:
      "Tambahkan variabel akan lebih mudah mengirimkan pesan broadcast yang mencantumkan data pelanggan.",
    addVariable: "+ Tambahkan Variabel",
    addVariableLearnSeeMore: "Lihat selengkapnya",
    titleAddVariable: "Tambah variabel",
    chooseVariable: "Pilih variabel yang mau ditambahkan",
    selfInputVariable: "Bisa juga isi variabel sendiri",
    inputVariable: "Masukkan variabel",
    variableErrorState: "Wajib isi variabel",

    titleAddSample: "Contoh konten variabel",
    subTitleAddSample: "Buat contoh konten variabel untuk verifikasi",
    contentSubTitleAddSample:
      "Tambahkan contoh konten variabel saat pesan template dikirim untuk membantu proses verifikasi oleh Meta. Pastikan contoh konten tidak mencantumkan info asli pelanggan.",
    fieldAddSamplePlaceHolder:
      "Masukkan contoh konten, misalnya untuk no. HP isi 08123456789",
    backButtonSampleDialog: "Kembali",
    sampleErrorState: "Contoh konten harus diisi",
    variableErrorStateSpecialChar:
      "Tidak boleh mengandung karakter khusus pada variabel",

    templateHeaderCategorySubCategory: "Kategori & Sub Kategori",
    bannerVariableHeader:
      "Baru! Kirim broadcast bisa lebih personal dengan variabel dalam template",
    bannerVariableContent:
      "Kamu bisa memuat info pelanggan yang berubah-ubah seperti nama dan detail pesanan.",
    learnMore: "Lihat Selengkapnya",
    tooltipVariableTemplate:
      "Template dengan variabel tidak bisa menjadi pesan baru.",
    tooltipTemplateRejected: "Belum bisa digunakan sebagai pesan baru",
    tooltipVariableLabel:
      "Pesan yang mencantumkan info pelanggan seperti nama, no. HP, no. pesanan, dll.",

    broadcastDetailEdit: "Edit",
    broadcastPriceInfoBanner:
      "Biaya untuk pesan broadcast adalah {0} chat credits/kontak dan bisa berubah tergantung country code.",
    downloadForTemplateContact: "Pilih penerima broadcast",
    labelRadioStaticTemplateAllContact: "Semua pelanggan",
    labelRadioStaticTemplateOneByOne: "Isi nomor HP satu per satu",
    labelRadioStaticTemplateBulk: "Isi banyak nomor HP sekaligus",
    labelRadioStaticTemplateGroup: "Pilih kelompok pelanggan",
    labelRadioDownloadDynamicTemplateWithoutData:
      "Download template dan isi data penerima",
    labelRadioDownloadDynamicTemplateWithData:
      "Penerima dari kelompok pelanggan",
    labelAutoCompleteDownloadDynamicTemplateNoGroupData:
      "Kamu belum memiliki kelompok pelanggan",
    labelAutoCompleteDownloadDynamicTemplateWithData:
      "Pilih Kelompok Pelanggan",
    labelAutoCompleteDownloadDynamicTemplateWithDataButton: "Pilih",
    broadcastDownloadDynamicTemplateWithDataDescriptionNotCompleted:
      "Download data penerima lalu lengkapi data yang kosong dengan klik tombol di bawah.",
    broadcastDownloadDynamicTemplateWithDataDescriptionCompleted:
      "Download data penerima untuk melakukan pengecekan.",
    broadcastUploadDynamicTemplateWithDataDescriptionNotCompleted:
      "Upload kembali data yang sudah kamu lengkapi dengan format <b>.xls/.xlsx</b>.",
    broadcastUploadDynamicTemplateWithDataDescriptionCompleted:
      "Jika ada pembaruan data, silakan upload lagi file kamu dengan format .xls/.xlsx.",
    broadcastUploadDynamicTemplateAlertNotCompleted:
      "Data penerima broadcast belum lengkap. Silakan download data penerima, isi data yang kosong, lalu upload lagi.",
    broadcastUploadDynamicTemplateAlertCompleted:
      "Data penerima broadcast sudah lengkap dan kamu bisa langsung kirim pesan broadcast kepada pelanggan.",
    broadcastDownloadDynamicTemplateButton: "Download",
    broadcastUploadDynamicTemplateButton: "Upload",
    broadcastUploadDynamicTemplateTooltip:
      "Hapus file yang sudah diupload untuk mengupload file baru.",
    labelSetGroupBroadcastData: "Atur kelompok pelanggan",
    placeholderAutoCompleteDownloadDynamicTemplateWithData: "{0} ({1} kontak)",
    downloadForTemplateContactContent:
      "Untuk mengirimkan pesan broadcast, silahkan <b>download .xlsx</b> template dibawah ini. File sudah disusun berdasarkan struktur template yang kamu pilih.",
    broadcastDownloadTitle: "1. Download Template",
    broadcastDownloadDescription:
      "Untuk mengirimkan pesan broadcast, silahkan <b>download & isi template</b>. File sudah disusun berdasarkan struktur template yang kamu pilih.",
    broadcastUploadTitle: "2. Upload template",
    broadcastUploadDescription:
      "Pastikan semua data penerima sudah terisi, kemudian upload file template dengan format file <b>.xls</b> / <b>.xslx</b>.",

    broadcastSelectedTemplateEmpty: "Wajib pilih template",
    broadcastDateEmpty: "Wajib pilih tanggal",
    broadcastTimeEmpty: "Wajib pilih jam",
    chatroomDownloadFileError:
      "Tidak dapat memuat file. Silahkan coba beberapa saat lagi.",
    labelVariable: "Variabel",
    uploadedFileTitle: "File yang kamu upload",
    tooltipButtonUploadFile:
      "Untuk upload file baru, hapus dulu file yang kamu upload sebelumnya.",
    noUploadedFileErrorMessage: "Silakan upload file terlebih dahulu",

    getPaymentMethodFailedError:
      "Gagal memuat metode pembayaran, silakan muat ulang",

    storeSettingContactTitle: "Kontak Toko",
    storeSettingContactPhoneTitle: "No handphone {0}",
    storeSettingContactPhoneTitleDescription:
      "Nomor yang terhubung dengan akun {0} Anda",
    storeSettingContactPhoneUpdate:
      "Untuk saat ini, penggantian nomor hanya bisa melalui {0}",

    storeSettingContactPhoneUpdateAlt1:
      "Untuk saat ini, penggantian nomor hanya bisa melalui {0} atau hubungi tim sales",

    storeSettingContactWABATitle: "Nomor WABA",
    storeSettingContactWABATitleDescription:
      "Nomor yang terhubung dengan akun WABA (WhatsApp Business API), tidak boleh sama dengan nomor {0}",
    storeSettingContactWABAUpdate:
      "Jika ada kendala dalam menghubungkan akun WABA, kontak {0}",
    storeSettingContactWABAUpdateAlt1:
      "Jika ada kendala dalam menghubungkan akun WABA, kontak {0} atau hubungi tim sales",
    storeSettingContactWABAButton: "Hubungkan Akun WABA",
    storeSettingContactWABAPlaceholder: "Anda belum menghubungkan akun WABA",

    storeSettingContactEmailTitle: "Email",
    storeSettingContactEmailUpdate:
      "Untuk saat ini, penggantian email hanya bisa melalui {0}",
    storeSettingContactEmailUpdateAlt1:
      "Untuk saat ini, penggantian email hanya bisa melalui {0} atau hubungi tim sales",
    businessFieldToyAndHobbyLocalsDesc: "Mainan & Hobi",
    businessFieldMaterialAndPropertyLocalsDesc: "Bahan Bangunan & Pertukangan",
    businessFieldBeautyAndHealthLocalsDesc: "Kecantikan & Kesehatan",
    businessFieldAnimalLocalsDesc: "Hewan & Perawatan Hewan",
    businessFieldClothAndAccessoryLocalsDesc: "Pakaian & Aksesoris",
    businessFieldVirtualProductLocalsDesc: "Produk Virtual",
    businessFieldFnBLocalsDesc: "Makanan & Minuman",
    businessFieldShopAndMarketLocalsDesc: "Warung/ Toko Kelontong/ Minimarket",
    businessFieldFurnitureAndDecorationLocalsDesc: "Furnitur & Dekorasi Rumah",
    businessFieldElectronicLocalsDesc: "Elektronik & Gadget",
    businessFieldAutomotiveLocalsDesc: "Otomotif",
    businessFieldFarmAndFisheryLocalsDesc: "Pertanian/ Perikanan/ Peternakan",
    businessFieldServiceLocalsDesc: "Layanan & Jasa",
    businessFieldOthersLocalsDesc: "Lainnya",
    businessModeRestaurantLocalsName: "Restoran",
    businessModeRestaurantLocalsDescription:
      "Fitur di {0} akan disesuaikan dengan kebutuhan restoran seperti pilihan layanan,cetak QR code untuk pemesanan, dll.",
    businessModeOnlineShopLocalsName: "Toko Online",
    businessModeOnlineShopLocalsDescription:
      "Fitur di {0} akan disesuaikan dengan kebutuhan toko online seperti atur kurir, bagikan link toko, dll",

    metabaseFilterPeriodToday: "Hari ini",
    metabaseFilterPeriodYesterday: "Kemarin",
    metabaseFilterPeriodPreviousXDay: "{0} hari sebelumnya",
    metabaseFilterPeriodThisMonth: "Bulan ini",
    metabaseFilterPeriodPreviousMonth: "Bulan sebelumnya",
    metabaseFilterChannelAll: "Semua",

    dashboardCTAButtonReplacementText:
      "** Hubungi tim sales untuk aktivasi fitur ini berdasarkan paket langganan",
    dashboardChatbotButtonReplacementText:
      "** Hubungi tim sales untuk kostumisasi chatbot sesuai paket langganan",
    chatListMultipleSelectActionText: "Beri aksi untuk banyak chat",
    chatListMultipleSelectActionTooltip: "Total chat dipilih",
    resolveChatConfirmationTotalMessage: " ({0} Pesan)",
    assignToCustomerServiceSuccessNotif: "Pesan berhasil diteruskan ke {0}.",
    assignToCustomerServiceFailedNotif: "Pesan gagal diteruskan ke {0}.",
    transactionSearchPlaceholder: "No Transaksi / Nama Pelanggan",
    transactionDeliveryTypePlaceholder: "Tipe Layanan",

    botTooltipEmptyContent:
      "Butuh bantuan untuk membuat template? Asistenmu siap membantu!",
    botTooltipContent: "Mau template lebih optimal? Klik di sini.",

    botHeader: "Asisten membantu anda membuat draft pesan",
    botOptionMarketingIdea: "Ide pesan marketing",
    botOptionNotificationIdea: "Ide pesan notifikasi",
    botOptionAuthenticationIdea: "Ide pesan autentikasi",
    botOptionOtherIdea:
      "Ide pesan pemberitahuan bahwa<br>besok bisnis kami tutup",
    botTextFieldPlaceHolder: "Infokan kepada Asisten apa yang bisa dibantu",
    botButton: "Proses",
    botOptionTransToIndonesiaLanguage: "Ubah ke Bahasa Inggris",
    botOptionTransToEnglishLanguage: "Ubah ke Bahasa Indonesia",
    botOptionShortenMessage: "Persingkat pesan",
    botOptionAnotherIdea: "Buat lebih menarik",
    botLoadingText: "Asisten sedang mengetik...",
    botUseIdea: "Gunakan",
    botNotSatified: "Belum puas dengan rekomendasi pesan di atas?",
    botOptionImprovementIdea: "Perbaiki pesan lebih menarik",
    botOptionDictionIdea: "Gunakan opsi kata lainnya",
    botErrorContent: "Mohon maaf ada kendala, silakan coba beberapa saat lagi",
    botErrorContentRefreshButton: "Proses ulang",
    botEmptyContent: "Tulis pesan dan Asisten akan membantumu menemukan ide",

    cancelBulkDownloadAttachmentButton: "Batalkan",
    downloadBulkDownloadAttachmentButton: "Download",

    mediaInputAddMore: "Tambah",
    menuCSPerformanceTabReport: "Report",
    reportSearchLabel: "Cari report...",
    reportPeriodLabel: "Periode",
    reportSearchButton: "Cari",
    reportAdditionalParamsInputTitle: "Masukan Parameter Report",
    reportMetabaseSecretKeyError: "Metabase tidak memiliki secret key.",
    reportMetabaseGeneralError:
      "Gagal mendapatkan data report. Silahkan coba beberapa saat lagi.",
    enableNotification: "Aktifkan Notifikasi",
    enableNotificationGuide:
      "Gak mau ketinggalan chat dari pelanggan? Klik icon 🔒 di samping URL {0}, lalu pada pilihan notifikasi, klik tombol aktifkan.",
    chatroomDeletedMessageContent: "Pesan ini telah dihapus.",
    deleteBulkDeleteAttachmentText: "Anda yakin ingin menghapus {0} pesan ini?",
    deleteBulkDeleteAttachmentButton: "Hapus",
    cancelBulkDeleteAttachmentButton: "Batalkan",
    statusResponded: "Dibalas",
    statusUnresponded: "Belum Dibalas",
    titleBlankReport: "Belum ada laporan",
    subtitleBlankReport:
      "Hubungi team {0} untuk menambahkan laporan sesuai kebutuhanmu",

    createTemplateFormLanguage: "Bahasa",
    createTemplateFormLangugeSubtext: "Pilih bahasa untuk template pesan Anda",

    variableOptionName: "Nama",
    variableOptionPhoneNumber: "No. HP",
    variableOptionDate: "Tanggal",
    variableOptionTransactionStatus: "Status transaksi",
    variableOptionTransactionNo: "No. transaksi",
    variableOptionAddress: "Alamat",
    variableOptionTrackingNo: "No. resi",
    variableOptionBilling: "Total tagihan",
    variableOptionProductName: "Nama produk",
    variableOptionDiscountCode: "Kode diskon",
    variableOptionCustomerId: "ID pelanggan",
    variableOptionSize: "Ukuran",
    variableOptionTime: "Jam",
    variableOptionLink: "Link",

    maxAttachmentCountExceeded: "Batas Maksimum 20 Attachment Terlampaui",
    orderTotalQuantity: "{0} items",
    orderTotalPrice: " {0} (total estimasi)",
    orderDetailButton: "Lihat detail pesanan",
    orderDetailDialogTitle: "Detail Pesanan",
    orderDetailDialogSku: "SKU: {0}",
    orderDetailDialogProduct: "{0} Produk",
    orderDetailDialogTotalPrice: "Total Pesanan",
    errorGetOrdersDetails: "Gagal mendapatkan detail ({0})",
    productDetailButton: "Lihat detail produk",
    productDetailDialogTitle: "Detail Produk",
    productDetailDialogDescription: "Deskripsi Barang",
    productDetailDialogSku: "SKU",
    productDetailDialogWebsite: "Website",

    createTemplateFormButton: "Tambahkan Tombol",
    createTemplateFormButtonSubText:
      "Buat tombol yang memungkinkan pelanggan menanggapi pesan atau mengambil tindakan",
    createTemplateFormButtonQuickReply: "Balasan Cepat",
    createTemplateFormButtonCallToAction: "Ajakan Bertindak",
    createTemplateFormButtonTypeNone: "Tanpa Tombol",
    createTemplateFormButtonAddQuickReplyButton: "Tambah tombol",
    createTemplateFormButtonQuickReplyPlaceholder: "Teks tombol",
    createTemplateFormButtonQuickReplyError:
      "Tidak bisa lebih dari 25 karakter",
    createTemplateFormButtonQuickReplyErrorSeeAllButtons: "Lihat semua opsi",
    createTemplateFormButtonQuickReplyDuplicateErrorMessage:
      "Tidak dapat memasukkan teks yang sama untuk beberapa tombol",
    createTemplateFormUrlDuplicateErrorMessage:
      "Tidak dapat memasukkan url yang sama untuk beberapa tombol",
    createTemplateFormButtonCallToActionUrlStaticPlaceholder:
      "Contoh: http://koalaapp.id",
    createTemplateFormButtonCallToActionUrlDynamicPlaceholder:
      "Broadcast file input URL",
    createTemplateFormButtonVisitWebsiteEmptyError: "Url harus diisi",
    createTemplateFormButtonVisitWebsiteFormatrror:
      "Url diawali dengan http:// atau https://",

    menuCSPerformanceBroadcastReportTab: "Laporan Broadcast",

    coachmarkBackButton: "Kembali",
    coachmarkNextButton: "Lanjut",
    coachmarkDoneButton: "Selesai",
    coachmarkOkButton: "Oke",
    coachmarkStartChannel:
      "Hubungkan akun marketplace dan media sosial kamu di sini.",
    coachmarkConnectChannel: "Yuk, lanjut lihat chat pelanggan di Chat Masuk.",
    coachmarkListAdminSetting: "Ini daftar pengguna yang punya akses ke {0}.",
    coachmarkChatSettingTourReplySetting:
      "Kamu bisa atur chat balasan otomatis tergantung kebutuhanmu.",
    coachmarkChatSettingTourQuickReply:
      "Kamu bisa membuat shortcut untuk membalas chat lebih cepat.",
    coachmarkChatSettingTourRemarks:
      "Mau tandai chat yang ingin diselesaikan? Beri keterangan di sini.",
    coachmarkChatSettingTourCategory:
      "Jika chat masih aktif, kelompokkan sesuai kategorinya di sini.",
    coachmarkChatPageTourChatList:
      "Pilih dan balas chat pelanggan mulai dari sini.",
    coachmarkChatPageTourAssignButton:
      "Lalu, tugaskan pengguna untuk membalas pesan dengan klik di sini.",
    coachmarkChatPageTourAssignCs:
      "Tugaskan admin dari pilihan admin yang telah terdaftar di {0}.",
    coachmarkConnectLiveChat:
      "Klik di sini untuk menambahkan widget Live Chat di website kamu.",
    coachmarkConnectChannelWithLiveChat:
      "Hubungkan akun marketplace, web Live Chat, dan media sosial kamu di sini.",
    coachmarkStartChannelWithEmail:
      "Hubungkan akun marketplace, email, dan media sosial kamu di sini",
    coachmarkStartChannelWithLiveChatAndEmail:
      "Hubungkan akun marketplace, email, media sosial, dan web Live Chat kamu di sini",

    coachmarkTabFeaturesAccess:
      "Tambah role baru untuk mengatur menu yang dapat diakses user.",
    columnNameTotalBroadcastHistory: "Total Broadcast",
    columnNameTotalSentBroadcastHistory: "Terkirim",
    columnNameTotalReadBroadcastHistory: "Dibaca",
    columnNameTotalFailedBroadcastHistory: "Gagal",

    addViberBotAll: "Semua pelanggan yang terhubung ke Viber bot anda",
    addViberBotOneByOne: "Pilih satu per satu",
    addViberBotGroup: "Pilih kelompok pelanggan",

    chatroomMultipleContact: "{0} dan {1} kontak lainnya",
    chatroomMultipleContactButton: "Lihat kontak",
    chatroomMultipleContactDialogTitle: "Detail Kontak",

    chatroomLoadMedia: "Sedang memuat media...",
    menuAccessForbiddenText: "Akses menu tidak diberikan.",

    unConnectedViberChannelText:
      "Channel Viber belum terhubung, hubungkan sekarang",
    unConnectedViberChannelButtonText: "Hubungkan sekarang",

    chatHistoryColumnTagMoreCaption: "+{0} lainnya",
    chatHistoryDialogCsTagTitle: "Detail Tag",
    chatHistoryDialogCsTagCustomer: "Nama Customer",
    chatHistoryFilterTagDefault: "Semua tag",
    chatHistorySelectAllTag: "Pilih semua tag",
    chatHistorySearchTag: "Cari tag",
    chatHistorySelectedTag: "+{0} lainnya",
    chatHistorySelectedTagMobile: "+{0}",
    chatHistoryResetFilter: "Reset Filter",

    liveChatBannerDashboardTitle: "Ada yang baru nih, fitur Live Chat",
    liveChatBannerDashboardSubtitle:
      "Kini komunikasi dengan pelanggan jadi makin mudah dengan fitur Live Chat",
    liveChatBannerDashboardButton: "Coba sekarang",
    liveChatBannerChannelTitle: "Makin mudah dengan fitur Live Chat",
    liveChatBannerChannelSubtitle:
      "Yuk, install Live Chat di website kamu agar komunikasi dengan customer jadi lebih mudah.",
    settingStoreStatusLiveChatVerification:
      "Status akan berubah setelah kamu embed kode Live Chat di websitemu.",
    liveChatCreate: "Live Chat",
    liveChatWidgetBasicNameLabel: "Nama Widget",
    liveChatWidgetBasicDomainLabel: "Domain",
    liveChatWidgetBasicNamePlaceholder: "Contoh: Chat CS",
    liveChatWidgetBasicDomainPlaceholder:
      "Contoh: https://websiteku.com atau http://websiteku.com",
    liveChatWidgetConnectButton: "Hubungkan",
    liveChatWidgetDomainNotFound:
      "Domain yang kamu masukkan tidak ditemukan. Silakan gunakan domain lainnya, ya.",
    liveChatWidgetDomainInvalid:
      "Domain harus diawali dengan http:// atau https://",
    liveChatWidgetDomainNotFoundNotifError: "Domain tidak ditemukan.",
    liveChatWidgetDomainDuplicated:
      "Domain sudah kamu gunakan. Silakan gunakan domain lainnya, ya.",
    liveChatWidgetCreateSuccess:
      "Berhasil menambahkan domain, silakan atur widget kamu.",
    liveChatWidgetBasicSettingsTitle: "Pengaturan awal",
    liveChatWidgetBasicSettingsSubtitle:
      "Atur nama dan domain webiste untuk widget Live Chat kamu. ",
    liveChatWidgetInstallationSettingsTitle: "Install Live Chat",
    liveChatWidgetInstallationSettingsSubtitle:
      "Untuk memunculkan Live Chat di website, kamu perlu menghubungkan widget dan meng-embed kode yang ada di bawah.",
    liveChatWidgetInstallationToggleTitle: "Hubungkan widget",
    liveChatWidgetInstallationToggleDesc:
      "Hubungkan widget Live Chat di website kamu",
    liveChatWidgetInstallationCodeTitle: "Embed kode widget",
    liveChatWidgetInstallationCodeDesc:
      "Untuk meng-install widget, salin dan tempelkan kode di bawah sebelum tag </body> di website kamu.",
    liveChatWidgetDisplaySettingsTitle: "Tampilan widget",
    liveChatWidgetDisplaySettingsSubtitle:
      "Silakan atur tampilan widget agar sesuai dengan yang kamu inginkan.",
    liveChatWidgetContentSettingsTitle: "Konten widget",
    liveChatWidgetContentSettingsSubtitle:
      "Atur konten Live Chat kamu di sini.",
    liveChatWidgetContentAdminLabel: "Nama channel Live Chat",
    liveChatWidgetContentBanner:
      "Waktu online/offline Live Chat otomatis disesuaikan dengan {0} toko kamu.",
    liveChatWidgetContentBannerOperationalHours: "jam operasional",
    liveChatWidgetContentStatusTitle: "Edit konten widget",
    liveChatWidgetContentStatusSubtitle:
      "Edit isi konten widget Live Chat sesuai dengan yang kamu inginkan",
    liveChatWidgetContentOnlineOption: "Online",
    liveChatWidgetContentOfflineOption: "Offline",
    liveChatWidgetContentTitleLabel: "Judul",
    liveChatWidgetContentTitleNote: "Maksimal 30 karakter",
    liveChatWidgetContentDescriptionNote: "Maksimal 80 karakter",
    liveChatWidgetContentDescriptionLabel: "Pesan",
    liveChatWidgetDisplayLogoButton: "Upload logo",
    liveChatWidgetDisplayLogoTitle: "Logo widget",
    liveChatWidgetDisplayLogoSubtitle:
      "Upload logo widget Live Chat kamu di sini.",
    liveChatWidgetDisplayColorTitle: "Warna widget",
    liveChatWidgetDisplayColorSubtitle:
      "Atur warna widget agar sesuai dengan warna brand kamu.",
    liveChatWidgetDisplayLogoErrorSize: "Ukuran maks. 5MB",
    liveChatWidgetDisplayLogoErrorFormat: "Format harus .PNG, .JPG, atau .JPEG",
    liveChatWidgetDisplayLogoErrorResolution: "Resolusi maksimal 320px * 320px",
    liveChatWidgetDisplayLogoRules: `<ul>
      <li>Format file harus berupa .PNG, .JPG, atau .JPEG</li>
      <li>Resolusi maksimal 320px x 320px.</li>
      <li>Ukuran file maksimal 5MB</li>
    </ul>`,
    liveChatWidgetDisplayColorButton: "Select color",
    liveChatWidgetInstallConnectTitle: "Hubungkan widget",
    liveChatWidgetInstallConnectSubtitle:
      "Hubungkan widget Live Chat di website kamu",
    liveChatWidgetInstallConnectToggleOn: "Terhubung",
    liveChatWidgetInstallConnectToggleOff: "Tidak Terhubung",
    liveChatWidgetInstallCodeHighlightTitle: "Install kode widget",
    liveChatWidgetInstallCodeHighlightSubtitle:
      "Copy kode widget di </body> website kamu",
    liveChatWidgetInstallCodeHighlightCopy: "Salin",
    liveChatWidgetInstallCodeHighlightCopySuccess: "Berhasil menyalin widget",
    liveChatWidgetInstallEditButtonCancel: "Batalkan",
    liveChatWidgetInstallEditButtonSave: "Simpan",
    liveChatWidgetSaveErrorEmpty: "Wajib diisi.",
    liveChatWidgetSaveErrorLogoEmpty: "Wajib upload logo",
    liveChatWidgetSaveErrorEmptySnackbar:
      "Mohon lengkapi semua data sebelum menyimpan.",
    liveChatWidgetDomainDuplicateNotifError: "Domain sudah digunakan.",
    liveChatFailedGetChannelConfig:
      "Gagal menampilkan konfigurasi. Silahkan coba lagi.",
    liveChatWidgetSaveConfigSuccess: "Berhasil menyimpan konfigurasi",
    liveChatWidgetPreviewEmpty: "Preview widget Live Chat akan muncul di sini.",
    publishPageTitle: "Publish",
    publishPageSubtitle:
      "Kamu bisa ngepost konten di media sosial lewat fitur ini dengan mudah dan cepat",
    publishCreateTitle: "Buat Post",
    publishSelectAccountPlaceholder: "Pilih akun",
    publishCaptionPlaceHolder: "Tulis caption disini..",
    publishErrorMediaType: "Tipe media yang kamu masukkan tidak sesuai",
    publishErrorMediaS3ize: "Ukuran media tidak dapat lebih besar dari {0}MB",
    publishErrorMediaFormat:
      "Format {0} tidak sesuai. Silahkan input {0} dengan format: {1}",
    publishErrorVideoDuration:
      "Durasi minimum video adalah {0} detik dan maksimum {1} detik",
    publishButton: "Publish",
    publishEmptyAccount: "Belum ada social channel yang terhubung.",
    publishFailedPost: "Gagal mempublikasikan instagram post. {0}",
    publishSuccess: "Berhasil dipost",

    commentsPageTitle: "Comments",
    commentsPageSubtitle:
      "Kamu bisa balas semua komentar post atau reels di halaman ini.",
    commentsEmptyPostStateTitle: "Belum ada post yang dikomentari",
    commentsEmptyPostStateSubtitle:
      "Semua postingan kamu yang memiliki komentar akan muncul dan dapat kamu balas disini",
    commentsSeeComments: "lihat komentar",
    commentsHideComments: "sembunyikan komentar",
    commentsSeeReplies: "lihat jawaban",
    commentsHideReplies: "sembuyikan jawaban",
    commentsEmptyCommentStateTitle: "Tidak ada komentar yang ditampilkan",
    commentsEmptyCommentStateSubtitle:
      "klik lihat komentar untuk mengetahui komentar pada post yang dipilih",
    commentsWriteRepliesPlaceholder: "tulis komentar...",
    commentsSuccessReply: "Komentar berhasil dikirim",
    commentsFailedReply: "Komentar gagal dikirim",
    commentsSelectedPost: `Komentar pada <span class="text-body-14--bold">@{0}</span> post “{1}“`,
    commentsFailedGetData: "Gagal mendapatkan data {0} ({1})",

    createTemplateErrorWaMeLink:
      "Direct links ke whatsapp tidak dapat digunakan pada tombol.",
    createTemplateFormNewInformationAboutFillingTemplateContent:
      "Informasi seputar pengisian pesan template",

    templateContentInfoHeader: "Informasi seputar pengisian pesan template",
    templateContentInfoTitleVariableContact: "Baru, Variabel Data Kontak!",
    templateContentInfoContentVariableContact:
      "Bikin pesan broadcast kamu jadi lebih personal. ",
    templateContentInfoAttributeVariableContact: "Pelajari fitur.",
    templateContentInfoLinkVariableContact:
      "https://koalaapp.id/blog/430-tips-trik-122546-cara-untuk-menambahkan-data-variabel-kontak-dan-menambahkan-kontak-di-koala",

    templateContentInfoTitleVariable: "Info tentang variabel",
    templateContentInfoContentVariable:
      "Pelajari tentang variabel dan kegunaannya ",
    templateContentInfoAttributeVariable: "di halaman ini.",

    templateContentInfoTitleContentGuide: "Tips agar template lolos verifikasi",
    templateContentInfoContentContentGuide:
      "Aturan penulisan template agar lolos verifikasi bisa kamu ",
    templateContentInfoAttributeContentGuide: "cek di sini.",

    addVariableNewLabel: "BARU!",
    addMappingVariableTitle: "Tambah Variable Data Kontak",
    addSelfInputVariableTitle: "Tambah Variable Secara Manual",
    addMappingVariableHeader: "Variable Data Kontak",
    addMappingVariableChooseVariablePlaceholder: "Pilih Variabel",
    addMappingVariableInformationDialogContent:
      "Kamu bisa menambahkan variabel baru dengan menambahkan kolom pada data kontak.",
    addMappingVariableInformationDialogLink: "Pengaturan kolom.",
    addVariableOptionMappingVariable: "Variabel Data Kontak",
    addVariableOptionSelfInputVariable: "Tambah Manual",
    addVariableButtonText: "Tambah Variabel",
    addVariableSelfInputInfoDialogContent:
      "Bikin pesan broadcast kamu jadi lebih personal dengan Variabel Data Kontak.",
    addVariableSelfInputInfoDialogLink: "Pelajari Variabel Data Kontak",

    callCenterContactUs: "Hubungi Kami",
    callCenterIsNotActiveInformationText:
      "Yuk, tingkatkan layanan pelanggan Anda! Gunakan Call Center kami sekarang. Hubungi kami untuk informasi lebih lanjut",
    callCenterTitle: "Buat Panggilan",
    callCenterInfoDialog:
      "Nomor panggilan yang digunakan akan diacak dan berbeda setiap panggilan telepon",
    callCenterInputPhoneNumberTitle: "Masukkan No. HP",
    callCenterInputPhoneNumberSubtitle:
      "Masukkan no. HP penerima secara manual, isi angka saja.",
    callCenterRecentCallTitle: "Panggilan Terakhir",
    callCenterRecentCallSubtitle: "Daftar panggilan terakhir",
    callCenterFrom: "dari",
    callCenterEmptyListContent: "Kamu belum memiliki riwayat panggilan",
    callCenterEmptyListSubcontent:
      "Yuk, mulai telepon dan terhubung dengan pelangganmu",
    callCenterLeaveSiteCostumeMessage:
      "Anda yakin ingin meninggalkan halaman ini? Panggilan akan terputus.",

    callCenterHistoryTitle: "Riwayat Call Center",
    callCenterHistorySubtitle:
      "Kamu bisa melihat riwayat call yang sudah dilakukan",
    downloadHistoryCallDialogTitle: "Download Riwayat Panggilan",
    searchContactCallCenterPlaceholder: "Cari kontak",
    callHistorySearchAgent: "Pilih Agent",
    callHistoryPlaceholderFilterStatus: "Pilih Status",
    callHistoryColumnCustomerContact: "Kontak Pelanggan",
    callHistoryColumnCallStatus: "Status Panggilan",
    callHistoryStatusEstabilished: "Tersambung",
    callHistoryStatusNotConnected: "Tidak Tersambung",
    callHistoryColumnAgent: "Agent",
    callHistoryColumnTime: "Waktu Panggilan",
    callHistoryColumnDuration: "Durasi Panggilan",
    callHistoryColumAction: "Action",
    callHistoryActionNotConnectedTooltip:
      "Panggilan tidak tersambung. Tidak ada aksi.",
    callRecordingDialogTitle: "Rekaman Panggilan",
    callRecordingDialogDownloadCallRecording: "Download rekaman panggilan",
    callCenterReceiverInvalidErrorMessage: "Nomor tidak valid",
    callSessionTrying: "Menghubungkan Panggilan",
    callSessionRinging: "Dering Panggilan",
    callSessionConnected: "Panggilan Tersambung",
    callSessionHold: "Panggilan Ditunda",
    callSessionBusy: "Panggilan Sibuk",
    callSessionNoAnswer: "Panggilan Tidak Terjawab",
    callSessionRejected: "Panggilan Ditolak",
    callSessionEnded: "Panggilan Berakhir",
    callSessionCancelled: "Panggilan Dibatalkan",
    callSessionFailed: "Panggilan Gagal",

    callButtonRedial: "Panggil Ulang",
    callButtonClose: "Keluar",
    callButtonMute: "Bisukan",
    callButtonUnmute: "Suarakan",
    callButtonHold: "Tahan",
    callButtonUnhold: "Unhold",
    callButtonSetting: "Pengaturan",
    callButtonEndCall: "Matikan",
    callButtonAnswer: "Jawab Panggilan",
    callButtonReject: "Tolak Panggilan",

    callReconnectionTitle: "Gagal Menghubungkan Server PBX",
    callReconnectionRetry: "Ulangi",
    callReconnectionCancel: "Batal",
    callReconnectionConfirm: "Apakah Anda ingin menyambungkan kembali?",

    callInbound: "Panggilan Masuk",
    customerTitle: "Pelanggan",
    chatroomContactDetailStatusLabel: "Status",
    chatroomContactDetailFormLabel: "Form",
    chatroomContactDetailFormCCLabel: "CCs",
    chatroomContactDetailFormChooseLabel: "Tipe form",
    chatroomContactDetailFormChooseNoDataLabel: "Tipe form tidak tersedia",
    chatroomContactDetailActivityLogLabel: "Log aktivitas",
    chatroomContactDetailFormSaveLabel: "Simpan",
    required: "Wajib diisi",

    chatRoomTicketLogDialogTitle: "Log Aktivitas",
    chatRoomTicketLogDialogLoadingText: "Memuat Log Aktivitas",

    chatRoomAssignmentDialogTitle: "Tugaskan CS",
    chatRoomAssignmentCsAgentGroupsLoading: "Memuat Data...",
    chatRoomAssignmentDialogConfirmation: "Konfirmasi",
    chatRoomAssignmentDialogServingChats: "{0} percakapan sedang ditangani",
    chatRoomAssignmentDialogAssignSuccess: "Pesan berhasil diteruskan",
    chatRoomAssignmentDialogAssignFailed: "Gagal meneruskan pesan",

    ticketStatusSelected: "Status ",
    ticketStatusApplyButton: "Terapkan",
    ticketStatusSucceedSnackbar: "Berhasil mengubah status menjadi {0}.",
    ticketStatusFailedSnackbar: "Gagal mengubah status.",
    ticketStatusChatListTooltip: "Status: {0}",
    ticketStatusFilterTitle: "Status",
    respondedStatusFilterTitle: "Tampilan chat",

    confirmButton: "Konfirmasi",

    name: "Nama",
    email: "Email",
    role: "Posisi",
    emptyData: "Data tidak tersedia",
    wabaConversionTitle: "Conversion API",
    wabaConversionAPIDesc:
      "Hubungkan ke Conversion API untuk membuat koneksi langsung antara data pemasaran Anda dan sistem-sistem yang membantu mengoptimalkan penargetan iklan, mengurangi biaya per hasil, dan mengukur hasil di seluruh teknologi Meta.",
    wabaConversionAPISeeMore: "Lihat lebih",
    wabaConversionAPIConnectButton: "Hubungkan",
    pleaseWait: "Sedang mengambil data, harap tunggu...",
    remove: "Hapus",
    removeAll: "Hapus semua",
    ticketFormSaveSuccess: "Berhasil menyimpan info form",
    ticketFormSaveFailed:
      "Gagal menyimpan info form. Pastikan form sudah diisi dengan benar.",
    formChat: "Form chat",
    invalid: "tidak valid",

    chatHistoryFailedFetchDataTitle: "Gagal menampilkan data",
    chatHistoryFailedFetchDataDesc:
      "Ada kendala saat menghubungkan email. Silakan coba lagi",
    chatHistoryFailedFetchDataButton: "Hubungkan ulang",
    chatHistoryOnChatRoomDateFilter: "Date",
    chatHistoryOnChatRoomRemarksFilter: "Keterangan",
    chatHistoryOnChatRoomSubjectFilter: "Subjek",
    chatHistorySearchLabelFilter: "Search here",
    chatHistoryOnChatRoomFilterLabel: "Search subject chat",
    cancel: "Batalkan",

    emailSettingsTitle: "Pengaturan akun email",
    emailSettingsSubtitle:
      "Atur display name, footer, dan header untuk email kamu.",
    emailSettingsFailedTitle: "Gagal menghubungkan akun email.",
    emailSettingsFailedSubtitle:
      "Silakan cek internet anda dan ulangi kembali lagi",
    emailSettingsFailedButton: "Hubungkan ulang",
    emailSettingsPreviewDisplayName: "Preview display name",
    emailSettingsDisplayName: "Display name",
    emailSettingsEmailAddress: "<alamatemail@kokatto.com>",
    emailSettingsEmailAddressSubtext: "to me, alamat.email",
    emailSettingsDisplayNameLabel: "Display name pengirim email",
    emailSettingsSuccessConnect: "Berhasil menghubungkan akun {0}.",
    emailSettingsFailedConnect:
      "Gagal menambahkan akun email. Silakan coba lagi.",
    emailSettingsFailedUploadImage:
      "Gagal dalam mengupload gambar, silahkan coba lagi.",
    emailSettingsSaveButton: "Simpan",
    emailSettingsSuccessSave: "Berhasil menyimpan pengaturan {0}.",
    emailSettingsFailedSave:
      "Gagal menyimpan pengaturan {0}. Silakan coba lagi.",
    emailSettingsHeader: "Header",
    emailSettingsFooter: "Footer",
    coachmarkConnectLiveChatAndEmail:
      "Klik di sini untuk tambah widget Live Chat atau akun email",
    coachmarkConnectEmail:
      "Klik di sini untuk menambahkan email yang mau dihubungkan",
    emailSettingsDuplicateConnect:
      "Akun sudah terhubung di tempat lain. Silakan hubungi CS.",
    emailSubjectChatroomHeader: "Subject: {0}",

    ticketViewsList: "Views",

    dataLoadFailed: "Data gagal diambil. Silakan coba lagi",

    ticketViewLoading: "Memuat Data",
    ticketViewNoDataTitle: "Belum ada data",
    ticketViewNoDataSubtitle:
      "Jika ada chat dari pelanggan untuk ditangani, info chat akan muncul di sini.",
    ticketViewErrorStateTitle: "Gagal menampilkan data",
    ticketViewErrorStateSubtitle:
      "Ada kendala saat mengambil data. Silakan coba lagi.",
    ticketViewErrorStateButtonText: "Muat Ulang",

    labelFilterTypeBroadcastHistory: "Tipe Broadcast",
    broadcastTypeFilterNormalBroadcast: "Broadcast",
    broadcastTypeFilterFollowUpNewMessage: "Follow Up & Pesan Baru",
    broadcastTypeFilterOtp: "OTP",
    columnNameStatusPendingBroadcastHistory: "Menunggu persetujuan",
    columnNameStatusCancelledBroadcastHistory: "Dibatalkan",
    columnNameStatusRejectedBroadcastHistory: "Ditolak",
    columnNameStatusExpiredBroadcastHistory: "Kadaluwarsa",

    ticketViewPageShowingText: "Menampilkan {0}-{1} dari {2}",
    ticketViewManualTicketCreationButtonText: "Buat chat baru",

    ticketCsHandledByFilter: "Handled by",
    ticketCsNameFilterDisplay: `CS {0} ({1})`,
    ticketResetFilter: "Reset",
    ticketFilterButton: "Filter",
    all: "Semua",
    tabTitleAdvancedBroadcast: "Advanced Broadcast",
    advancedBroadcastList: "Daftar Advanced Broadcast",
    advancedBroadcastButtonCreate: "Tambah",
    tabTodaysBroadcastSchedule: "Jadwal broadcast hari ini",
    tabAllBroadcast: "Semua broadcast",
    searchBroadcastPlaceholder: "Cari nama broadcast",
    noBroadcastTodayTitle: "Belum ada jadwal broadcast hari ini",
    noBroadcastTodayContent:
      "Jika ada advanced broadcast yang akan dikirim hari ini, detailnya akan muncul di sini.",
    noBroadcastDataTitle: "Belum ada jadwal broadcast",
    noBroadcastDataContent:
      "Jika ada advanced broadcast, detailnya akan muncul di sini.",
    broadcastTableBroadcastName: "Nama Broadcast",
    broadcastTableBroadcastStatus: "Status",
    broadcastTableBroadcastTime: "Waktu Broadcast",
    broadcastTableBroadcastTotalRecipient: "Jumlah Penerima",
    broadcastTableBroadcastPrice: "Biaya Broadcast",
    broadcastTableBroadcastTemplateMessage: "Isi Template",
    broadcastTableBroadcastAction: "Action",
    broadcastTableBroadcastLastUpdated: "Update Terakhir",

    createAdvancedBroadcastTitle: "Advanced Broadcast",
    createBroadcastFormName: "Nama broadcast",
    createBroadcastFormNamePlaceholder: "Isi nama broadcast",
    createBroadcastFormNameError: "Tidak bisa lebih dari 30 karakter",
    createBroadcastFormTemplateVariableIsNotEligible:
      "Template harus mengandung variabel dari info kontak pelanggan",
    createAdvancedBroadcastInfoBannerText:
      "Advanced broadcast hanya bisa pakai template statis atau dengan variabel dari info kontak pelanggan.",
    createTemplatePreviewSubtitle:
      "Menampilkan preview chat yang dikirim ke penerima",
    createAdvancedBroadcastStepRuleTitle:
      "Kriteria penerima broadcast (opsional)",
    createAdvancedBroadcastStepRuleSubTitle:
      "Tambahkan ketentuan siapa saja dari kontak pelanggan yang akan menerima broadcast",
    createAdvancedBroadcastStepRuleAddRule: "Tambah ketentuan",
    createAdvancedBroadcastStepRuleSelectVariable: "Pilih variabel",
    createAdvancedBroadcastStepRuleSelectRule: "Pilih ketentuan",
    createAdvancedBroadcastStepRuleInputDetail: "Isi detail",
    createAdvancedBroadcastStepRuleSelectDate: "Pilih tanggal",
    autoMsgOprIsContains: "Mengandung",
    autoMsgOprIsEqual: "Sama dengan",
    autoMsgOprIsToday: "Hari ini",
    autoMsgOprIsDateBefore: "Sebelum hari ini",
    autoMsgOprIsDateAfter: "Setelah hari ini",
    autoMsgOprIsDateBetween: "Rentang waktu",
    autoMsgOprIsScheduleDateBefore: "Sebelum tanggal broadcast",
    autoMsgOprIsScheduleDateAfter: "Setelah tanggal broadcast",
    autoMsgOprIsNotEmpty: "Tidak kosong",
    createAdvancedBroadcastEmptyFieldErrorMessage: "Variabel harus diisi",
    createAdvancedBroadcastEmptyRuleErrorMessage: "Ketentuan harus diisi",
    createAdvancedBroadcastEmptyValueErrorMessage: "Detail harus diisi",
    createAdvancedBroadcastDuplicateCriteriaErrorMessage:
      "Variabel dan ketentuan sudah ada",
    createAdvancedBroadcastStepScheduleTitle: "Jadwal broadcast",
    createAdvancedBroadcastStepScheduleSubTitle:
      "Atur kapan broadcast akan dikirim ke masing-masing penerima",
    createAdvancedBroadcastStepScheduleSelectFrequencyPlaceholder:
      "Pilih frekuensi pengiriman",
    createAdvancedBroadcastStepScheduleSelectTime: "Pilih jam",
    createAdvancedBroadcastButtonSend: "Kirim sesuai jadwal",
    createAdvancedBroadcastButtonSaveAsDraft: "Simpan sebagai draft",
    createAdvancedBroadcastFrequencyListOneTime: "Sekali kirim",
    createAdvancedBroadcastFrequencyListInterval: "Interval",
    createAdvancedBroadcastStepScheduleSelectInterval: "Pilih tipe interval",
    createAdvancedBroadcastStepScheduleSelectDay: "Pilih hari",
    createAdvancedBroadcastStepScheduleSelectDate: "Pilih tanggal",
    createAdvancedBroadcastIntervalListDaily: "Harian",
    createAdvancedBroadcastIntervalListWeekly: "Mingguan",
    createAdvancedBroadcastIntervalListMonthly: "Bulanan",
    createAdvancedBroadcastStepScheduleSelectFrequencyErrorMessage:
      "Frekuensi harus diisi",
    createAdvancedBroadcastStepScheduleSelectIntervalErrorMessage:
      "Tipe interval harus diisi",
    createAdvancedBroadcastStepScheduleSelectDayErrorMessage:
      "Hari harus diisi",
    createAdvancedBroadcastStepScheduleSelectDateErrorMessage:
      "Tanggal harus diisi",
    createAdvancedBroadcastStepScheduleInfoBannerText:
      "Biaya broadcast bisa berbeda-beda tergantung jumlah kontak tiap pengiriman. Detail biaya akan muncul di menu Semua Broadcast.",
    createAdvancedBroadcastSendSuccess:
      "Berhasil membuat jadwal advanced broadcast ke {0} kontak.",
    createAdvancedBroadcastSaveSuccess:
      "Berhasil menyimpan draft broadcast. Untuk mengirim, silakan aktifkan broadcast.",
    createAdvancedBroadcasDuplicateErrorMessage:
      "Nama broadcast sudah pernah terdaftar. Silakan ganti nama lain.",
    createAdvancedBroadcastGeneralErrorMessage:
      "Gagal menambahkan advanced broadcast. Silakan coba lagi.",
    advancedBroadcastChatCreditEstimationInfoTitle:
      "Estimasi total biaya broadcast hari ini: {0} Chat Credits",
    advancedBroadcastChatCreditEstimationInfoText:
      "Semua info di halaman ini update otomatis tiap jam 00:00.",
    advancedBroadcastStatusNonActive: "Nonaktif",
    advancedBroadcastStatusWaiting: "Menunggu dikirim",
    advancedBroadcastStatusSent: "Terkirim",
    advancedBroadcastStatusFailed: "Gagal dikirim",
    advancedBroadcastStatusChatCreditMinus: "Saldo kurang",
    advancedBroadcastConfirmationUpdateToActiveDialogTitle:
      "Aktifkan broadcast",
    advancedBroadcastConfirmationUpdateToActiveDialogDescription:
      "Broadcast akan diaktifkan sesuai jadwal pengiriman.",
    advancedBroadcastConfirmationUpdateToNonActiveDialogTitle:
      "Nonaktifkan broadcast",
    advancedBroadcastConfirmationUpdateToNonActiveDialogDescription:
      "Broadcast akan dinonaktifkan. Jadwal pengiriman broadcast akan dibatalkan.",
    advancedBroadcastConfirmationUpdateToNonActiveDialogYes: "Ya, nonaktifkan",
    advancedBroadcastConfirmationUpdateToActiveDialogYes: "Ya, aktifkan",
    advancedBroadcastConfirmationUpdateDialogCancel: "Batalkan",
    advancedBroadcastUpdateToActiveSuccessSnackbar:
      "Berhasil mengaktifkan broadcast. Broadcast akan dikirim sesuai jadwal.",
    advancedBroadcastUpdateToActiveErrorSnackbar:
      "Gagal mengaktifkan broadcast. Silakan coba lagi.",
    advancedBroadcastUpdateToNonActiveSuccessSnackbar:
      "Berhasil menonaktifkan broadcast. Jadwal pengiriman broadcast akan dibatalkan.",
    advancedBroadcastDownloadFailedFileIconTooltip:
      "Download kontak untuk kirim ulang broadcast secara manual",
    advancedBroadcastNameFailedIconTooltip:
      "Kamu bisa coba kirim ulang manual dari menu Broadcast",
    advancedBroadcastNameChatCreditMinusIconTooltip:
      "Silakan top up saldo Chat Credits agar bisa mengirim broadcast",
    advancedBroadcastConfirmationDeleteDialogTitle: "Hapus broadcast",
    advancedBroadcastConfirmationDeleteDialogDescription:
      "Jika ada jadwal yang tersambung dengan broadcast ini, maka jadwal akan dihapus.",
    advancedBroadcastConfirmationDeleteDialogYes: "Ya, hapus",
    advancedBroadcastSuccessDeleteSnackbar:
      "Berhasil menghapus jadwal advanced broadcast.",
    advancedBroadcastFailedDeleteSnackbar:
      "Gagal menghapus jadwal advanced broadcast. Silakan coba lagi.",
    advancedBroadcastFailedGetDetail:
      "Gagal mendapatkan detail broadcast. Silakan coba lagi.",
    updateAdvancedBroadcastGeneralErrorMessage:
      "Gagal update advanced broadcast. Silakan coba lagi.",
    createContactConfirmationDialogTitle:
      "Update perubahan di advanced broadcast",
    createContactConfirmationDialogDescription:
      "Perubahan di kontak pelanggan akan berpengaruh ke detail advanced broadcast. Agar detail bisa diperbarui, silakan update dari halaman advanced broadcast.",
    createContactConfirmationDialogUnderstand: "Ok, mengerti",
    advancedBroadcastSyncContactBannerText:
      "Ada perubahan info kontak pelanggan, silakan update untuk memperbarui detail broadcast",
    advancedBroadcastSyncContactBannerRefreshButton: "Update sekarang",
    advancedBroadcastSuccessSyncContactSnackbar:
      "Berhasil update kontak pelanggan untuk broadcast.",
    advancedBroadcastFailedSyncContactSnackbar:
      "Gagal update kontak pelanggan untuk broadcast. Silakan coba lagi.",
    advancedFailedGetBroadcastListSnackbar:
      "Gagal mendapatkan daftar advanced broadcast. Silakan coba lagi.",
    updateBroadcastConfirmationDialogDescription:
      "Perubahan di advanced broadcast akan berpengaruh pada jadwal pengiriman. Tetap lanjutkan?",
    updateBroadcastConfirmationDialogContinue: "Ya, lanjutkan",
    advancedBroadcastTotalEligibleContactText:
      "{0} kontak memenuhi ketentuan untuk menerima broadcast",
    advancedBroadcastViewEligibleContactBtntext: "Lihat Data",
    advancedBroadcastViewEligibleContactDialogTitle: "Data Penerima broadcast",
    advancedBroadcastNoneEligibleContactText:
      "Tidak ada kontak yang memenuhi kriteria, cek lagi ketentuan yang kamu buat",
    advancedBroadcastTotalEligibleContactErrorText:
      "Gagal mendapatkan jumlah kontak yang memenuhi ketentuan",

    noActivityLog: "Tidak ada Activity Log",
    chatroomUnassignedHasNotExpiryDateInstruction:
      "Tugaskan ke CS yang sedang online untuk membalas.",
    settingAdminSelectRoleSupervisorAndCustomerService: "Supervisor 2",
    settingAdminSelectRoleSupervisorAndCustomerServiceDescription:
      "Dapat melihat, membalas, menugaskan, dan ditugaskan ke semua chat.",
    settingAdminSelectRoleCustomerServicePlayMode: "Customer Service 1",
    settingAdminSelectRoleCustomerServicePlayModeDescription:
      "Hanya dapat melihat dan membalas chat yang  ditugaskan secara berurutan oleh sistem (Auto Assign).",

    ticketViewBackCaption: "Kembali ke halaman utama Chat",

    advancedBroadcastFailedDownloadHistorySnackbar:
      "Gagal mendownload riwayat advanced broadcast",

    templateHeaderTemplateType: "Jenis Template",
    templateTypeCRM: "Variabel - Hanya dari info Kontak",
    templateTypeManual: "Variabel - Manual input",
    templateTypeStatic: "Statis - Tanpa variabel",

    sendBroadcastConfirmationDialogTitle: "Lanjut kirim advanced broadcast",
    sendBroadcastConfirmationDialogDescription:
      "Broadcast akan dikirim dan diaktifkan sesuai jadwal pengiriman.",
    saveBroadcastConfirmationDialogTitle: "Lanjut buat advanced broadcast",
    saveBroadcastConfirmationDialogDescription:
      "Broadcast akan dibuat dan tidak aktif. Silahkan mengaktifkan untuk mengirimkan advanced broadcast.",

    templateListFailedSnackbar:
      "Gagal mendapatkan daftar template. Silakan coba lagi.",
    chatroomErrorServiceUnavailable:
      "WhatsApp service is temporarily unavailable. Please try again soon.",
    chatroomErrorRateLimitHit:
      "High traffic when sending message to WhatsApp, please try again in a few moments ({0})",
    chatroomErrorConnectionTimeOut:
      "Connection to Meta timed out, please try again later",
    activityLogSendEmailTitle: "Send Email",

    advancedBroadcastExpiredTooltip:
      "Jadwal broadcast sudah lewat. Tidak dapat diubah.",
    advancedBroadcastOneTimeTooltip: "Broadcast sekali kirim tidak bisa diubah",
    // keep this on the end to overwrite strings from brand specific strings
    ...brandConstants.CUSTOM_WORDINGS.ID,
  },
};
