import { TicketTimeTrackingRequest } from "@/serviceClients/requests/KokattoTicketTimeTrackingRequest";

export interface State {
  // TODO remove these old states when after migration to the new time-tracking approach
  startTime: Date | null;
  endTime: Date | null;

  /**
   * TODO: create more state like this to time-track multiple kind of activities
   * state signature: <activity>: { startTime, endTime }
   */
  manualTicketCreation: {
    startTime: Date | null;
    endTime: Date | null;
  };
}

export enum TimeTrackingEventEnum {
  ManualTicketCreation = "manualTicketCreation",
  UpdateTicket = "updateTicket",
}

export interface SubmitDurationToBackendActionPayload {
  request: TicketTimeTrackingRequest;
  shouldRestart: boolean;
}
