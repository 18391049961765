const id = {
  emailCreatePreviewDisplayName: "Sumting Wong",
  emailCreatePreviewName: "alamatemail",
  emailCreatePreviewNameLabel: "ADDRESS",
  emailCreatePreviewDomain: "@kokatto.com",
  emailCreatePreviewDomainLabel: "DOMAIN",
  emailCreateTitle: "Pengaturan awal",
  emailCreateSubtitle:
    "Buat email workspace yang baru untuk dihubungkan ke {0}. Untuk kustomisasi domain, hubungi {1} atau perwakilan tim sales {0}.",
  emailCreateAddressPlaceholder:
    "Email Address. Contoh: info / support / marketing. ",
  emailCreateDomainLabel: "Email Domain",
  emailCreateAddressRule:
    "Maks Karakter: 40. Hanya bisa menggunakan karakter “-”, “_” & “.”",
  emailCreateNextButton: "Lanjut",
  emailCreateDuplicateConnect:
    "Email sudah digunakan. Silakan gunakan Email lainnya, ya.",
  emailDeleteDialogTitle: "Hapus email terhubung?",
  emailDeleteDialogDescription:
    'Menghapus  <span class="text-body-16--bold">{0}</span>  dari channel akan juga menonaktifkan alamat email tersebut dan customer tidak dapat mengirim pesan ke alamat email yang terhapus.',
};
const en = {
  emailCreatePreviewDisplayName: "Sumting Wong",
  emailCreatePreviewName: "emailaddress",
  emailCreatePreviewNameLabel: "ADDRESS",
  emailCreatePreviewDomain: "@kokatto.com",
  emailCreatePreviewDomainLabel: "DOMAIN",
  emailCreateTitle: "Initial Setup",
  emailCreateSubtitle:
    "Create a new workspace email to be connected to {0}. For domain customization, contact {1} or the sales team representative {0}.",
  emailCreateAddressPlaceholder:
    "Email Address. Example: info / support / marketing.",
  emailCreateDomainLabel: "Email Domain",
  emailCreateAddressRule:
    "Max Characters: 40. Only the characters “-”, “_” & “.” are allowed.",
  emailCreateNextButton: "Next",
  emailCreateDuplicateConnect:
    "Email is already in use. Please use another email.",
  emailDeleteDialogTitle: "Delete connected email?",
  emailDeleteDialogDescription:
    'Removing { <span class="text-body-16--bold">{0}</span>  from the channel will also deactivate that email address, and customers will not be able to send messages to the deleted email address.',
};

export default { id, en };
