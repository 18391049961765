const en = {
  preChatFormConfigPanelHeading: "Form Settings",
  preChatFormConfigPanelSubHeading:
    "Set up form for customers to fill before using Live Chat",
  preChatFormConfigFieldLabel: "Field label",
  preChatFormConfigFieldPlaceholder: "Example: Enter your name",
  preChatFormConfigCustomizedDataLabel: "Customized data option",
  preChatFormConfigCustomizedDataPlaceholder: "Choose an option",
  preChatFormConfigConsentBoxLabel: "Consent box text",
  preChatFormConfigConsentBoxPlaceholder:
    "Masukkan info atau detail persetujuan ketika kotak dicentang",
  preChatFormConfigValidationCustomizedDataAlreadySelected:
    "Please choose another option",
  preChatFormConfigCTAAddField: "Field",
  preChatFormConfigCTAAddConsentBox: "Consent box",
  preChatFormConfigItemLimitNotReached:
    "You can add max. 3 additional components",
  preChatFormConfigItemLimitReached:
    "You’ve reached limit of 3 additional components",

  preChatFormConfigFieldLabelIsRequired: "Label cannot be empty",
  preChatFormConfigFieldCustomizedDataIsRequired:
    "Customized data cannot be empty",
  preChatFormConfigConsentBoxLabelIsRequired:
    "Consent box label cannot be empty",
};

const id = {
  preChatFormConfigPanelHeading: "Pengaturan Form",
  preChatFormConfigPanelSubHeading:
    "Atur form untuk pelanggan isi sebelum memulai Live Chat",
  preChatFormConfigFieldLabel: "Label kolom isian",
  preChatFormConfigFieldPlaceholder: "Contoh: Masukkan nama",
  preChatFormConfigCustomizedDataLabel: "Pilihan customized data",
  preChatFormConfigCustomizedDataPlaceholder: "Pilih satu opsi",
  preChatFormConfigConsentBoxLabel: "Teks kotak persetujuan",
  preChatFormConfigConsentBoxPlaceholder:
    "Masukkan info atau detail persetujuan ketika kotak dicentang",
  preChatFormConfigValidationCustomizedDataAlreadySelected:
    "Silakan pilih opsi lainnya",
  preChatFormConfigCTAAddField: "Kolom isian",
  preChatFormConfigCTAAddConsentBox: "Kotak persetujuan",
  preChatFormConfigItemLimitNotReached:
    "Kamu bisa tambah maks. 3 komponen form",
  preChatFormConfigItemLimitReached: "Sudah mencapai batas 3 komponen tambahan",

  preChatFormConfigFieldLabelIsRequired: "Label harus diisi",
  preChatFormConfigFieldCustomizedDataIsRequired: "Customized data harus diisi",
  preChatFormConfigConsentBoxLabelIsRequired:
    "Label kotak persetujuan wajib diisi",
};

export default { en, id };
