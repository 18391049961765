import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RefreshableRequestHelper } from "@/helpers/RefreshableRequestHelper";
import { KokattoTicketTimeTrackingResponse } from "@/serviceClients/responses/KokattoTicketTimeTrackingResponse";
import { isKokattoSuccessResponse } from "@/serviceClients/responses/KokattoSuccessResponse";
import { KokattoTicketServiceClient } from "@/serviceClients/KokattoTicketServiceClient";
import {
  State,
  TimeTrackingEventEnum,
  SubmitDurationToBackendActionPayload,
} from "./types";

const DEFAULT_STATE: State = {
  // TODO remove these old states when after migration to the new time-tracking approach
  startTime: null as Date | null,
  endTime: null as Date | null,

  /**
   * TODO: create more state like this to time-track multiple kind of activities
   * state signature: <TimeTrackingEventEnum>: { startTime, endTime }
   */
  manualTicketCreation: {
    startTime: null,
    endTime: null,
  },
};

const state: State = { ...DEFAULT_STATE };

const getters: GetterTree<State, any> = {
  getDuration:
    (state) =>
    (event?: TimeTrackingEventEnum): number => {
      const { manualTicketCreation } = state;
      if (event === TimeTrackingEventEnum.ManualTicketCreation) {
        if (manualTicketCreation.startTime && manualTicketCreation.endTime) {
          return Math.round(
            (manualTicketCreation.endTime.getTime() -
              manualTicketCreation.startTime.getTime()) /
              1000
          );
        }
        return 0;
      }

      // handles the existing behavior
      if (state.startTime && state.endTime) {
        return Math.round(
          (state.endTime.getTime() - state.startTime.getTime()) / 1000
        );
      }
      return 0;
    },
};

const mutations: MutationTree<State> = {
  startTracking(state, event?: TimeTrackingEventEnum) {
    if (event === TimeTrackingEventEnum.ManualTicketCreation) {
      state.manualTicketCreation = {
        ...state.manualTicketCreation,
        startTime: new Date(),
      };
      return;
    }
    state.startTime = new Date();
  },
  stopTracking(state, event?: TimeTrackingEventEnum) {
    if (event === TimeTrackingEventEnum.ManualTicketCreation) {
      state.manualTicketCreation = {
        ...state.manualTicketCreation,
        endTime: new Date(),
      };
      return;
    }
    state.endTime = new Date();
  },
  resetTracking(state, event?: TimeTrackingEventEnum) {
    if (event === TimeTrackingEventEnum.ManualTicketCreation) {
      state.manualTicketCreation = {
        startTime: null,
        endTime: null,
      };
      return;
    }
    state.startTime = null;
    state.endTime = null;
  },
  resetAllTracking(state) {
    state = { ...DEFAULT_STATE };
    return state;
  },
};

const actions: ActionTree<State, any> = {
  startTracking({ commit }, payload: TimeTrackingEventEnum) {
    commit("startTracking", payload);
  },
  stopTracking({ commit }, payload: TimeTrackingEventEnum) {
    commit("stopTracking", payload);
  },
  resetTracking({ commit }, payload: TimeTrackingEventEnum) {
    commit("resetTracking", payload);
  },
  resetAllTracking({ commit }) {
    commit("resetAllTracking");
  },

  async submitDurationToBackend(
    { rootGetters, getters, commit },
    payload: SubmitDurationToBackendActionPayload
  ) {
    const eventName = payload.request.event;
    commit("stopTracking", eventName);
    const duration = getters.getDuration(eventName);

    try {
      const response =
        await RefreshableRequestHelper.requestKokatto<KokattoTicketTimeTrackingResponse>(
          () => {
            const serviceClient = new KokattoTicketServiceClient({
              token: rootGetters.getKokattoTokenAccess,
            });
            return serviceClient.submitTicketDurationTracking({
              ...payload.request,
              durationInSecond: duration,
            });
          }
        );

      if (isKokattoSuccessResponse(response)) {
        console.log("Duration submitted successfully");
      }

      commit("resetTracking", eventName);

      if (payload.shouldRestart) commit("startTracking", eventName);
    } catch (error) {
      console.error("Error submitting duration:", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
