import axios, { AxiosRequestConfig } from "axios";
import { KokattoServiceClient } from "./KokattoServiceClient";
import { KokattoErrorResponse } from "./responses/KokattoErrorResponse";
import {
  ChatRoomUpdateTicketRequest,
  KokattoGetTicketFormRequest,
} from "./requests/KokattoGetTicketFormRequest";
import { KokattoGetTicketFormResponse } from "./responses/KokattoGetTicketFormResponse";
import { KokattoGetTicketFieldRequest } from "./requests/KokattoGetTicketFieldRequest";
import { KokattoGetTicketFieldResponse } from "./responses/KokattoGetTicketFieldResponse";
import { KokattoGetTicketAuditLogRequest } from "./requests/KokattoGetTicketAuditLogRequest";
import { KokattoGetTicketAuditLogResponse } from "./responses/KokattoGetTicketAuditLogResponse";
import { KokattoGetCsAgentGroupsRequest } from "./requests/KokattoGetCsAgentGroupsRequest";
import { KokattoGetCsAgentGroupsResponse } from "./responses/KokattoGetCsAgentGroupsResponse";
import { KokattoGetGroupMembershipRequest } from "./requests/KokattoGetGroupMembershipRequest";
import { KokattoGetGroupMembershipResponse } from "./responses/KokattoGetGroupMembershipResponse";
import {
  CsAgentGroup,
  CsAgentGroupMembership,
} from "@/models/CsAgentManagementModel";
import { KokattoGetCsAgentGroupMembershipsResponse } from "./responses/KokattoGetCsAgentGroupMembershipsResponse";
import { KokattoGetCsAgentGroupMembershipsRequest } from "./requests/KokattoGetCsAgentGroupMembershipsRequest";
import { KokattoPaginatedRequest } from "./requests/KokattoPaginatedRequest";
import { KokattoGetTicketViewListResponse } from "./responses/KokattoGetTicketViewListResponse";
import { KokattoGetTicketListRequest } from "./requests/KokattoGetTicketListRequest";
import { KokattoGetTicketListResponse } from "./responses/KokattoGetTicketListResponse";
import { KokattoGetTicketChatListRequest } from "./requests/KokattoGetTicketChatListRequest";
import { KokattoGetTicketFieldsRequest } from "./requests/KokattoGetTicketFieldsRequest";
import { KokattoGetTicketFieldsResponse } from "./responses/KokattoGetTicketFieldsResponse";
import { KokattoTicketTimeTrackingRequest } from "./requests/KokattoTicketTimeTrackingRequest";
import { KokattoGetTicketPlaylistResponse } from "./responses/KokattoGetTicketPlaylistResponse";
import { KokattoGetTicketPlaylistRequest } from "./requests/KokattoGetTicketPlaylistRequest";
import { KokattoGetCsAgentGroupByIdRequest } from "./requests/KokattoGetCsAgentGroupByIdRequest";
import { KokattoGetCsAgentGroupByIdResponse } from "./responses/KokattoGetCsAgentGroupByIdResponse";
import { KokattoGetTicketFieldByIdRequest } from "./requests/KokattoGetTicketFieldByIdRequest";
import { KokattoGetTicketFieldByIdResponse } from "./responses/KokattoGetTicketFieldByIdResponse";

export class KokattoTicketServiceClient extends KokattoServiceClient {
  constructor(
    args?:
      | string
      | {
          baseUrl?: string;
          token?: string;
        }
  ) {
    super(args);
    if (!this.baseUrl) {
      this.baseUrl = process.env.VUE_APP_BASEURL_TICKET_SERVICE ?? "";
    }
  }

  /**
   * Get Ticket Log Containing User Activities and Changes on a Ticket
   */
  async getTicketAuditLog(
    request: KokattoGetTicketAuditLogRequest
  ): Promise<KokattoGetTicketAuditLogResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/auditLogs`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
        params: request,
      });

      return response.data;
    });
  }

  /**
   * Get ticket form list
   */
  async getTicketFormList(
    request: KokattoGetTicketFormRequest
  ): Promise<KokattoGetTicketFormResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/ticket-form/list`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
        params: request,
      });
      return response.data;
    });
  }

  /**
   * Get ticket form details
   */
  async getTicketFormDetail(
    request: KokattoGetTicketFormRequest
  ): Promise<KokattoGetTicketFormResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/ticket-form/${request.id}`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  /**
   * Get ticket field by field ID
   */
  async getTicketFieldById(
    request: KokattoGetTicketFieldByIdRequest
  ): Promise<KokattoGetTicketFieldByIdResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/fields/${request.fieldId}`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
        params: request,
      });
      return response.data;
    });
  }

  /**
   * Get ticket field details
   */
  async getTicketFieldDetail(
    request: KokattoGetTicketFieldRequest
  ): Promise<KokattoGetTicketFieldResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/csplatform/chatTemplate`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
        params: request,
      });
      return response.data;
    });
  }

  /**
   * Get CS Agent Groups
   */
  async getCsAgentGroups(
    request?: KokattoGetCsAgentGroupsRequest
  ): Promise<KokattoGetCsAgentGroupsResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/groups`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
        params: request,
      });

      return response.data;
    });
  }

  /**
   * Get CS Agent Groups by groupId
   */
  async getCsAgentGroupById(
    request: KokattoGetCsAgentGroupByIdRequest
  ): Promise<KokattoGetCsAgentGroupByIdResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/groups/${request.groupId}`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });

      return response.data;
    });
  }

  /**
   * Save Group
   */
  async postGroups(
    request: Partial<CsAgentGroup>
  ): Promise<any | KokattoErrorResponse> {
    const url = `${this.baseUrl}/groups`;
    return this.failsafeHttpRequest(async () => {
      const config: AxiosRequestConfig = {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      };
      const response = await axios.post(url, request, config);
      return response.data;
    });
  }

  /**
   * Update Group
   */
  async putGroups(
    request: Partial<CsAgentGroup>
  ): Promise<any | KokattoErrorResponse> {
    const url = `${this.baseUrl}/groups/${request.id}`;
    return this.failsafeHttpRequest(async () => {
      const config: AxiosRequestConfig = {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      };
      const response = await axios.put(url, request, config);
      return response.data;
    });
  }

  /**
   * Get Group Memberships
   */
  async getGroupMemberships(
    request: KokattoGetGroupMembershipRequest
  ): Promise<KokattoGetGroupMembershipResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/groups/${request.groupId}/membership`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
        params: request,
      });

      return response.data;
    });
  }

  /**
   * Delete group management
   */
  async deleteGroupManagement(
    groupId: string
  ): Promise<any | KokattoErrorResponse> {
    const url = `${this.baseUrl}/groups/${groupId}`;
    return this.failsafeHttpRequest(async () => {
      const config: AxiosRequestConfig = {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      };
      const response = await axios.delete(url, config);
      return response.data;
    });
  }

  /**
   * Get CS Agent Groups based on cs / user email
   */
  async getCsAgentGroupMemberships(
    request: KokattoGetCsAgentGroupMembershipsRequest
  ): Promise<KokattoGetCsAgentGroupMembershipsResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/groupMembership/${request.email}/groups`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });

      return response.data;
    });
  }

  /**
   * Get Ticket List - Used in the Ticket View page
   */
  async getTicketList(
    params: KokattoGetTicketListRequest
  ): Promise<KokattoGetTicketListResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/ticket/list`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
        params,
      });

      return response.data;
    });
  }

  /**
   * Add user to group membership
   */
  async postCsAgentGroupMemberships(
    request: CsAgentGroupMembership
  ): Promise<CsAgentGroupMembership | KokattoErrorResponse> {
    const url = `${this.baseUrl}/groupMembership`;
    return this.failsafeHttpRequest(async () => {
      const config: AxiosRequestConfig = {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      };
      const response = await axios.post(url, request, config);
      return response.data;
    });
  }

  /**
   * Delete user from group membership
   */
  async deleteGroupMembership(
    groupMembershipId: string
  ): Promise<CsAgentGroupMembership | KokattoErrorResponse> {
    const url = `${this.baseUrl}/groupMembership/${groupMembershipId}`;
    return this.failsafeHttpRequest(async () => {
      const config: AxiosRequestConfig = {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      };
      const response = await axios.delete(url, config);

      return response.data;
    });
  }

  /**
   * Get List of Ticket View
   */
  async getTicketViewList(
    request: KokattoPaginatedRequest
  ): Promise<KokattoGetTicketViewListResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/views/list`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
        params: request,
      });

      return response.data;
    });
  }
  /**
   * Get Ticket List by Advanced Search
   */
  async getTicketListByAdvancedSearch(
    request: KokattoGetTicketChatListRequest
  ): Promise<any | KokattoErrorResponse> {
    // [TO-DO] Advanced Search : change response type
    const url = `${this.baseUrl}/search`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        params: request,
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });

      return response.data;
    });
  }

  /** Get Custom fields for Ticket Chat List Column */
  async getTicketFields(
    request: KokattoGetTicketFieldsRequest
  ): Promise<KokattoGetTicketFieldsResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/fields`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        params: request,
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  /**
   * Submit ticket handling bydDuration in seconds
   */
  async submitTicketDurationTracking(
    request: KokattoTicketTimeTrackingRequest
  ) {
    const url = `${this.baseUrl}/time-tracking`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.post(url, request, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }
  /**
   * Get List of Auto Assign Ticket Playlist
   */
  async getAutoAssignTicketPlaylist(
    request: KokattoGetTicketPlaylistRequest
  ): Promise<KokattoGetTicketPlaylistResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/ticket/playlist`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
        params: request,
      });

      return response.data;
    });
  }
}
